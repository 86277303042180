class MicroshopContactForm {
    constructor() {
        this.form = $("#microshop-form");
        if (this.form.length) {
            this.formInputs = this.form.find("input , textarea");
            this.bindEvents();
        }
    }

    bindEvents() {
        this.form.on("submit", this.validate.bind(this));
        this.formInputs.each((i, element) => {
            let input = $(element);

            input.on("input", this.hideAlerts.bind(this));
        });
    }

    validate() {
        let sendEmail = true;
        event.preventDefault();
        console.log("ok");
        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.formInputs.each((i, element) => {
            let input = $(element);
            if (!input.val()) {
                sendEmail = false;
                this.showAlert(input);
            } else if (
                input.attr("name") === "correo" &&
                !reg.test(input.val())
            ) {
                sendEmail = false;
                this.showAlert(input);
            }
        });

        if (sendEmail) {
            let values = {};
            this.formInputs.each((i, input) => {
                values = {
                    ...values,
                    [input.name]: input.value
                };
            });

            axios
                .post("/api/micrositio/contacto", values)
                .then(function(response) {
                    alert(
                        "Mensaje enviado, Pronto estaremos respondiendo tus inquietudes."
                    );
                    /*  window.location.href = "/contacto/gracias"; */
                })
                .catch(function(error) {
                    alert(
                        "No sspodemos enviar su mensaje en este momento, intente de nuevo mas tarde."
                    );
                });
        }
    }

    showAlert(input) {
        let container = input.parent();
        if (container.find("small").length) {
            return null;
        }
        input.addClass("input--alert");
        let name = input.attr("name");
        let alertText;
        switch (name) {
            case "nombre":
                alertText = "Por favor, complete su nombre.";
                break;
            case "apellido":
                alertText = "Por favor, complete su apellido.";
                break;
            case "telefono":
                alertText = "Por favor, complete su telefono.";
                break;
            case "correo":
                alertText =
                    "Por favor ingrese una dirección de correo electrónico válida.";
                break;
            case "comentarios":
                alertText = "Por favor, complete el mensaje.";
                break;

            default:
                alertText = "Por favor, debe llenar el campo.";
                break;
        }
        container.append(`<small class='small--alert'>${alertText}</small>`);
    }

    hideAlerts() {
        let input = $(event.target);

        if (input.val()) {
            let container = input.parent();
            input.removeClass("input--alert");
            let text = container.find("small");
            text.remove();
        } else {
            this.showAlert(input);
        }
    }

    radioValues() {
        let input = $(event.target);
        input.val("true");
        this.hideAlerts();
    }
}
export default MicroshopContactForm;
