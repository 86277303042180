class address
{

    constructor()
    {
        this.content = document.querySelector('#container-address');


        if (this.content != null) {
            this.boton = '#button-save';
            this.buttonSubmit = this.content.querySelector('#button-save');
            this.buttonChange = '.btn-change';
            this.departamentoDireccion = '#departamento-direccion';

            this.ciudadDireccion = this.content.querySelector('#ciudad-direccion');
            this.departamento = this.content.querySelector('#departamento-direccion');
            this.nombre = this.content.querySelector('#nombre');
            this.telefono = this.content.querySelector('#telefono');
            this.direccion = this.content.querySelector('#direccion');
            //this.checkboxPrincipal = '#principal';
            this.alertMessage = this.content.querySelector('#alert-message');
            this.successMessage = this.content.querySelector('#success-message');

            this.containerAddress = document.querySelector('#direccionesContenedor');

            this.principal = 0;

            this.bindEvents();
        }
    }

    bindEvents()
    {
        document.addEventListener('click', this.submitEvents.bind(this));
        document.addEventListener('change', this.changeEvents.bind(this));
    }

    submitEvents(e)
    {
        if (e.target.matches(this.boton)) {
            this.submitForm(e);
        }

        if(e.target.matches(this.buttonChange)){
            let idButton = e.target.dataset.id;
            this.changePrincipal(idButton);
        }
    }

    changePrincipal(idButton){

        axios
        .post('/api/usuarios/direcciones/principal', {
            id: idButton
        })
        .then(response => {
            //this.getAllDirections();
            //console.log('principal ' , response);
            document.location.reload();
        })
        .catch(error => {
            if (typeof error.response !== 'undefined') {
                console.log("error response",error.response);
            }
        });
    }

    changeEvents(e)
    {
        if (e.target.matches(this.departamentoDireccion)) {
            this.getCity(e);
        }

        /*if(e.target.matches(this.checkboxPrincipal)){
            if(this.principal == 0){
                this.principal = 1;
            }else if( this.principal ==1){
                this.principal = 0;
            }
        }*/

    }

    async getAllDirections()
    {
        try {
            let sectionAddress= '';

            //Obtener productos
            let address = await this.getDireccionesAPI();

            //Obtener el template de los productos
            sectionAddress += this.getTemplate(address);

            //ELiminar contenido del formulario y activar mensaje
            this.buttonSubmit.disabled = false;
            this.direccion.value = '';
            this.departamento.value = '';
            this.ciudadDireccion.value = null;
            this.principal = 0;
            this.telefono.value = '';
            this.nombre.value = '';
            this.successMessage.style.display = 'block';

            //Insertar templates
            this.containerAddress.innerHTML = sectionAddress;
        } catch (error) {
            if (typeof error.response !== 'undefined') {
                console.log(error.response.data);
            }
        }
    }

    submitForm(e)
    {
        e.preventDefault();

        if (this.validateFields()) {

            let direccionForm = this.direccion.value;
            let departamento = this.departamento.value;
            let ciudad = this.ciudadDireccion.value;
            let direccionPrincipal = 1; //this.principal;
            let telefono =  this.telefono.value;
            let nombre = this.nombre.value;


            this.buttonSubmit.disabled = true;
            this.alertMessage.style.display = 'none';
            this.successMessage.style.display = 'none';

            let form = {
                direccion: direccionForm,
                departamento_id: departamento,
                ciudad_id: ciudad,
                principal:  direccionPrincipal,
                telefono_quien_recibe: telefono,
                nombre_quien_recibe: nombre
            };

            axios
            .post('/api/usuarios/direcciones', form )
            .then(response => {
                if (response.data.status == 200) {
                    document.location.reload();
                    //this.getAllDirections();
                }
            })
            .catch(error => {
                if (typeof error.response !== 'undefined') {
                    console.log("error response",error.response);
                    this.buttonSubmit.disabled = false;
                    this.alertMessage.style.display = 'block';
                }
            });

        }
    }

    validateFields()
    {
        let allInputs  = this.getAllElementsWithAttribute('required');
        let formIsValid = true;

        for (let key in allInputs) {
            let inputElement = allInputs[key];
            let name = inputElement.name;
            let nameIdErrorMessage = name.replace(/\[/g, '');
            nameIdErrorMessage = nameIdErrorMessage.replace(/\]/g, '');
            let id = inputElement.id
            let type = inputElement.type;
            let value = inputElement.value;
            let message = inputElement.dataset.msg;

            //console.log(type);
            switch (type) {
                case 'text':
                case 'select-one':
                    //console.log('Es text o select-one');
                    //console.log('El name es: ', name);
                    if (!value) {
                        formIsValid = false;
                        inputElement.parentElement.classList.add('u-has-error');
                        var errorMessage = `
                        <div id="error-message-${ nameIdErrorMessage }" class="invalid-feedback" style="display: block;">
                            ${ message }
                        </div>`;

                        if (this.content.querySelector('#error-message-' + nameIdErrorMessage) == null) {
                            inputElement.insertAdjacentHTML('afterend', errorMessage);
                        }

                    } else {
                        inputElement.parentElement.classList.remove('u-has-error');
                        if (this.content.querySelector('#error-message-' + nameIdErrorMessage) != null) {
                            this.content.querySelector('#error-message-' + nameIdErrorMessage).remove();
                        }
                    }
                    break;
                case 'checkbox':
                case 'radio':
                    //console.log(type);
                    //console.log('El tipo es checkbox o radio y su nombre es: ', name);
                    const checkboxes = document.querySelectorAll('input[name="' + name + '"]:checked');
                    if (!checkboxes.length) {
                        //console.log('El elemento ' + name + ' no esta seleccionado');
                        formIsValid = false;
                        let checkboxElement = this.content.querySelector('input[name="' + name + '"]');
                        let closestParent = checkboxElement.closest(".js-form-message")
                        //console.log('el closestParent es: ', closestParent);

                        var errorMessage = `
                        <div id="error-message-${ nameIdErrorMessage }" class="invalid-feedback" style="display: block;">
                            ${ message }
                        </div>`;

                        if (this.content.querySelector('#error-message-' + nameIdErrorMessage) == null) {
                            closestParent.insertAdjacentHTML('beforeend', errorMessage);
                        }
                    } else {
                        if (this.content.querySelector('#error-message-' + nameIdErrorMessage) != null) {
                            this.content.querySelector('#error-message-' + nameIdErrorMessage).remove();
                        }
                    }
                    break;
            }
        }
        return formIsValid;
    }

    getAllElementsWithAttribute(attribute)
    {
      var matchingElements = [];
      var allElements = this.content.getElementsByTagName('*');

      for (var i = 0, n = allElements.length; i < n; i++) {
        if (allElements[i].getAttribute(attribute) !== null) {
          // Element exists with attribute. Add to array.
          matchingElements.push(allElements[i]);
        }
      }
      return matchingElements;
    }

    getDireccionesAPI(){

        return axios
        .get('/api/usuarios/direcciones')
        .then(response => {
            return response.data;
        });
    }

    getTemplate(addresses){

        let template = '';

        for (let address of addresses) {
            if(address.principal == 1){
                template += `<div class="col-12 col-md-6">
                <div class="address address-active">
                <h3>Dirección Principal</h3>
                <p><strong>${address.nombre_quien_recibe}</strong></p>
                <p>${address.telefono_quien_recibe}</p>
                <p>${address.nombre_departamento}</p>
                <p>${address.nombre_ciudad}</p>
                <p>${address.direccion}</p>

                <p class="note"><input type="radio" class="mr-2" checked>El pedido será entregado a esta dirección</p>
            </div>
            </div>`
            } else {
                template += `<div class="col-12 col-md-6">
                <div class="address">
                 <h3>&nbsp;</h3>
                <p><strong>${address.nombre_quien_recibe}</strong></p>
                <p>${address.telefono_quien_recibe}</p>
                <p>${address.nombre_departamento}</p>
                <p>${address.nombre_ciudad}</p>
                <p>${address.direccion}</p>


                <a class="btn-change btn btn-soft-secondary" id="${address.id}">Entregar a esta dirección</a>
            </div>
            </div>`
            }
        }

        return template;
    }

    getCity(e)
    {
        let element = e.target;
        let departamentoId = element.value;

        axios
        .get('/api/departamentos/' + departamentoId + '/ciudades')
        .then(response => {
            let ciudades = '<option value="">Seleccionar ciudad</option>';
            for (let ciudad of response.data) {
                ciudades += `<option value="${ ciudad.id_ciudad }">${ ciudad.nombre }</option>`;
            }

            //Asignar las ciudades al elemento que invoco la accion
            if (element.id == 'departamento-direccion') {
                this.ciudadDireccion.innerHTML = ciudades;
            }
            else {
                let id = element.dataset.id;
                let ciudad = this.register.querySelector('#ciudad-direccion-' + id);
                ciudad.innerHTML = ciudades;
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

}

export default address;
