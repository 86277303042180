class Pagination {
    constructor() {
        this.inputButton = $("#next-page-input-button");
        this.input = $("#next-page-input");
        this.squareSmall = $("#subcategory-3-products-list-small");
        this.list = $("#subcategory-products-list");
        this.bindEvents();
    }
    bindEvents() {
        this.inputButton.on("click", e => {
            this.getNextPage(e);
        });
        this.input.on("change", e => {
            this.getNextPage(e);
        });
    }
    getNextPage(e) {
        e.preventDefault();
        let value = this.input.val();
        let idCate = this.input.data("category");
        let idSubca = this.input.data("subcategory");
        axios
            .post("/api/productosSubcategoria", {
                categoria: idCate,
                subcategoria: idSubca,
                page: value
            })
            .then(response => {
                this.renderProducts(response.data.data);
            });
    }
    renderProducts(data) {
        const products = data.listaproductos;
        let attr;
        this.squareSmall.empty();
        this.list.empty();
        products.forEach((product, index) => {
            const {
                Categoria,
                Name,
                Imagenes,
                precio,
                PartNum,
                Description,
                xmlAttributes
            } = product;

            let templateSqSm = `<li class="col-6 col-md-4 product-item">
                                    <div class="product-item__outer h-100 w-100">
                                        <div class="product-item__inner px-xl-4 p-3">
                                            <div class="product-item__body pb-xl-2">
                                                <div class="mb-2"><a href="/producto?rq=${encodeURIComponent(
                                                    PartNum
                                                )}"
                                                        class="font-size-12 text-gray-5">${Categoria}</a>
                                                </div>
                                                <h5 class="mb-1 product-item__title"><a
                                                        href="/producto?rq=${encodeURIComponent(
                                                            PartNum
                                                        )}"
                                                        class="text-blue font-weight-bold">${Name}</a></h5>
                                                <div class="mb-2">
                                                    <a href="/producto?rq=${encodeURIComponent(
                                                        PartNum
                                                    )}"
                                                        class="d-block text-center"><img class="img-fluid"
                                                            src="/mps/img/212X200/img1.jpg" alt="Image Description"></a>
                                                </div>
                                                <div class="flex-center-between mb-1">
                                                    <div class="prodcut-price">
                                                        <div class="text-gray-100">${precio.toLocaleString(
                                                            "de-DE"
                                                        )}</div>
                                                    </div>
                                                    <div class="d-none d-xl-block prodcut-add-cart">
                                                        <a href="" class="btn-add-cart btn-primary transition-3d-hover" onclick="addToCart(event,'${PartNum}')"><i
                                                                class="ec ec-add-to-cart"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="product-item__footer">
                                                <div class="border-top pt-2 flex-center-between flex-wrap">

                                                    <a href="" class="text-gray-6 font-size-13"onclick="wishlist(event,'${PartNum}')"><i
                                                            class="ec ec-favorites mr-1 font-size-15"  ></i> Favoritos</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>`;
            let templateL = ` <li class="product-item remove-divider">
                                    <div class="product-item__outer w-100">
                                        <div class="product-item__inner remove-prodcut-hover py-4 row">
                                            <div class="product-item__header col-6 col-md-4">
                                                <div class="mb-2">
                                                    <a href="/producto?rq=${encodeURIComponent(
                                                        PartNum
                                                    )}" class="d-block text-center"><img class="img-fluid"
                                                            src="/mps/img/212X200/img1.jpg" alt="Image Description"></a>
                                                </div>
                                            </div>
                                            <div class="product-item__body col-6 col-md-5">
                                                <div class="pr-lg-10">
                                                    <div class="mb-2"><a href="/producto?rq=${encodeURIComponent(
                                                        PartNum
                                                    )}"
                                                            class="font-size-12 text-gray-5">${Categoria}</a>
                                                    </div>
                                                    <h5 class="mb-2 product-item__title"><a href="/producto?rq=${encodeURIComponent(
                                                        PartNum
                                                    )}"
                                                            class="text-blue font-weight-bold">${Name}</a></h5>
                                                    <div class="prodcut-price mb-2 d-md-none">
                                                        <div class="text-gray-100">
                                                            ${precio.toLocaleString(
                                                                "de-DE"
                                                            )}</div>
                                                    </div>
                                                    <div class="mb-3 d-none d-md-block">
                                                        <a class="d-inline-flex align-items-center small font-size-14"
                                                            href="#">
                                                            <div class="text-warning mr-2">
                                                                <small class="fas fa-star"></small>
                                                                <small class="fas fa-star"></small>
                                                                <small class="fas fa-star"></small>
                                                                <small class="fas fa-star"></small>
                                                                <small class="far fa-star text-muted"></small>
                                                            </div>
                                                            <span class="text-secondary">(40)</span>
                                                        </a>
                                                    </div>
                                                    <ul class="font-size-12 p-0 text-gray-110 mb-4 d-none d-md-block" id="${`attributes-${index}`}">
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="product-item__footer col-md-3 d-md-block">
                                                <div class="mb-3">
                                                    <div class="prodcut-price mb-2">
                                                        <div class="text-gray-100">
                                                            $${precio.toLocaleString(
                                                                "de-DE"
                                                            )}</div>
                                                    </div>
                                                    <div class="prodcut-add-cart">
                                                        <a href=""
                                                            class="btn btn-sm btn-block btn-primary-dark btn-wide transition-3d-hover" onclick="addToCart(event,'${PartNum}')">Add
                                                            to cart</a>
                                                    </div>
                                                </div>
                                                <div
                                                    class="flex-horizontal-center justify-content-between justify-content-wd-center flex-wrap">
                                                    <a href="../shop/wishlist.html"
                                                        class="text-gray-6 font-size-13 mx-wd-3"  onclick="wishlist(event,'${PartNum}')"><i
                                                            class="ec ec-favorites mr-1 font-size-15"></i> Favoritos</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>`;
            this.list.append(templateL);
            this.squareSmall.append(templateSqSm);
            if (xmlAttributes) {
                this.setAttributes(xmlAttributes, index);
            } else {
                this.setDescription(Description, index);
            }
        });
    }
    setDescription(Description, index) {
        let container = $(`#attributes-${index}`);
        $(container).append(
            `<li class="line-clamp-1 mb-1 list-bullet">${Description}</li>`
        );
    }
    setAttributes(attributes, index) {
        const {
            ListaAtributos: {
                Atributos: { attributecs }
            }
        } = JSON.parse(attributes);
        let container = $(`#attributes-${index}`);
        for (let i = 0; i < 3; i++) {
            let template = `<li class="line-clamp-1 mb-1 list-bullet">${attributecs[i].AttributeName}: ${attributecs[i].AttributeValue}</li>`;
            container.append(template);
        }
    }
}

export default Pagination;
