import Utils from "../../utils/utils";
import Template from "./template";

class List
{
    constructor(device)
    {
        this.productsListContainer = $('#products-list-container');
        if (this.productsListContainer.length) {
            this.template = new Template();
            this.formFiltersList = this.productsListContainer.find('#form-filters-list-' + device);
            this.categoryFilter = this.formFiltersList.find('input[name="categoria"]').val();
            this.subCategoryFilter = this.formFiltersList.find('input[name="subcategoria"]').val();
            this.termFilter = this.formFiltersList.find('input[name="term"]').val();
            this.brandFilter = this.formFiltersList.find('input[name="marca"]').val();
            this.discountFilter = this.formFiltersList.find('input[name="descuentos"]').val();

            this.mosaicProducts = this.productsListContainer.find('#products-list-mosaic-view');
            this.productsListMosaicContent = this.productsListContainer.find('#products-list-mosaic-view ul');
            this.productsListContent = this.productsListContainer.find('#products-list-view ul');

            this.filtersContainer = $('.filters-container-' + device);

            this.containerSubcategoriesFilter = this.filtersContainer.find('.container-subcategories-filter');
            this.sectionCheckboxSubcategories = this.filtersContainer.find('.section-checkbox-subcategories');

            this.containerDisscountFilter = this.filtersContainer.find('.container-disscount-filter');

            this.sectionCheckboxPrice = this.filtersContainer.find('.section-checkbox-price');
            this.containerBrandsFilter = this.filtersContainer.find('.container-brands-filter');
            this.sectionCheckboxBrand = this.filtersContainer.find('.section-checkbox-brand');
            this.sectionCheckboxColors = this.filtersContainer.find('.section-checkbox-colors');

            this.labelSubCategorias = this.filtersContainer.find('.rango-subcategorias');
            this.labelPrecios = this.filtersContainer.find('.rango-precio');
            this.labelMarcas = this.filtersContainer.find('.rango-marcas');
            this.labelColores = this.filtersContainer.find('.rango-colores');

            this.lastFilterApplied = null;
            this.filtersApplied = [];
            this.selectedRangesOfSubCategories = [];
            this.selectedRangesOfPrices = null;
            this.selectedRangesOfBrands = [];
            this.selectedRangesOfColors = [];

            this.selectedColors = [];
            this.priceFilter = null;
            this.colorFilter = null;
            this.availabilityFilter = 0;

            this.classMarca = ".brand-checkbox";
            this.classPrecio = ".price-checkbox";
            this.classColor = ".color-checkbox";
            this.classSubCategoria = ".subCategory-checkbox";
            this.checkboxAvailability = ".disponibilidad-checkbox";
            this.checkboxDisscount = ".descuento-checkbox";
            this.btnRemoveFilter = '.remove-filter';

            this.AzFilter = null;
            this.ZaFilter = null;
            this.LowerPriceFilter = null;
            this.HigherPriceFilter = null;

            this.orderFiltersSelect = this.productsListContainer.find('#order-filters-select');

            this.paginationBottom = this.productsListContainer.find('#pagination');
            this.paginationTop = this.productsListContainer.find("#countProducts-top");
            this.paginationBottomLinks = this.paginationBottom.find('ul li a');

            this.bindEvents();
            this.initializeProductList();
        }
    }

    bindEvents()
    {
        this.mosaicProducts.addClass('u-slick-products');
        document.addEventListener('change', this.filter.bind(this));
        document.addEventListener('click', this.removeFilter.bind(this));
        this.orderFiltersSelect.on('change', this.changeSelectOrderFilters.bind(this));
        this.paginationBottomLinks.on("click", this.changePage.bind(this));
    }

    initializeProductList()
    {
        // Si la sección acutal es el listado de productos por subcategorias o listado de productos por termino de busqueda entonces ocultar el filtro de subcategorías
        if (this.subCategoryFilter != undefined && this.subCategoryFilter != ""
            || this.termFilter  != undefined && this.termFilter != "") {
            this.containerSubcategoriesFilter.addClass('d-none');
        }

        // Si la sección actual es el listado de productos por marca entonces ocultar el filtro de subcategorias y marca
        if (this.brandFilter != undefined && this.brandFilter != "") {
            this.containerSubcategoriesFilter.addClass('d-none');
            this.containerBrandsFilter.addClass('d-none');
        }

        // Si la sección actual es el listado de productos por descuento entonces ocultar el filtro de descuento
        if (this.discountFilter != undefined && this.discountFilter != "") {
            this.containerDisscountFilter.addClass('d-none');
        }

        this.getProductsList(1);
    }

    showFilterLoader()
    {
        this.filtersContainer.addClass('u-slick-products');
        this.filtersContainer.addClass('opacity');
    }

    hideFilterLoader()
    {
        this.filtersContainer.removeClass('u-slick-products');
        this.filtersContainer.removeClass('opacity');
    }

    showProductListLoader()
    {
        this.mosaicProducts.addClass('u-slick-products');
        this.productsListMosaicContent.addClass('opacity');
    }

    hideProductListLoader()
    {
        this.mosaicProducts.removeClass('u-slick-products');
        this.mosaicProducts.removeClass('py-5');
        this.productsListMosaicContent.removeClass('opacity');
    }

    showProductList(products)
    {
        let mosaicTemplate = '';
        let listTemplate = '';
        if (products.listaproductos.length) {
            for (let producto of products.listaproductos) {
                mosaicTemplate += this.template.getTemplateMosaicView(producto);
                listTemplate += this.template.getTemplateListView(producto);
            }
        }

        this.productsListMosaicContent.html(mosaicTemplate);
        this.productsListContent.html(listTemplate);
    }

    showSubCategories(subcategorias)
    {
        let sectionSubcategoriesTemplate = this.template.getTemplateSubcategories(subcategorias, this.selectedRangesOfSubCategories);
        this.sectionCheckboxSubcategories.html(sectionSubcategoriesTemplate);
    }

    showPrices(precioMin, precioMax)
    {
        let sectionPricesTemplate = this.template.getTemplatePrice(precioMin, precioMax, this.selectedRangesOfPrices);
        this.sectionCheckboxPrice.html(sectionPricesTemplate);
    }

    showBrands(brands)
    {
        let sectionBrandsTemplate = this.template.getTemplateBrands(brands, this.selectedRangesOfBrands);
        this.sectionCheckboxBrand.html(sectionBrandsTemplate);
    }

    showColors(colores)
    {
        let sectionColorsTemplate = this.template.getTemplateColors(colores, this.selectedRangesOfColors);
        this.sectionCheckboxColors.html(sectionColorsTemplate);
    }

    changeSelectOrderFilters(event)
    {
        event.preventDefault();
        let value = event.target.value;
        console.log('cambio select order ', value);
        if (value != '') {
            this.AzFilter = null;
            this.ZaFilter = null;
            this.LowerPriceFilter = null;
            this.HigherPriceFilter = null;

            switch (value) {
                case 'a-z':
                    this.AzFilter = 1;
                    break;
                case 'z-a':
                    this.ZaFilter = 1;
                    break;
                case 'lower-price':
                    this.LowerPriceFilter = 1;
                    break;
                case 'higher-price':
                    this.HigherPriceFilter = 1;
                    break;
            }

            //this.getProductsList(1);
        }

    }

    showPagination(pagination)
    {
        this.paginationTop.html('Mostrando 12 de ' + pagination.CantidadProduct + ' resultados');
        this.paginationBottom.find('div').html('Mostrando 12 de ' + pagination.CantidadProduct + ' resultados');
        let ul = this.paginationBottom.find('ul');
        let first = '';
        let prev = '';
        let current = '';
        let next = '';
        let after = '';
        let last = '';

        if (pagination.TotalPag <= 3) {

            if (pagination.PaginaActual - 1 > 0) {
                prev = `
                <li class="page-item">
                    <a class="page-link" data-page="${pagination.PaginaActual - 1}">
                        ${pagination.PaginaActual - 1}
                    </a>
                </li>
                `;
            }

            current = `
            <li class="page-item">
                <a class="page-link current" style="cursor: default;">
                    ${pagination.PaginaActual}
                </a>
            </li>
            `;

            if (pagination.PaginaActual + 1 <=  pagination.TotalPag) {
                next = `
                <li class="page-item">
                    <a class="page-link" data-page="${pagination.PaginaActual + 1}">
                        ${pagination.PaginaActual + 1}
                    </a>
                </li>
                `;
            }

            if (pagination.PaginaActual == 1 && pagination.TotalPag > 2) {
                after = `
                <li class="page-item">
                    <a class="page-link" data-page="${pagination.PaginaActual + 2}">
                        ${pagination.PaginaActual + 2}
                    </a>
                </li>
                `;
            }

            let paginationLinks = prev + current + next + after;
            ul.html(paginationLinks);
        }

        if (pagination.TotalPag > 3) {

            if (pagination.PaginaActual >= pagination.TotalPag - 1 ) {
                first = `
                <li class="page-item">
                    <a class="page-link" data-page="1">
                        1
                    </a>
                </li>
                <li class="page-item">
                    <a style="font-size: 20px; font-weight: bold;">...</a>
                </li>
                `;
            }

            if (pagination.PaginaActual - 1 > 0) {
                prev = `
                <li class="page-item">
                    <a class="page-link" data-page="${pagination.PaginaActual - 1}">
                        ${pagination.PaginaActual - 1}
                    </a>
                </li>
                `;
            }

            current = `
            <li class="page-item">
                <a class="page-link current" style="cursor: default;">
                    ${pagination.PaginaActual}
                </a>
            </li>
            `;

            if (pagination.PaginaActual + 1 <=  pagination.TotalPag) {
                next = `
                <li class="page-item">
                    <a class="page-link" data-page="${pagination.PaginaActual + 1}">
                        ${pagination.PaginaActual + 1}
                    </a>
                </li>
                `;
            }

            if (pagination.PaginaActual == 1 ) {
                after = `
                <li class="page-item">
                    <a class="page-link" data-page="${pagination.PaginaActual + 2}">
                        ${pagination.PaginaActual + 2}
                    </a>
                </li>
                `;
            }

            if (pagination.PaginaActual < pagination.TotalPag - 1 ) {
                last = `
                <li class="page-item">
                    <a style="font-size: 20px; font-weight: bold;">...</a>
                </li>
                <li class="page-item">
                    <a class="page-link" data-page="${pagination.TotalPag}">
                        ${pagination.TotalPag}
                    </a>
                </li>
                `;
            }

            let paginationLinks = first + prev + current + next + after + last;
            ul.html(paginationLinks);
        }

        //Reattach events
        this.paginationBottomLinks = this.paginationBottom.find('ul li a');
        this.paginationBottomLinks.on("click", this.changePage.bind(this));
    }

    changePage()
    {
        var $target = $(event.target);
        let page = $target.data('page');

        if (page != undefined) {
            this.getProductsList(page);
        }
    }

    filter(e)
    {
        e.preventDefault();
        let filterApplied = null;

        if (e.target.matches(this.classSubCategoria)) {
            //console.log('se aplica ' + this.classSubCategoria);
            filterApplied = "subcategory";
            this.setSubCategoryFilter();
        }

        if (e.target.matches(this.classPrecio)) {
            filterApplied = "price";
            this.setPriceFilter(e);
        }

        if (e.target.matches(this.classMarca)) {
            filterApplied = "brand";
            this.setBrandFilter();
        }

        if (e.target.matches(this.classColor)) {
            filterApplied = "color";
            this.setColorFilter();
        }

        if (e.target.matches(this.checkboxAvailability)) {
            //console.log('check disponible');
            filterApplied = "available";
            this.setAvalabilityFilter();
        }

        if (e.target.matches(this.checkboxDisscount)) {
            console.log('check descuento');
            filterApplied = "disscount";
            this.setDisscountFilter();
        }

        // Agregar filtro si no existe en el array de filtros aplicados
        if (!this.filtersApplied.includes(filterApplied)) {
            this.filtersApplied.unshift(filterApplied);
        }

        //console.log('el listado de filtros aplicados es ' , this.filtersApplied);
        this.getProductsList(1);
    }

    setSubCategoryFilter()
    {
        let selectedSubCategory = [];
        let selectedSubCategoryName = [];

        // Buscar todos los checkbox que esten seleccionados
        this.formFiltersList.find('input:checked').each(function() {
            if($(this).data('subcategoria') != undefined){
                selectedSubCategory.push($(this).data('nombre'));
                selectedSubCategoryName.push($(this).data('nombre'));
            }
        });

        // Mostrar el label con el rango seleccionado
        if (selectedSubCategory.length > 0) {
            this.subCategoryFilter = selectedSubCategory.join('-');
            this.labelSubCategorias.removeClass('d-none');
            this.labelSubCategorias.addClass('d-inline-flex');
            this.labelSubCategorias.find('span').html(selectedSubCategoryName.join(' - '));

            // Agregar el rango de subCategorias seleccionadas
            this.selectedRangesOfSubCategories = selectedSubCategoryName;
        } else {
            this.subCategoryFilter = null;
            this.labelSubCategorias.addClass('d-none');
            this.labelSubCategorias.removeClass('d-inline-flex');
            this.labelSubCategorias.find('span').html('');

            // Eliminar el rango de subCategorias seleccionadas
            this.selectedRangesOfSubCategories = [];
        }

        //console.log('selectedRangesOfSubCategories ' , this.selectedRangesOfSubCategories);
    }

    setPriceFilter(e)
    {
        let priceMinCheckbox = 0;
        let priceMaxCheckbox = 0;
        let priceFilter = null;
        let element = e.target;
        let selectedPriceMin = [];
        let selectedPriceMax = [];

        // Buscar todos los checkbox que esten seleccionados
        this.formFiltersList.find('input:checked').each(function() {
            if($(this).data('min') != undefined || $(this).data('max') != undefined){
                selectedPriceMin.push($(this).data('min'));
                selectedPriceMax.push($(this).data('max'));
                priceMaxCheckbox =  Math.max(...selectedPriceMax);
                priceMinCheckbox = Math.min(...selectedPriceMin);
            }
        });

        // Mostrar el label con el rango seleccionado
        if (selectedPriceMin.length && selectedPriceMax.length) {
            this.priceFilter = priceMinCheckbox + '-' + priceMaxCheckbox;
            this.labelPrecios.removeClass('d-none');
            this.labelPrecios.addClass('d-inline-flex');
            this.labelPrecios.find('span').html(
                `$ ${ priceMinCheckbox.toLocaleString("de-DE")} -
                $ ${ priceMaxCheckbox.toLocaleString("de-DE")}`
            );

            // Agregar el rango de precios seleccionados
            this.selectedRangesOfPrices =  this.priceFilter;
        } else {
            this.priceFilter = null;
            this.labelPrecios.addClass('d-none');
            this.labelPrecios.removeClass('d-inline-flex');
            this.labelPrecios.find('span').html('');

            // Eliminar el rango de precios seleccionados
            this.selectedRangesOfPrices = null;
        }

        //console.log('selectedRangesOfPrices ' , this.selectedRangesOfPrices);
    }

    setBrandFilter()
    {
        let selectedBrands = [];

        // Buscar todos los checkbox que esten seleccionados
        this.formFiltersList.find('input:checked').each(function() {
            if ($(this).data('brand') != undefined) {
                selectedBrands.push($(this).data('brand'));
            }
        });

        // Mostrar el label con el rango seleccionado
        if (selectedBrands.length) {
            this.brandFilter = selectedBrands.join('-');
            this.labelMarcas.removeClass('d-none');
            this.labelMarcas.addClass('d-inline-flex');
            this.labelMarcas.find('span').html(selectedBrands.join(' - '));

            // Agregar el rango de marcas seleccionadas
            this.selectedRangesOfBrands = selectedBrands;

        } else {
            this.brandFilter = null;
            this.labelMarcas.addClass('d-none');
            this.labelMarcas.removeClass('d-inline-flex');
            this.labelMarcas.find('span').html('');

            // Eliminar el rango de marcas seleccionadas
            this.selectedRangesOfBrands = [];
        }
        //console.log('selectedRangesOfBrands ' , this.selectedRangesOfBrands);
    }

    setColorFilter()
    {
        let selectedColors = [];

        // Buscar todos los checkbox que esten seleccionados
        this.formFiltersList.find('input:checked').each(function() {
            if ($(this).data('color') != undefined) {
                selectedColors.push($(this).data('color'));
            }
        });

        // Mostrar el label con el rango seleccionado
        if (selectedColors.length > 0) {
            this.colorFilter = selectedColors.join('-');
            this.labelColores.removeClass('d-none');
            this.labelColores.addClass('d-inline-flex');
            this.labelColores.find('span').html(selectedColors.join(' - '));

            // Agregar el rango de colores seleccionados
            this.selectedRangesOfColors = selectedColors;
        } else {
            this.colorFilter = null;
            this.labelColores.addClass('d-none');
            this.labelColores.removeClass('d-inline-flex');
            this.labelColores.find('span').html('');

            // Eliminar el rango de colores seleccionados
            this.selectedRangesOfColors = [];
        }

        //console.log('selectedRangesOfColors ' , this.selectedRangesOfColors);
    }

    setAvalabilityFilter()
    {
        if (this.availabilityFilter == 0){
            this.availabilityFilter = 1;
        } else {
            this.availabilityFilter = 0;
        }
    }

    setDisscountFilter()
    {
        console.log('this.discountFilter ' , this.discountFilter);
        if (this.discountFilter == undefined || this.discountFilter == 0){
            this.discountFilter = 1;
        } else {
            this.discountFilter = 0;
        }
        console.log('this.discountFilter ' , this.discountFilter);
    }

    removeFilter(e)
    {
        if(e.target.matches(this.btnRemoveFilter)){
            let element = e.target;
            let filter = $(element).data('filter');
            //console.log('eliminar filtro ' + filter);

            switch (filter) {
                case 'subcategory':
                    this.subCategoryFilter = null;
                    this.selectedRangesOfSubCategories = [];
                    this.labelSubCategorias.removeClass('d-inline-flex');
                    this.labelSubCategorias.addClass('d-none');
                    break;
                case 'price':
                    this.priceFilter = null;
                    this.selectedRangesOfPrices = null;
                    this.labelPrecios.removeClass('d-inline-flex');
                    this.labelPrecios.addClass('d-none');
                    break;
                case 'brand':
                    this.brandFilter = null;
                    this.selectedRangesOfBrands = [];
                    this.labelMarcas.removeClass('d-inline-flex');
                    this.labelMarcas.addClass('d-none');
                    break;
                case 'color':
                    this.colorFilter = null;
                    this.selectedRangesOfColors = [];
                    this.labelColores.removeClass('d-inline-flex');
                    this.labelColores.addClass('d-none');
                    break;
            }

            // Eliminar filtro del array
            let index = this.filtersApplied.findIndex(filterName => filterName == filter);
            if (index > -1) {
                this.filtersApplied.splice(index, 1);
            }

            this.getProductsList(1);
        }
    }

    getProductsList(page)
    {
        // Mostrar cargador
        this.showProductListLoader();
        this.showFilterLoader();
        //console.log('el listado de filtros this.discountFilter y actualizado es ' , this.filtersApplied);
        //console.log('this.discountFilter ' , this.discountFilter);
        // Consultar productos
        axios.post('/api/productos/filtro', {
            idcategoria: this.categoryFilter,
            idsubcategoria: this.subCategoryFilter,
            terminoBusqueda: this.termFilter,
            filtroPrecio: this.priceFilter,
            filtroMarcas: this.brandFilter,
            filtroColores: this.colorFilter,
            filtroDisponible: this.availabilityFilter,
            filtroDescuento: this.discountFilter,
            filtroAz: this.AzFilter,
            filtroZa: this.ZaFilter,
            filtroMenorPrecio: this.LowerPriceFilter,
            filtroMayorPrecio: this.HigherPriceFilter,
            pagina: page
        })
        .then(response => {
            //console.log("listado de productos filtrados: ", response.data);
            let products = response.data;

            //console.log(this.filtersApplied[0] + ' actualiza a los demás');

            // Mostrar listado de productos
            this.showProductList(products);

            // Quitar el cargador
            this.hideProductListLoader();
            this.hideFilterLoader();

            //console.log('this.filtersApplied[0] ' , this.filtersApplied[0]);
            // Mostrar filtro subcategorias (si no esta a la cabeza)
            if (this.filtersApplied[0] != 'subcategory') {
                this.showSubCategories(products.paginado.CategoriasSub);
            }

            // Mostrar filtro de precios (si no esta a la cabeza)
            if (this.filtersApplied[0] != 'price') {
                this.showPrices(products.paginado.Precios.PrecioMinimo, products.paginado.Precios.PrecioMaximo);
            }

            // Mostrar filtro de marcas
            if (this.filtersApplied[0] != 'brand') {
                this.showBrands(products.paginado.Marcas);
            }

            // Mostrar filtro colores
            if (this.filtersApplied[0] != 'color') {
                this.showColors(products.paginado.Colors);
            }

            // Mostrar paginador
            this.showPagination(products.paginado);
        })
        .catch(error => {
            if (typeof error.response !== 'undefined') {
                console.log(error.response.data);

                // Quitar el cargador
                this.hideProductListLoader();
                this.hideFilterLoader();
            }
        });
    }
}

export default List;
