class Comments
{
    constructor()
    {
        this.commentsSection = document.querySelector('#seccion-comentarios');
        if (this.commentsSection != null) {
            this.productDetail = document.querySelector('#product-detail');
            this.productPartNum = this.productDetail.querySelector('#product-partnum');
            this.containerStarsProduct = this.productDetail.querySelector('#container-stars-product');
            this.containerCounterReviewsProduct = this.productDetail.querySelector('#container-counter-reviews-product');
            this.formAddComment = this.commentsSection.querySelector('form');
            this.StarsFormAddComment = '#seccion-comentarios form small';
            this.comment = this.formAddComment.querySelector('textarea');
            this.btAddComment = '#seccion-comentarios form button';
            this.rating = 1;
            this.seccionAddComment = this.commentsSection.querySelector('.agregarComentario');
            this.thanksMessage = this.commentsSection.querySelector('.thanks-message');
            this.alertMessage = this.formAddComment.querySelector('.alert-message');
            this.baseReviews = this.commentsSection.querySelector('#base-reviews');
            this.averageRating = this.commentsSection.querySelector('#average-rating');
            this.containerReviews = this.commentsSection.querySelector('#container-reviews');
            this.pagination = this.productDetail.querySelector('#pagination-comments');
            this.paginationLinks = this.pagination.querySelector('ul li a');
            this.etiqueta = "a";
            //console.log(this.percentStatsFiveRating);
            this.bindEvents();
        }
    }

    bindEvents()
    {
        this.seccionAddComment.style.display = "block";
        this.getComments(1);
        document.addEventListener('click', this.clickEvents.bind(this));
    }

    clickEvents(e)
    {
        if (e.target.matches(this.StarsFormAddComment)) {
            this.selectStar(e);
        }

        if (e.target.matches(this.btAddComment)) {
            this.addComment(e);
        }

        if(e.target.matches(this.etiqueta)){
            this.changePage(e);
        }
    }

    selectStar(e)
    {
        e.preventDefault();
        let element = e.target;
        this.rating = parseInt(element.dataset.rating);
        let starPosition = parseInt(element.dataset.rating) - 1;
        let stars = document.querySelectorAll(this.StarsFormAddComment);

        //Quitar color de las estrellas
        for(const [index, value] of stars.entries()) {
            value.classList.remove('fas');
            value.classList.add('text-muted');
        }

        //Agregar color a las estrellas
        for(const [index, value] of stars.entries()) {
            if (index <= starPosition) {
                value.classList.add('fas');
                value.classList.remove('text-muted');
            }
        }
    }

    addComment(e)
    {
        e.preventDefault();
        let element = e.target;
        element.disabled = true;
        this.alertMessage.style.display = 'none';

        let partnumSelected = element.dataset.partnum;
        let comment = this.comment.value.trim();

        if (comment != "" && comment.length) {
            axios
            .post('/api/productos/comentarios/store', {
                partnum: partnumSelected,
                calificacion: this.rating,
                comentario: comment,
            })
            .then(response => {
                //console.log(response.data);
                element.disabled = false;
                this.seccionAddComment.style.display = "none";
                this.thanksMessage.classList.remove('d-block');
                this.thanksMessage.classList.add('d-block');

                //Cargar comentarios
                this.getComments(1);
            })
            .catch(error => {
                //console.log(error);
                element.disabled = false;
                if (typeof error.response !== 'undefined') {

                    this.alertMessage.innerHTML = error.response.data.message;
                    if (error.response.data.errors) {
                        //console.log("errors es: ", error.response.data.errors);

                        this.alertMessage.innerHTML = error.response.data.errors.comentario[0];
                    }
                    this.alertMessage.style.display = 'block';
                }
            });
        } else {
            this.alertMessage.innerHTML = 'Ingresa un comentario.';
            this.alertMessage.style.display = 'block';
        }
        element.disabled = false;
    }

    getComments(number)
    {
        axios
        .post('/api/productos/comentarios', {
            partnum: this.productPartNum.value,
            page: number,
        })
        .then(response => {
            console.log("commentarios");
            console.log(response.data);
            let comments = response.data.data;
            let pages = response.data.total/2;

            //this.setPagination(response.data.current, pages);
            this.getAverageAndPercentRating(comments);
            let template = '';

            for (let comment of comments) {
                template += this.getReviewTemplate(comment);
            }
            this.containerReviews.innerHTML = template;
        })
        .catch(error => {
            if (typeof error.response !== 'undefined') {
                console.log("Error", error.response);
            }
        });
    }

    setPagination(PaginaActual, TotalPag)
    {
        let ul = this.pagination.querySelector('ul');
        let first = '';
        let prev = '';
        let current = '';
        let next = '';
        let after = '';
        let last = '';

        //console.log(element);
        console.log("PaginaActual " , PaginaActual);
        console.log("TotalPag " , TotalPag);
        if (TotalPag > 0 && TotalPag <= 3) {

            if (PaginaActual - 1 > 0) {
                prev = `
                <li class="page-item">
                    <a class="page-link" data-page="${PaginaActual - 1}">
                        ${PaginaActual - 1}
                    </a>
                </li>
                `;
            }

            current = `
            <li class="page-item">
                <a class="page-link current" style="cursor: default;" >
                    ${PaginaActual}
                </a>
            </li>
            `;

            if (PaginaActual + 1 <=  TotalPag) {
                next = `
                <li class="page-item">
                    <a class="page-link" data-page="${PaginaActual + 1}" >
                        ${PaginaActual + 1}
                    </a>
                </li>
                `;
            }

            if (PaginaActual == 1 && TotalPag > 2) {
                after = `
                <li class="page-item">
                    <a class="page-link" data-page="${PaginaActual + 2}" >
                        ${PaginaActual + 2}
                    </a>
                </li>
                `;
            }

            let paginationLinks = prev + current + next + after;
            ul.innerHTML = paginationLinks;
        }

        if (TotalPag > 3) {

            if (PaginaActual >= TotalPag - 1 ) {
                first = `
                <li class="page-item">
                    <a class="page-link" data-page="1" >
                        1
                    </a>
                </li>
                <li class="page-item">
                    <a style="font-size: 20px; font-weight: bold;">...</a>
                </li>
                `;
            }

            if (PaginaActual - 1 > 0) {
                prev = `
                <li class="page-item">
                    <a class="page-link" data-page="${PaginaActual - 1}" >
                        ${PaginaActual - 1}
                    </a>
                </li>
                `;
            }

            current = `
            <li class="page-item">
                <a class="page-link current" style="cursor: default;">
                    ${PaginaActual}
                </a>
            </li>
            `;

            if (PaginaActual + 1 <=  TotalPag) {
                next = `
                <li class="page-item">
                    <a class="page-link" data-page="${PaginaActual + 1}" >
                        ${PaginaActual + 1}
                    </a>
                </li>
                `;
            }

            if (PaginaActual == 1 ) {
                after = `
                <li class="page-item">
                    <a class="page-link" data-page="${PaginaActual + 2}">
                        ${PaginaActual + 2}
                    </a>
                </li>
                `;
            }

            if (PaginaActual < TotalPag - 1 ) {
                last = `
                <li class="page-item">
                    <a style="font-size: 20px; font-weight: bold;">...</a>
                </li>
                <li class="page-item">
                    <a class="page-link" data-page="${TotalPag}" >
                        ${TotalPag}
                    </a>
                </li>
                `;
            }

            let paginationLinks = first + prev + current + next + after + last;
            ul.innerHTML = paginationLinks;
        }
    }

    changePage(e)
    {
        let elemento = e.target;
        var $target = $(event.target);
        let page = $target.data('page');

        if(page != undefined){
            this.getComments(page,elemento);
        }
    }

    getAverageAndPercentRating(comments)
    {
        let totalComments = comments.length
        let sum = 0;
        let ratings = [
            {
                name: 'one',
                total: 0
            },
            {
                name: 'two',
                total: 0
            },
            {
                name: 'three',
                total: 0
            },
            {
                name: 'four',
                total: 0
            },
            {
                name: 'five',
                total: 0
            },
        ];

        for (let comment of comments) {
            sum += parseInt(comment.calificacion);

            //Obtener suma total en cada calificacion
            switch (comment.calificacion) {
                case 1:
                    ratings[0].total++;
                    break;
                case 2:
                    ratings[1].total++;
                    break;
                case 3:
                    ratings[2].total++;
                    break;
                case 4:
                    ratings[3].total++;
                    break;
                case 5:
                    ratings[4].total++;
                    break;
            }
        }

        //Mostrar estadisticas en la vista
        this.baseReviews.innerHTML = totalComments;
        if (comments.length) {
            let averageRating =  Math.round(sum / comments.length);

            // Agregar las estadisticas en los comentarios
            this.averageRating.innerHTML = averageRating;

            // Agregar las estadisticas del producto
            this.containerStarsProduct.innerHTML = this.getStarsTemplate(averageRating);
            this.containerCounterReviewsProduct.innerHTML = `Recomendaciones (${ comments.length })`;
        } else {
            // Agregar las estadisticas del producto
            this.containerStarsProduct.innerHTML = this.getStarsTemplate(0);
            this.containerCounterReviewsProduct.innerHTML = `Recomendaciones (${ 0 })`;
        }

        for (let rating of ratings) {
            let statsRating = this.commentsSection.querySelector('#stats-' + rating.name + '-rating');
            let percentStatsRating = statsRating.querySelector('.progress-bar');
            let totalSumStatsRating = statsRating.querySelector('span');
            let percent = (rating.total / totalComments) * 100;
            percentStatsRating.style.width =  percent + '%';
            totalSumStatsRating.innerHTML = rating.total;
        }
    }

    getReviewTemplate(comment)
    {
        let template = `
        <ul class="border-bottom border-color-1 pb-4 mb-4">
            <!-- Review Rating -->
            <div
                class="d-flex justify-content-between align-items-center text-secondary font-size-1 mb-2">
                <div class="text-warning text-ls-n2 font-size-16" style="width: 80px">
                    ${ this.getStarsTemplate(comment.calificacion) }
                </div>
            </div>
            <!-- End Review Rating -->

            <p class="text-gray-90">
                ${comment.comentario}
            </p>

            <!-- Reviewer -->
            <div class="mb-2">
                <strong>${comment.user.name}</strong>
                <span class="font-size-13 text-gray-23"> - ${comment.created_at}</span>
            </div>
            <!-- End Reviewer -->
        </ul>
        `;
        return template;
    }

    getStarsTemplate(rating)
    {
        let template = '';
        for (let i = 1; i < 6; i++) {
            if (i <= rating) {
                template += '<small class="fas fa-star"></small>';
            } else {
                template += '<small class="far fa-star text-muted"></small>';
            }
        }
        return template;
       /* let template = `
        <small class="fas fa-star"></small>
        <small class="fas fa-star"></small>
        <small class="fas fa-star"></small>
        <small class="far fa-star text-muted"></small>
        <small class="far fa-star text-muted"></small>
        `;*/
    }
}
export default Comments;
