class PersonalInformation
{
    constructor()
    {
        this.personalInformation = document.querySelector('#personal-information');

        if (this.personalInformation != null) {
            this.formPersonalInformation = this.personalInformation.querySelector('#form-personal-information');
            this.alertMessage = this.formPersonalInformation.querySelector('#alert-message');
            this.successMessage = this.formPersonalInformation.querySelector('#success-message');
            this.buttonSubmit = this.formPersonalInformation.querySelector('#button-submit');
            //console.log(this.buttonSubmit);
            this.bindEvents();
        }
    }

    bindEvents()
    {
        document.addEventListener('submit', this.submitEvents.bind(this));
    }

    submitEvents(e)
    {
        if (e.target.matches('#' + this.formPersonalInformation.id)) {
            this.submitForm(e);
        }
    }

    submitForm(e)
    {
        e.preventDefault();
        let element = e.target;

        if (this.validateFields()) {
            const formData = new FormData(element)
            formData.append("_method", "put");
            this.successMessage.innerHTML = '';
            this.alertMessage.innerHTML = '';
            this.buttonSubmit.innerHTML = 'Actualizando ...';
            this.buttonSubmit.disabled = true;

            axios
            .post('/api/usuarios/informacion-personal', formData)
            .then(response => {
                //console.log(response);
                this.buttonSubmit.innerHTML = 'Actualizar';
                this.buttonSubmit.disabled = false;
                this.successMessage.innerHTML = response.data.message;
            })
            .catch(error => {
                if (typeof error.response !== 'undefined') {
                    console.log(error.response);
                    this.buttonSubmit.innerHTML = 'Actualizar';
                    this.buttonSubmit.disabled = false;
                    this.alertMessage.innerHTML = error.response.data.message;
                }
            });
        } else {
            this.alertMessage.innerHTML = 'Debes completar todos los campos';
        }
    }

    validateFields()
    {
        let allInputs  = this.getAllElementsWithAttribute('required');
        let formIsValid = true;

        for (let key in allInputs) {
            //console.log(allInputs[key]);
            let inputElement = allInputs[key];
            let name = inputElement.name;
            let nameIdErrorMessage = name.replace(/\[/g, '');
            nameIdErrorMessage = nameIdErrorMessage.replace(/\]/g, '');
            let id = inputElement.id
            let type = inputElement.type;
            let value = inputElement.value;
            let message = inputElement.dataset.msg;

            //console.log(type);
            switch (type) {
                case 'text':
                case 'select-one':
                    //console.log('Es text o select-one');
                    //console.log('El name es: ', name);
                    if (!value) {
                        formIsValid = false;
                        inputElement.parentElement.classList.add('u-has-error');
                        var errorMessage = `
                        <div id="error-message-${ nameIdErrorMessage }" class="invalid-feedback" style="display: block;">
                            ${ message }
                        </div>`;

                        if (this.personalInformation.querySelector('#error-message-' + nameIdErrorMessage) == null) {
                            inputElement.insertAdjacentHTML('afterend', errorMessage);
                        }

                    } else {
                        inputElement.parentElement.classList.remove('u-has-error');
                        if (this.personalInformation.querySelector('#error-message-' + nameIdErrorMessage) != null) {
                            this.personalInformation.querySelector('#error-message-' + nameIdErrorMessage).remove();
                        }
                    }
                    break;
                case 'checkbox':
                case 'radio':
                    //console.log(type);
                    //console.log('El tipo es checkbox o radio y su nombre es: ', name);
                    const checkboxes = document.querySelectorAll('input[name="' + name + '"]:checked');
                    if (!checkboxes.length) {
                        //console.log('El elemento ' + name + ' no esta seleccionado');
                        formIsValid = false;
                        let checkboxElement = this.personalInformation.querySelector('input[name="' + name + '"]');
                        let closestParent = checkboxElement.closest(".js-form-message")
                        //console.log('el closestParent es: ', closestParent);

                        var errorMessage = `
                        <div id="error-message-${ nameIdErrorMessage }" class="invalid-feedback" style="display: block;">
                            ${ message }
                        </div>`;

                        if (this.personalInformation.querySelector('#error-message-' + nameIdErrorMessage) == null) {
                            closestParent.insertAdjacentHTML('beforeend', errorMessage);
                        }
                    } else {
                        if (this.personalInformation.querySelector('#error-message-' + nameIdErrorMessage) != null) {
                            this.personalInformation.querySelector('#error-message-' + nameIdErrorMessage).remove();
                        }
                    }
                    break;
               /* case 'file':
                    //console.log(type);
                    //console.log('El tipo es file y su nombre es: ', name);
                    if (!value) {
                        formIsValid = false;
                        var errorMessage = `
                        <div id="error-message-${ nameIdErrorMessage }" class="invalid-feedback" style="display: block; margin-top: 40px;">
                            ${ message }
                        </div>`;

                        if (this.personalInformation.querySelector('#error-message-' + nameIdErrorMessage) == null) {
                            inputElement.insertAdjacentHTML('afterend', errorMessage);
                        }
                    } else {
                        let size = inputElement.files[0].size;
                        //console.log('El tamaño del archivo es: ', size);

                        if (size > 2097152) {
                            //Si el tamaño del archivo es mayor a 2MB
                            formIsValid = false;
                            var errorMessage = `
                            <div id="error-message-${ nameIdErrorMessage }" class="invalid-feedback" style="display: block; margin-top: 40px;">
                               El archivo debe pesar menos de 2MB
                            </div>`;

                            if (this.personalInformation.querySelector('#error-message-' + nameIdErrorMessage) == null) {
                                inputElement.insertAdjacentHTML('afterend', errorMessage);
                            } else {
                                this.personalInformation.querySelector('#error-message-' + nameIdErrorMessage).innerHTML =  errorMessage;
                            }
                        } else {
                            if (this.personalInformation.querySelector('#error-message-' + nameIdErrorMessage) != null) {
                                this.personalInformation.querySelector('#error-message-' + nameIdErrorMessage).remove();
                            }
                        }
                    }
                    break;*/
            }
        }
        return formIsValid;
    }

    getAllElementsWithAttribute(attribute)
    {
      var matchingElements = [];
      var allElements = this.personalInformation.getElementsByTagName('*');

      for (var i = 0, n = allElements.length; i < n; i++) {
        if (allElements[i].getAttribute(attribute) !== null) {
          // Element exists with attribute. Add to array.
          matchingElements.push(allElements[i]);
        }
      }
      return matchingElements;
    }
}

export default PersonalInformation;
