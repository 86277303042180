class Trm
{
    constructor()
    {
        this.header = document.querySelector("#header");
        this.containerTRM = this.header.querySelector("#trm");

        if (this.containerTRM != null) {
            this.bindEvents();
        }
    }

    bindEvents()
    {
        this.getTRM();
    }

    getTRM()
    {
        let valorTrm;
        let trm = this.containerTRM;

        axios.get('/api/trm')
        .then(function(response) {
            if (response.data.status == 200) {
                valorTrm = response.data.data.value;
                trm.innerHTML = valorTrm;
            }
        })
        .catch(function(error){
            if (typeof error.response !== 'undefined') {
                console.log("Error", error.response);
             }
        });
    }
}

export default Trm;
