class SearchProductForm
{
    constructor()
    {
        this.formButtonClass = document.querySelectorAll('.btn-search-product-by-term');
        if (this.formButtonClass != null) {
            this.searchProductForm = document.querySelectorAll('.search-product-form');
            this.inputTerm = document.querySelectorAll('.input-term');
            this.suggestionsCover = document.querySelector('.suggestions-cover');
            this.bindEvents();
        }
    }

    bindEvents()
    {
        this.formButtonClass.forEach(element => {
            element.addEventListener('click', this.sendForm.bind(this));
        });

        this.searchProductForm.forEach(element => {
            element.addEventListener('submit', this.sendForm.bind(this));
        });

        this.inputTerm.forEach(element => {
            element.addEventListener('keyup', this.onKeyUp.bind(this));
        });

        document.addEventListener('click', this.hideSuggestions.bind(this));
    }

    sendForm()
    {
        event.preventDefault();

        let element = event.target;
        let form = element.closest('form');
        let input = form.querySelector('.input-term');
        if (input.value.length) {
            document.location = '/productos/buscar/' + input.value;
        }
    }

    async onKeyUp()
    {
        let element = event.target;
        //console.log("on key up term");

        if(element.value.length > 2) {
            let searchProductForm = this.searchProductForm;

            let res = await this.searchTerm(element.value);
            let products = res.data.data;
            let productList = '';
            //console.log("products: ", products);
            products.forEach((product) => {
                //console.log("product: ", product);
                productList += `<li class="suggestions-list-item">
                                    <a href="/productos/${product.slug}" class="text-gray-90 mb-0 text-lh-1dot2"><span class="ec ec-search font-size-20 mr-2"></span>${product.Name.substring(0,52)}</a>
                                </li>`;
            });

            //console.log("searchProductForm " , this.searchProductForm );
            this.searchProductForm.forEach(formElement => {
                //console.log("formElement: ", formElement);

                let suggestionContainer = formElement.querySelector('.suggestions-container');

                let suggestionList = formElement.querySelector('.suggestions-list');

                //console.log("suggestionList: " , suggestionList);
                suggestionList.innerHTML = productList;

                suggestionContainer.classList.add('suggestions-container-visible');

                this.suggestionsCover.style.height = document.body.clientHeight + 'px';
                this.suggestionsCover.classList.add('suggestions-cover-visible');
            });
        } else if(element.value.length <= 2){
            this.searchProductForm.forEach(formElement => {
                //console.log("formElement: ", formElement);
                let suggestionContainer = formElement.querySelector('.suggestions-container');

                suggestionContainer.classList.remove('suggestions-container-visible');
            });

            this.suggestionsCover.classList.remove('suggestions-cover-visible');
        }
    }

    hideSuggestions()
    {
        let element = event.target;
        //console.log("se hizo click en: ", element);
        if (element.classList.contains('suggestions-cover') || element.classList.contains('navbar')) {
            console.log("cerrar suggestions");
            this.suggestionsCover.classList.remove('suggestions-cover-visible');
            this.searchProductForm.forEach(formElement => {
                //console.log("formElement: ", formElement);
                let suggestionContainer = formElement.querySelector('.suggestions-container');

                suggestionContainer.classList.remove('suggestions-container-visible');
            });
        }
    }

    searchTerm(term)
    {
        return axios
                .post("/api/productos/buscar-sugerencias-por-termino", {
                    term: term
                }).then(function(response) {
                    return response;
                })
    }
}
export default SearchProductForm;
