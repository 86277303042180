import { template } from "lodash";

class GetAllBlogs {
    constructor()
    {
        this.container = $("#blogs-container");
        if (this.container.length) {
            this.paginator = $("#blog-paginator");
            this.bindEvents();
        }
    }

    bindEvents()
    {
        this.firstPetition(1);
    }

    handleClick(e, buttons)
    {
        e.preventDefault();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        buttons.removeClass("current");
        e.target.classList.add("current");
        let page = e.target.innerHTML;
        this.petition(page);
    }

    firstPetition(page)
    {
        axios
            .get(`/api/blogs/${page}`)
            .then(response => {
                const { data, pages } = response.data;
                //console.log(response);
                let nPages = parseInt(pages);
                if (nPages > 0) {
                    for (let i = 0; i <= nPages; i++) {
                        let template;
                        if (i === 0) {
                            template = `<li class="page-item"><a class="page-link current" href="#">${i +
                                1}</a></li>`;
                        } else {
                            template = `<li class="page-item"><a class="page-link" href="#">${i +
                                1}</a></li>`;
                        }
                        this.paginator.append(template);
                    }
                }
                let link = $("#blog-paginator .page-link");
                link.on("click", e => {
                    this.handleClick(e, link);
                });
                data.forEach(blog => {
                    //console.log(blog);
                    const {
                        updated_at,
                        slug,
                        descripcion_corta,
                        titulo,
                        url_imagen
                    } = blog;
                    let date = new Date(updated_at);
                    var month = date.getUTCMonth();
                    var day = date.getUTCDate();
                    var year = date.getUTCFullYear();
                    const monthNames = [
                        "Enero",
                        "Febrero",
                        "Marzo",
                        "Abril",
                        "Mayo",
                        "Junio",
                        "Julio",
                        "Agosto",
                        "Septiembre",
                        "Octubre",
                        "Noviembre",
                        "Deciembre"
                    ];
                    let newdate = `${monthNames[month]} ${day}, ${year}`;
                    let template = `<div class="col-lg-4">
                                        <article class="card mb-13 border-0">
                                            <a href="/blog/${slug}" class="d-block"><img class="img-fluid"
                                                    src="${url_imagen}" alt="Image Description"></a>
                                            <div class="card-body pt-3 pb-0 px-0">
                                                <h4 class="mb-0"><a href="/blog/${slug}">${titulo}</a></h4>
                                                <div class="mb-3 pb-3 border-bottom">
                                                    <div
                                                        class="list-group list-group-horizontal flex-wrap list-group-borderless align-items-center mx-n0dot5">
                                                        <a href="/blog/${slug}" class="mx-0dot5 text-gray-5">${newdate}</a>
                                                    </div>
                                                </div>
                                                <p>${descripcion_corta}</p>
                                                <div class="flex-horizontal-center">
                                                    <a href="/blog/${slug}"
                                                        class="btn btn-soft-secondary-w mb-md-0 font-weight-normal px-5 px-md-4 px-lg-5">Leer más</a>
                                                </div>
                                            </div>
                                        </article>
                                    </div>`;
                    this.container.append(template);
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    petition(page)
    {
        axios
            .get(`/api/blogs/${page}`)
            .then(response => {
                const { data, pages } = response.data;
                this.container.empty();
                data.forEach(blog => {
                    //console.log(blog);
                    const {
                        updated_at,
                        slug,
                        descripcion_corta,
                        titulo,
                        url_imagen
                    } = blog;
                    let date = new Date(updated_at);
                    var month = date.getUTCMonth();
                    var day = date.getUTCDate();
                    var year = date.getUTCFullYear();
                    const monthNames = [
                        "Enero",
                        "Febrero",
                        "Marzo",
                        "Abril",
                        "Mayo",
                        "Junio",
                        "Julio",
                        "Agosto",
                        "Septiembre",
                        "Octubre",
                        "Noviembre",
                        "Deciembre"
                    ];
                    let newdate = `${monthNames[month]} ${day}, ${year}`;
                    let template = `<div class="col-lg-4">
                                        <article class="card mb-13 border-0">
                                            <a href="/blog/${slug}" class="d-block"><img class="img-fluid"
                                                    src="${url_imagen}" alt="Image Description"></a>
                                            <div class="card-body pt-3 pb-0 px-0">
                                                <h4 class="mb-0"><a href="/blog/${slug}">${titulo}</a></h4>
                                                <div class="mb-3 pb-3 border-bottom">
                                                    <div
                                                        class="list-group list-group-horizontal flex-wrap list-group-borderless align-items-center mx-n0dot5">
                                                        <a href="/blog/${slug}" class="mx-0dot5 text-gray-5">${newdate}</a>
                                                    </div>
                                                </div>
                                                <p>${descripcion_corta}</p>
                                                <div class="flex-horizontal-center">
                                                    <a href="/blog/${slug}"
                                                        class="btn btn-soft-secondary-w mb-md-0 font-weight-normal px-5 px-md-4 px-lg-5">Leer más</a>
                                                </div>
                                            </div>
                                        </article>
                                    </div>`;
                    this.container.append(template);
                });
            })
            .catch(error => {
                console.log(error);
            });
    }
}

export default GetAllBlogs;
