class addProductSeen
{
    constructor()
    {
        this.productDetail = document.querySelector('#product-detail');
        if (this.productDetail != null) {
            this.productSlug = this.productDetail.querySelector('#product-slug');
        }
        this.bindEvents();
    }

    bindEvents()
    {
        //Si existe el elemento activar los eventos
        if (this.productDetail != null) {
            this.setProductSeen();
        }
    }

    setProductSeen()
    {
        let mpsProductsSeen = localStorage.getItem('mps-products-seen');

        if (mpsProductsSeen != null) {
            let arr = mpsProductsSeen.split(',');

            //Si el array contiene mas de 5 elementos remover el primer elemento
            if (arr.length > 5) {
                let menosElemento = arr.shift();
                //console.log(arr);
                localStorage.setItem('mps-products-seen', arr.join(','));
            }

            //Agregar el producto actual si no existe en el array
            // const productExist = arr.find(element => element == this.productPartNum.value);
            const productExist = arr.find(element => element == this.productSlug.value);
            if (!productExist) {
                mpsProductsSeen = localStorage.getItem('mps-products-seen');
                mpsProductsSeen += ',' + this.productSlug.value;
                localStorage.setItem('mps-products-seen', mpsProductsSeen);
            } else {
            }
        } else {
            mpsProductsSeen = this.productSlug.value;
            localStorage.setItem('mps-products-seen', mpsProductsSeen);
        }

    }
}

export default addProductSeen;
