//import Axios from "axios";

class MegaMenu
{
    constructor()
    {
        this.menus = $(".megamenu-subcategories-list");
        this.menuMovil = $('#menuMovil');
        this.menuLateral = $('#sidebarHeader');
        this.background = $('#background-black');
        this.btnBurger = this.menuMovil.find('#sidebarHeaderMenu');
        this.btnClose = this.menuLateral.find('#btn-close-menu');
        this.estado = "abierto";

        this.bindEvents();
    }

    bindEvents()
    {
        this.btnBurger.on("click", this.clickEvents.bind(this));
        this.btnClose.on("click", this.clickEvents.bind(this));
        this.menus.each((i, menu) => {
            let idCategory = menu.dataset.category;
            this.petition(menu, idCategory);
        });
    }

    clickEvents(e)
    {
        let body = document.getElementsByTagName('body')[0];

        if(  this.estado == "abierto"){

            this.background.removeClass('d-none');
            this.background.addClass('d-block');
            body.classList.add('noOverflow');
            body.classList.remove('overflow');

            this.estado = "cerrado";
            this.background.addClass('d-none');
        }else{

            this.background.removeClass('d-block');
            this.background.addClass('d-none');
            body.classList.remove('noOverflow');
            body.classList.add('overflow');

            this.estado = "abierto";
            this.background.addClass('d-block');
        }

    }

    petition(menu, idCategory)
    {
        axios
        .get(`/api/subcategorias/${idCategory}`)
        .then(response => {
            if (response.data.data.length) {
                let subcategories = response.data.data;
                subcategories.forEach(subcategory => {
                    const {
                        SubCatego,
                        CodigoCategoria,
                        CodigoSubCategoria
                    } = subcategory;
                    let template = `<li><a href="/productos/subcategorias/${CodigoCategoria}/${CodigoSubCategoria}" class="nav-link u-header__sub-menu-nav-link">${SubCatego}</a></li>`;
                    $(menu).append(template);
                });
            }
        })
        .catch(error => {
            console.log(error);
        });
    }
}
export default MegaMenu;
