class PhoneOrders
{
    constructor()
    {
        this.phoneOrders = document.querySelector('#phone-orders');
        if (this.phoneOrders != null) {
            this.order = this.phoneOrders.querySelector('#order');
            this.alertMessage = this.phoneOrders.querySelector('#alert-message');
            this.btnSearchOrder = this.phoneOrders.querySelector('#btn-search-order');

            this.bindEvents();
        }
    }

    bindEvents()
    {
        document.addEventListener('click', this.clickEvents.bind(this));
    }

    clickEvents (e)
    {
        if (e.target.matches('#' + this.btnSearchOrder.id)) {
            this.searchOrder(e);
        }
    }

    searchOrder(e)
    {
        let element = e.target;
        this.alertMessage.innerHTML = '';
        element.disabled = true;
        element.innerHTML = 'Buscando ...';
        let order = this.order.value.trim();
        if (order) {
            document.location.href = '?ref=' + order;
        } else {
            this.alertMessage.innerHTML = 'Debes ingresar un número de pedido.';
            element.disabled = false;
            element.innerHTML = 'Buscar';
        }
    }
}

export default PhoneOrders;
