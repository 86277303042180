import CartService from "../utils/cartService";
import CouponService from "./couponService";

class Checkout {
    constructor() {
        this.checkout = document.querySelector("#checkout");
        if (this.checkout != null) {
            this.cartService = new CartService();
            this.couponService = new CouponService();
            this.decryptedCartProducts = null;
            this.productsMps = [];
            this.containerCartList = this.checkout.querySelector(
                "#containerCartList"
            );
            this.tableCartList = this.checkout.querySelector(
                "#table-cart-list"
            );
            this.cartList = this.checkout.querySelector("#cartList");
            this.productsHtml = "";
            this.tableFootSubTotal = this.tableCartList.querySelector(
                "#subTotal"
            );
            this.tableFootCouponTotal = this.tableCartList.querySelector(
                "#cupon"
            );
            this.tableFootIva = this.tableCartList.querySelector("#iva");
            this.tableFootEnvio = this.tableCartList.querySelector("#envio");
            this.tableFootTotal = this.tableCartList.querySelector(
                "#total strong"
            );
            this.valorSubTotal = 0;
            this.valorIva = 0;
            this.valorSubTotalMasIva = 0;
            this.valorEnvio = 0;
            this.valorTotal = 0;

            this.paymentMethod = null;
            this.hasCreditCapacityMps = null;
            this.hasMainAddress = null;

            this.tableFoot = this.tableCartList.querySelector("tfoot");
            this.containerButtonBackToCart = this.checkout.querySelector(
                "#container-button-back-to-cart"
            );
            this.containerPaymentMethod = this.checkout.querySelector(
                "#container-payment-method"
            );
            this.inputRadioPaymentMethod =
                '#container-payment-method input[name="inputRadioPaymentMethod"]';
            this.messagecreditMps = this.containerPaymentMethod.querySelector(
                "#mensaje-credito-mps"
            );

            this.containerCoupon = document.querySelector("#container-coupon");
            this.coupon = this.containerCoupon.querySelector("#coupon-input");
            this.btnCoupon = this.containerCoupon.querySelector("#btn-coupon");
            this.couponAlertMessage = this.containerCoupon.querySelector(
                "#coupon-alert-message"
            );
            this.couponApplied = false;
            this.couponResponse = null;

            this.checkTerms = this.containerPaymentMethod.querySelector(
                "#check-terms"
            );
            this.btnPerformPayment = this.containerPaymentMethod.querySelector(
                "#btn-perform-payment"
            );
            this.cartAlertMessage = this.checkout.querySelector(
                "#cart-alert-message"
            );
            this.paymentAlertMessage = this.checkout.querySelector(
                "#payment-alert-message"
            );
            this.bindEvents();
        }
    }

    bindEvents() {
        this.getCartItems();
        document.addEventListener("click", this.clickEvents.bind(this));
        document.addEventListener("change", this.changeEvents.bind(this));
        this.btnCoupon.addEventListener("click", this.aplyCoupon.bind(this));
    }

    clickEvents(e) {
        if (e.target.matches("#" + this.btnPerformPayment.id)) {
            this.performPayment(e);
        }
    }

    changeEvents(e) {
        if (e.target.matches(this.inputRadioPaymentMethod)) {
            this.changePaymentMethod(e);
        }
    }

    async changePaymentMethod(e) {
        let element = e.target;
        let paymentMethod = element.value;

        //Deshabilitar boton de pago
        this.btnPerformPayment.disabled = true;

        if (paymentMethod == "mps") {
            this.paymentMethod = "mps";
            let mpsCredit = null;

            try {
                mpsCredit = await this.checkSpecialClientMpsPromise();
            } catch (error) {
                if (typeof error.response !== "undefined") {
                    if (typeof error.response !== "undefined") {
                        this.paymentAlertMessage.innerHTML =
                            error.response.data.message;
                        this.paymentAlertMessage.style.display = "block";
                    }
                }
            }

            if (mpsCredit.AccountNum != null) {
                //Pendiente: En espera de verificar si se implementa lo del cobro
                //del flete para usuarios con credito mps
                //this.calculateShippingWithMpsCredit(mpsCredit.GeneraFlete);

                //Verificar si el usuario tiene capacidad de credito
                if (this.valorTotal <= parseInt(mpsCredit.CreditLimit)) {
                    console.log("tiene capacidd de credito");
                    this.hasCreditCapacityMps = true;
                    this.messagecreditMps.innerHTML = `MPS te informa que puedes continuar con este medio de pago.`;
                } else {
                    console.log("no tiene capacidd de credito");
                    this.hasCreditCapacityMps = false;
                    this.messagecreditMps.innerHTML = `Actualmente no tienes
                    capacidad de credito MPS, por favor selecciona otro medio de pago.`;
                }

                this.btnPerformPayment.disabled = false;
            } else {
                console.log("el cliente no existe en mps");
                this.hasCreditCapacityMps = false;
                this.messagecreditMps.innerHTML = `Actualmente no tienes
                capacidad de credito MPS, por favor selecciona otro medio de pago.`;
            }
        } else if (paymentMethod == "credibanco") {
            this.paymentMethod = "credibanco";
            this.btnPerformPayment.disabled = false;
        } else {
        }
    }

    checkSpecialClientMpsPromise() {
        return axios
            .get("/api/checkout/cliente-especial-mps")
            .then(response => {
                return response.data.data;
            });
    }

    performPayment(e) {
        e.preventDefault();
        let element = e.target;
        element.disabled = true;
        element.innerHTML = "Procesando ...";
        this.paymentAlertMessage.style.display = "none";

        if (this.hasMainAddress) {
            if (this.checkTerms.checked) {
                switch (this.paymentMethod) {
                    case "credibanco":
                        this.sendPayment(element);
                        break;
                    case "mps":
                        if (this.hasCreditCapacityMps) {
                            this.sendPayment(element);
                        } else {
                            this.paymentAlertMessage.innerHTML =
                                "No tienes capacidad crediticia para hacer este pago.";
                            this.paymentAlertMessage.style.display = "block";
                            element.disabled = false;
                            element.innerHTML = "Finalizar Compra";
                        }
                        break;
                    default:
                        this.paymentAlertMessage.innerHTML =
                            "Debes seleccionar un método de pago.";
                        this.paymentAlertMessage.style.display = "block";
                        element.disabled = false;
                        element.innerHTML = "Finalizar Compra";
                        break;
                }
            } else {
                this.paymentAlertMessage.innerHTML =
                    "Debes aceptar los términos y condiciones.";
                this.paymentAlertMessage.style.display = "block";
                element.disabled = false;
                element.innerHTML = "Finalizar Compra";
            }
        }
    }

    sendPayment(element) {
        let cartProductsInLocalStorage = this.cartService.getCartLocalStorage();
        //console.log('cartProductsInLocalStorage ' , cartProductsInLocalStorage);
        axios
            .post("/api/pagos", {
                cartProducts: cartProductsInLocalStorage,
                subTotal: Math.round(this.valorSubTotal),
                iva: Math.round(this.valorIva),
                envio: Math.round(this.valorEnvio),
                cupon: this.couponResponse,
                total: Math.round(this.valorTotal),
                metodo: this.paymentMethod
            })
            .then(response => {
                //console.log(response);
                this.cartService.clearCart();
                document.location.href = response.data.data.formUrl;
            })
            .catch(error => {
                console.log(error);
                if (typeof error.response !== "undefined") {
                    this.paymentAlertMessage.innerHTML =
                        error.response.data.message;
                    this.paymentAlertMessage.style.display = "block";
                    element.disabled = false;
                    element.innerHTML = "Finalizar Compra";
                }
            });
    }

    async getCartItems() {
        let cartProductsInLocalStorage = this.cartService.getCartLocalStorage();
        if (
            typeof cartProductsInLocalStorage != "" &&
            cartProductsInLocalStorage != null &&
            cartProductsInLocalStorage.length > 0
        ) {
            //let this.decryptedCartProducts = null;
            try {
                this.decryptedCartProducts = await this.cartService.decryptCart(
                    cartProductsInLocalStorage
                );
            } catch (error) {
                if (typeof error.response !== "undefined") {
                    console.log("Catch del error: ", error.response.data);
                }
            }

            if (this.decryptedCartProducts.length > 0) {
                this.cartList.innerHTML = "";

                //Obtener los productos
                this.getProductsPromise()
                    .then(result => {
                        console.log("Muestra resolve: " + result);
                        this.cartList.innerHTML = this.productsHtml;
                        this.containerCartList.classList.remove("u-slick");
                        this.tableFootSubTotal.innerHTML =
                            "$" +
                            Math.round(this.valorSubTotal).toLocaleString(
                                "de-DE"
                            );
                        this.tableFootIva.innerHTML =
                            "$" +
                            Math.round(this.valorIva).toLocaleString("de-DE");

                        //Calcular costo de envio
                        this.calculateShipping();
                    })
                    .catch(error => {
                        //Si existe un producto que supere la disponibilidad, entonces
                        //dejar de mostrar los demás productos en el carrito
                        console.log("Muestra error: " + error);
                        this.cartList.innerHTML = this.productsHtml;
                        this.containerCartList.classList.remove("u-slick");
                        this.tableCartList.style.display = "block";
                        this.containerButtonBackToCart.style.display = "block";
                    });
            }
        } else {
            /*this.title.innerHTML = "Aun no tienes productos en el carrito";
            this.containerCartList.style.display = 'none';*/
        }
    }

    async calculateShipping() {
        let mainAddress = null;
        let shippingCost = null;

        try {
            mainAddress = await this.getMainAddressPromise();
            shippingCost = await this.getShippingCost();
        } catch (error) {
            if (typeof error.response !== "undefined") {
                console.log("Catch del error: ", error.response.data);
            }
        }

        if (mainAddress != null) {
            this.hasMainAddress = true;
            this.valorSubTotalMasIva = this.valorSubTotal + this.valorIva;
            this.valorEnvio = 0;
            this.valorTotal = 0;
            let specialClientMps = null;
            let zonaEspecial = null;
            zonaEspecial = mainAddress.zona_especial;

            //Consultar cliente especial en MPS
            try {
                specialClientMps = await this.checkSpecialClientMpsPromise();
            } catch (error) {
                if (typeof error.response !== "undefined") {
                    if (typeof error.response !== "undefined") {
                        this.tableCartList.style.display = "block";

                        this.cartAlertMessage.innerHTML =
                            error.response.data.message;
                        this.cartAlertMessage.style.display = "block";
                    }
                }
            }

            if (specialClientMps != null && zonaEspecial != null) {
                console.log("specialClientMps ", specialClientMps);
                console.log("mainAddress ", mainAddress);

                // Si la dirección principal es diferente de "en-sitio" entonces calcular costo del envío
                if (mainAddress.direccion != "en-sitio") {
                    if (specialClientMps.GeneraFlete) {
                        //Genera flete
                        console.log("Genera flete");

                        if (zonaEspecial == 1) {
                            console.log("es zona especial");

                            if (
                                this.valorSubTotalMasIva <
                                parseFloat(shippingCost.genera_flete.zona_especial
                                    .monto_base)
                            ) {
                                this.valorEnvio =
                                    parseFloat(shippingCost.genera_flete.zona_especial.tarifa_menor_a_monto_base);
                            } else {
                                this.valorEnvio =
                                    (this.valorSubTotalMasIva *
                                        parseFloat(shippingCost.genera_flete.zona_especial
                                            .tarifa_mayor_a_monto_base)) /
                                    100;
                            }
                        } else {
                            console.log("no es zona especial");
                            if (
                                this.valorSubTotalMasIva <
                                parseFloat(shippingCost.genera_flete.zona_no_especial
                                    .monto_base)
                            ) {

                                this.valorEnvio =
                                    parseFloat(shippingCost.genera_flete.zona_no_especial.tarifa_menor_a_monto_base);
                            } else {
                                this.valorEnvio =
                                    (this.valorSubTotalMasIva *
                                        parseFloat(shippingCost.genera_flete
                                            .zona_no_especial
                                            .tarifa_mayor_a_monto_base)) /
                                    100;
                            }

                        }
                    } else {
                        //No genera flete
                        console.log("no genera flete");

                        if (specialClientMps.ClienteVip) {
                            console.log("Es cliente vip");
                            if (
                                (mainAddress.departamento_id == "11" &&
                                    mainAddress.ciudad_id == "001") ||
                                (mainAddress.departamento_id == "05" &&
                                    mainAddress.ciudad_id == "001")
                            ) {
                                console.log(
                                    "es bogota o medellín entonces no se cobra flete"
                                );
                                this.valorEnvio = 0;
                            } else {
                                console.log(
                                    "se cobra el " +
                                        parseFloat(shippingCost.no_genera_flete
                                            .tarifa_otra_ciudad) +
                                        "%"
                                );
                                this.valorEnvio =
                                    (this.valorSubTotalMasIva *
                                        parseFloat(shippingCost.no_genera_flete
                                            .tarifa_otra_ciudad)) /
                                    100;
                            }
                        } else {
                            console.log("No es cliente vip");
                            this.valorEnvio = 0;
                        }
                    }
                } else {
                    console.log("Se recoge en sitio (no se cobra envío)");
                }

                this.tableFootEnvio.innerHTML =
                    "$" + Math.round(this.valorEnvio).toLocaleString("de-DE");

                //Calcular Total
                this.valorTotal = Math.round(
                    this.valorSubTotalMasIva + this.valorEnvio
                );

                this.tableFootTotal.innerHTML =
                    "$" + this.valorTotal.toLocaleString("de-DE");

                //Mostrar elementos
                this.tableFoot.classList.remove("hideElement");
                this.tableCartList.style.display = "block";
                this.containerPaymentMethod.style.display = "block";
            }
        } else {
            //console.log('no tiene una direccion principal parar todo');
            this.hasMainAddress = false;
            this.tableCartList.style.display = "block";

            this.cartAlertMessage.innerHTML =
                "Por favor selecciona una dirección de entrega.";
            this.cartAlertMessage.style.display = "block";
        }
    }

    getShippingCost() {
        return axios.get("api/checkout/flete").then(response => {
            return response.data;
        });
    }

    getMainAddressPromise() {
        return axios
            .get("/api/usuarios/direcciones/principal")
            .then(response => {
                //console.log(response.data.data);
                return response.data.data;
            });
    }

    calculateShippingWithMpsCredit(freeShipping) {
        console.log("calculateShippingWithMpsCredit");
        this.valorEnvio = 0;

        if (freeShipping) {
        }

        this.tableFootEnvio.innerHTML =
            "$" + Math.round(this.valorEnvio).toLocaleString("de-DE");

        //Calcular Total
        this.valorTotal = Math.round(
            this.valorSubTotalMasIva + this.valorEnvio
        );
        this.tableFootTotal.innerHTML =
            "$" + this.valorTotal.toLocaleString("de-DE");
    }

    aplyCoupon() {
        let coupon = this.coupon.value.trim();

        if (coupon != "") {
            if (!this.couponApplied) {
                axios
                    .get("/api/checkout/cupon/" + coupon + "/show")
                    .then(response => {
                        console.log(response.data);
                        let coupon = response.data.data;

                        if (coupon != null) {
                            if (!response.data.outOfStock) {
                                if (!response.data.alreadyApplied) {
                                    let couponApplication =
                                        coupon.cupon_aplicacion_id;
                                    this.couponAlertMessage.innerHTML = "";

                                    console.log(
                                        "valorTotal anterior ",
                                        this.valorTotal.toLocaleString("de-DE")
                                    );
                                    this.couponResponse = this.couponService.calculateCouponDiscount(
                                        coupon,
                                        this.decryptedCartProducts,
                                        this.productsMps
                                    );
                                    console.log(
                                        " this.couponResponse ",
                                        this.couponResponse
                                    );
                                    // Determinar si se aplica o no el descuento
                                    if (
                                        this.couponResponse
                                            .couponShouldBeApplied
                                    ) {
                                        this.couponApplied = true;
                                        this.valorTotal -= this.couponResponse.discount;
                                        this.tableFootCouponTotal.innerHTML =
                                            "- $" +
                                            this.couponResponse.discount.toLocaleString(
                                                "de-DE"
                                            );
                                        this.tableFootTotal.innerHTML =
                                            "$" +
                                            this.valorTotal.toLocaleString(
                                                "de-DE"
                                            );
                                        this.showCuponMessage(
                                            "success",
                                            "Cupón aplicado satisfactoriamente."
                                        );
                                    } else {
                                        this.couponApplied = false;
                                        this.showCuponMessage(
                                            "error",
                                            this.couponResponse.alertMessage
                                        );
                                    }
                                } else {
                                    this.showCuponMessage(
                                        "error",
                                        "El código ya fue aplicado con anterioridad."
                                    );
                                }
                            } else {
                                this.showCuponMessage(
                                    "error",
                                    "Se han agotado las existencias para este cupón."
                                );
                            }
                        } else {
                            this.showCuponMessage(
                                "error",
                                "El código aplicado no es válido."
                            );
                        }
                    })
                    .catch(error => {
                        if (typeof error.response !== "undefined") {
                            if (typeof error.response !== "undefined") {
                                this.couponAlertMessage.innerHTML =
                                    error.response.data.message;
                            }
                        }
                    });
            } else {
                this.showCuponMessage("error", "Ya se aplicó un cupón.");
            }
        } else {
            this.showCuponMessage(
                "error",
                "Debes ingresar un código de cupón."
            );
        }
    }

    showCuponMessage(type, message) {
        if (type == "error") {
            this.couponAlertMessage.classList.remove("text-success");
            this.couponAlertMessage.classList.add("text-danger");
        } else {
            this.couponAlertMessage.classList.remove("text-danger");
            this.couponAlertMessage.classList.add("text-success");
        }

        this.couponAlertMessage.innerHTML = message;
    }

    getProductsPromise() {
        return new Promise((resolve, reject) => {
            let limit = this.decryptedCartProducts.length;
            let count = 0;
            for (let cartProduct of this.decryptedCartProducts) {
                if (cartProduct.partnum != undefined) {
                    axios
                        .get("/api/productos/" + cartProduct.slug + "/show")
                        .then(response => {
                            //console.log(response.data.data);
                            let product = response.data.data;

                            //if (product !=undefined) {
                            // Agregar al array de productos MPS
                            this.productsMps.push(product);

                            // Obtener template
                            this.productsHtml += this.getProductTemplate(
                                cartProduct,
                                product
                            );

                            //Si existe un producto que supere la disponibilidad, entonces
                            //rechazar y dejar de mostrar los demás productos en el carrito
                            if (
                                parseInt(cartProduct.quantity) >
                                parseInt(product.Quantity)
                            ) {
                                reject("Hay un producto no disponible");
                            }
                            //}

                            //Resolver si se completaron todos los productos
                            count += 1;
                            if (count == limit) {
                                resolve("se completo");
                            }
                        })
                        .catch(error => {
                            console.log(
                                "Ocurrio un error al consultar el producto: ->",
                                error.response
                            );
                            this.productsHtml += this.getProductNotFoundedTemplate(
                                cartProduct
                            );
                            reject("Hay un producto no disponible");
                        });
                } else {
                    this.title.innerHTML = "Ocurrio un error interno";
                    //window.localStorage.setItem("cartItems", '');
                    this.containerCartList.style.display = "none";
                }
            }
        });
    }

    getProductTemplate(productCart, product) {
        let unvailable = false;
        let price =
            product.Descuento > 0
                ? parseInt(product.precio - product.Descuento)
                : parseInt(product.precio);
        let subTotal = parseInt(productCart.quantity) * price;
        this.valorSubTotal += subTotal;

        //Calcular el iva de cada producto
        if (product.TributariClassification == "GRAVADO") {
            this.valorIva += subTotal * 0.19;
        }

        //Si existe un producto que supere la disponibilidad, entonces
        //rechazar y dejar de mostrar los demás productos en el carrito
        if (parseInt(productCart.quantity) > parseInt(product.Quantity)) {
            unvailable = true;
        }
        let template = "";
        if (unvailable) {
            template += `
            <tr class="cart_item">
                <td>${product.Name}
                    <br>
                    <span class="text-red">Sin disponibilidad</span>
                    <p>Elimina este producto en el carrito de compras para poder continuar con la compra</p>
                </td>
                <td class="text-center">${productCart.quantity}</td>
                <td class="text-dark">$${subTotal.toLocaleString("de-DE")}</td>
            </tr>
            `;
        } else {
            template = `
            <tr class="cart_item">
                <td>${product.Name} - ${product.TributariClassification}</td>
                <td class="text-center">${productCart.quantity}</td>
                <td class="text-dark">$${subTotal.toLocaleString("de-DE")}</td>
            </tr>
            `;
        }

        return template;
    }

    getProductNotFoundedTemplate(productCart) {
        let unvailable = true;
        let price = 0;
        let subTotal = parseInt(productCart.quantity) * price;
        this.valorSubTotal += subTotal;

        let template = "";
        if (unvailable) {
            template += `
            <tr class="cart_item">
                <td>${productCart.name}
                    <br>
                    <span class="text-red">Este producto ya no existe</span>
                    <p>Elimina este producto en el carrito de compras para poder continuar con la compra</p>
                </td>
                <td class="text-center">${productCart.quantity}</td>
                <td class="text-dark">$${subTotal.toLocaleString("de-DE")}</td>
            </tr>
            `;
        }

        return template;
    }
}

export default Checkout;
