import CartService from "../utils/cartService";
import CounterCart from "../common/counterCart";

class Cart
{
    constructor()
    {
        this.cartSection = document.querySelector('#cart-section');
        if (this.cartSection != null) {
            this.cartService = new CartService();
            this.containerCartList = document.querySelector('#container-cart-list');
            this.tableCartList = this.containerCartList.querySelector('table');
            this.cartList = this.containerCartList.querySelector('#cart-list');
            this.title = this.cartSection.querySelector("#cart-title");
            this.productsHtml = '';
            this.revision = '';
            this.inputsQuantity = '.input-quantity';
            this.buttonChangeQuantityProduct = '.js-plus,.js-plus small,.js-minus,.js-minus small';
            this.removeButton = '.remove-product';
            this.btnGoBuy = '#btn-seguir-comprando';
            this.totalPrice = 0;
            this.tableFoot = document.querySelector('#container-cart-list table tfoot');
            this.tableFootTotal = this.tableFoot.querySelector('span');
            this.whatsappButton = document.querySelector(".whatsapp");
            this.whatsappButton.hidden = true;

            this.bindEvents();
        }
    }
    bindEvents()
    {
        this.getCartItems();
        document.addEventListener('click', this.clickEvents.bind(this));
        document.addEventListener('keyup', this.changeEvents.bind(this));
    }

    clickEvents(e)
    {
        if (e.target.matches(this.buttonChangeQuantityProduct)) {
            this.increaseOrDecreaseQuantity(e);
        }

        if (e.target.matches(this.removeButton)) {
            this.removeProduct(e);
        }

        if (e.target.matches(this.btnGoBuy)) {
            if (window.history.length > 2) {
                history.go(-2);
            } else {
                document.location.href = '/';
            }
        }
    }

    changeEvents(e)
    {
        if (e.target.matches(this.inputsQuantity)) {
            this.onChangeQuantity(e);
        }
    }

    async getCartItems() {
        let cartProductsInLocalStorage = this.cartService.getCartLocalStorage();
        if (typeof cartProductsInLocalStorage != '' && cartProductsInLocalStorage != null && cartProductsInLocalStorage.length > 0) {
            let data = await this.cartService.decryptCart(cartProductsInLocalStorage);

            if (data.length > 0) {
                this.cartList.innerHTML = '';

                //Obtener los productos
                this.getProductsPromise(data)
                .then(result => {
                    //console.log('Muestra resolve: ' + result);
                    this.cartList.innerHTML = this.productsHtml;
                    this.containerCartList.classList.remove('u-slick');
                    this.tableFootTotal.innerHTML = '$' + this.totalPrice.toLocaleString("de-DE");
                    this.tableFoot.classList.remove('hideElement');
                    this.tableCartList.style.display = 'block';
                })
                .catch(error => {
                    //Si existe un producto que supere la disponibilidad, entonces
                    //dejar de mostrar los demás productos en el carrito
                    console.log('Muestra error: ' + error);
                    this.cartList.innerHTML = this.productsHtml;
                    this.containerCartList.classList.remove('u-slick');
                    //this.tableCartList.show();

                    this.tableCartList.style.display = 'block';
                });
            }
        } else {
            this.title.innerHTML = "Aun no tienes productos en el carrito";
            this.containerCartList.style.display = 'none';
        }

    }

    getProductsPromise(data) {
        return new Promise((resolve, reject) => {
            let limit = data.length;
            let count = 0;
            for (let [key, productCart] of data.entries()) {
                if (productCart.partnum != undefined) {
                    axios
                    .get('/api/productos/' + productCart.slug + '/show')
                    .then(response => {
                        //console.log(response.data.data);
                        let product = response.data.data;
                        //console.log('product ' , product);
                        //if (product !=undefined) {
                            this.productsHtml += this.getProductTemplate(key, productCart, product);

                            //Si existe un producto que supere la disponibilidad, entonces
                            //rechazar y dejar de mostrar los demás productos en el carrito
                            if (parseInt(productCart.quantity) > parseInt(product.Quantity)) {
                                reject('Hay un producto no disponible');
                            }
                        //}

                        //Resolver si se completaron todos los productos
                        count += 1;
                        if (count == limit) {
                            resolve('se completo');
                        }

                    })
                    .catch(error => {
                        console.log("Ocurrio un error al consultar el producto: ->", error.response);
                        this.productsHtml += this.getProductNotFoundedTemplate(key, productCart)
                        reject('Hay un producto no disponible');

                    });
                } else {
                    this.title.innerHTML = "Ocurrio un error interno";
                    this.cartService.setCartLocalStorage();
                    this.cartService.clearCart();
                    this.containerCartList.style.display = 'none';
                }
            }
        });
    }

    getProductTemplate(key, productCart, product)
    {
        let unvailable = false;
        let price = product.Descuento > 0 ? parseInt(product.precio - product.Descuento) : parseInt(product.precio);
        let subTotal = parseInt(productCart.quantity) * price;
        this.totalPrice += subTotal;
        //productCart.quantity

        //Si existe un producto que supere la disponibilidad, entonces
        //rechazar y dejar de mostrar los demás productos en el carrito
        if (parseInt(productCart.quantity) > parseInt(product.Quantity)) {
            unvailable = true;
        }

        let template = `
        <tr id="row-${ key }">
            <td class="text-center">
                <a href="#" class="text-gray-32 font-size-26 remove-product"
                data-partnum="${product.PartNum}" data-id="${ key }" data-refresh="${unvailable}">×</a>
            </td>
            <td class="d-none d-md-table-cell">
                <a href="#">
                    <img class="img-fluid max-width-100 p-1 border border-color-1"
                        src="${product.Imagenes[0]}" alt="Image Description">
                </a>
            </td>

            <td data-title="Product" class="w-44">
                <a href="/productos/${encodeURIComponent(
                    product.slug
                )}" class="text-gray-90">${product.Name}</a>
            </td>

            <td data-title="Price">
                <span class="">$${price.toLocaleString("de-DE")}</span>
            </td>

            <td data-title="Quantity">`

                if (unvailable) {
                    template += `
                    <span class="text-red">Sin disponibilidad</span>
                    <p>Elimina este producto para poder continuar con la compra</p>
                    `;
                } else {
                    template += `
                    <span class="sr-only">Cantidad</span>
                    <!-- Quantity -->
                    <div class="border rounded-pill py-1 width-134 w-xl-100 px-3 border-color-1">
                        <div class="js-quantity-cart row align-items-center">
                            <div class="col">

                                <input class="js-result form-control h-auto border-0 rounded p-0 shadow-none input-quantity"
                                    type="text" value="${productCart.quantity}"
                                    data-max="${product.Quantity}"
                                    data-partnum="${product.PartNum}"
                                    data-slug="${product.slug}"
                                    data-price="${price}"
                                    id="input-${ key }"
                                    disabled>

                            </div>
                            <div class="col-6 d-flex ">
                                <a class="js-minus btn btn-icon btn-xs btn-outline-secondary rounded-circle border-0"
                                    data-id="${ key }">
                                    <small class="fas fa-minus btn-icon__inner"></small>
                                </a>
                                <a class="js-plus btn btn-icon btn-xs btn-outline-secondary rounded-circle border-0"
                                    data-id="${ key }">
                                    <small class="fas fa-plus btn-icon__inner"></small>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- End Quantity -->`;
                }

            template += `
            </td>

            <td class="text-right" data-title="Total">
                <span class="" id="subtotal-${ key }" data-value="${subTotal}">$${subTotal.toLocaleString("de-DE")}</span>
            </td>
        </tr>`;

        return template;
    }

    getProductNotFoundedTemplate(key, productCart)
    {
        let unvailable = true;
        let price = 0;
        let subTotal = parseInt(productCart.quantity) * price;
        this.totalPrice += subTotal;
        //productCart.quantity



        let template = `
        <tr id="row-${ key }">
            <td class="text-center">
                <a href="#" class="text-gray-32 font-size-26 remove-product"
                data-partnum="${productCart.partnum}" data-id="${ key }" data-refresh="${unvailable}">×</a>
            </td>
            <td class="d-none d-md-table-cell">
                <a href="#">
                    <img class="img-fluid max-width-100 p-1 border border-color-1"
                        src="" alt="Image Description">
                </a>
            </td>

            <td data-title="Product" class="w-44">
                <a href="/productos/${encodeURIComponent(
                    productCart.slug
                )}" class="text-gray-90">${productCart.name}</a>
            </td>

            <td data-title="Price">
                <span class="">$${price.toLocaleString("de-DE")}</span>
            </td>

            <td data-title="Quantity">`

                if (unvailable) {
                    template += `
                    <span class="text-red">Este producto ya no existe</span>
                    <p>Elimina este producto para poder continuar con la compra</p>
                    `;
                }

            template += `
            </td>

            <td class="text-right" data-title="Total">
                <span class="" id="subtotal-${ key }" data-value="${subTotal}">$${subTotal.toLocaleString("de-DE")}</span>
            </td>
        </tr>`;

        return template;
    }

    onChangeQuantity(e)
    {
        let element = e.target;
        let maxQuantity = parseInt(element.dataset.max);

        //Si se ingresa un valor como letras poner un 1 en vez de un NaN
        element.value = parseInt(element.value) || 1;

        if (element.value < 1) {
            element.value = 1;
        }

        if (element.value > maxQuantity) {
            element.value = maxQuantity;
        }
    }

    increaseOrDecreaseQuantity(e)
    {
        e.preventDefault();

        //Si se hace click en el elemento small buscar y asignar el elemento padre
        let element = e.target;
        if (element.nodeName == 'SMALL') {
            element = element.parentElement;
        }

        //Recalcular precios
        if (element.classList.contains('js-plus')) {
            this.recalculatePrices('sum', element);
        } else {
            this.recalculatePrices('sustraction', element);
        }
    }

    recalculatePrices(operation, element)
    {
        let id = element.dataset.id;
        let input = document.querySelector('#input-' + id);
        let currentQuantity = parseInt(input.value);
        let maxQuantity = parseInt(input.dataset.max);
        let newQuantity = 0;
        let price = parseInt(input.dataset.price);
        let subtotalElement = document.querySelector('#subtotal-' + id);

        if (operation == 'sum') {
            if (currentQuantity < maxQuantity) {
                newQuantity = currentQuantity + 1
                this.totalPrice += price;
            }

        } else {
            if (currentQuantity > 1) {
                newQuantity = currentQuantity - 1
                this.totalPrice -= price;
            }
        }

        if (newQuantity > 0) {
            input.value = newQuantity;
            let newSubtotal = newQuantity * price;
            subtotalElement.innerHTML = '$' + newSubtotal.toLocaleString("de-DE");
            this.tableFootTotal.innerHTML = '$' + this.totalPrice.toLocaleString("de-DE");
        }

        this.updateQuantityLocalStorage(input);
    }

    async updateQuantityLocalStorage(input)
    {
        let cartProductsInLocalStorage = this.cartService.getCartLocalStorage();
        let data = await this.cartService.decryptCart(cartProductsInLocalStorage);
        //let data = JSON.parse(window.localStorage.getItem("smartbuy-cart-items"));
        let inputPartnum = input.dataset.partnum;
        let inputSlug = input.dataset.slug;

        data.forEach(product => {
            if (product.partnum == inputPartnum) {
                product.quantity = parseInt(input.value);
            }
        });
        //window.localStorage.setItem("smartbuy-cart-items", JSON.stringify(data));
        let cartObjectToSave = JSON.stringify(data);
        let response = await this.cartService.saveAndEncryptCart(cartObjectToSave);
    }

    async removeProduct(e)
    {
        e.preventDefault();
        let id = e.target.dataset.id;
        let partnum = e.target.dataset.partnum;
        let refresh = e.target.dataset.refresh;
        let row = document.querySelector('#row-' + id);
        let cartProductsInLocalStorage = this.cartService.getCartLocalStorage();
        let data = await this.cartService.decryptCart(cartProductsInLocalStorage);
        let index = data.findIndex(product => product.partnum == partnum);

        if (index > -1) {
            //Actualizar total
            let subtotalElement = document.querySelector('#subtotal-' + id);
            let subtotal = parseInt(subtotalElement.dataset.value);
            this.totalPrice -= subtotal;
            this.tableFootTotal.innerHTML = '$' + this.totalPrice.toLocaleString("de-DE");

            //Eliminar producto
            data.splice(index, 1);
            if (data.length == 0) {
                //window.localStorage.setItem("smartbuy-cart-items", '');
                this.cartService.clearCart();
                this.title.innerHTML = "Aun no tienes productos en el carrito";
                this.containerCartList.style.display = 'none';
            } else {
                let cartObjectToSave = JSON.stringify(data);
                let response = await this.cartService.saveAndEncryptCart(cartObjectToSave);
                //window.localStorage.setItem("smartbuy-cart-items", JSON.stringify(data));
            }

            //Eliminar fila
            //console.log(row);
            row.remove();

            if (refresh == 'true') {
                document.location.reload();
            }
        }
    }
}

export default Cart;
