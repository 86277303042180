class Register
{
    constructor()
    {
        this.register = document.querySelector('#register');

        if (this.register != null) {
            this.formRegister = '#register #form-register';
            this.buttonSubmit = this.register.querySelector('#button-submit');
            this.departamentoUsuario = '#register #form-register #departamento-usuario';
            this.ciudadUsuario = this.register.querySelector('#ciudad-usuario');
            this.departamentoDireccion = '#register #form-register .departamento-direccion';
            this.ciudadDireccion = this.register.querySelector('#ciudad-direccion');
           /* this.inputFileCarta = this.register.querySelector('input[name="carta"]');
            this.inputFileCertificado = this.register.querySelector('input[name="certificado"]');*/
            this.containerAddAddress = this.register.querySelector('#container-add-address');
            this.btnAddAddress = `#register #form-register #btn-ad-address,
            #register #form-register #btn-ad-address i`;
            this.addressCounter = 0;

            this.containerRegisterForm = this.register.querySelector('#container-register-form');
            this.containerRegisterThanks = this.register.querySelector('#container-register-thanks');
            this.alertMessage = this.register.querySelector('#alert-message');

            this.statesList = '';
            this.getAndSetStatesList();


            //console.log(this.statesList);
            this.bindEvents();
        }
    }

    bindEvents()
    {
        document.addEventListener('submit', this.submitEvents.bind(this));
        document.addEventListener('change', this.changeEvents.bind(this));
        document.addEventListener('click', this.clickEvents.bind(this));
    }

    submitEvents(e)
    {
        if (e.target.matches(this.formRegister)) {
            this.submitForm(e);
        }
    }

    changeEvents(e)
    {
        if (e.target.matches(this.departamentoUsuario + ', ' + this.departamentoDireccion)) {
            this.getCity(e);
        }
    }

    clickEvents(e)
    {
        if (e.target.matches(this.btnAddAddress)) {
            this.addAddress(e);
        }
    }

    submitForm(e)
    {
        e.preventDefault();
        let element = e.target;


        if (this.validateFields()) {
            //console.log('si pasa la validacion');
            const formData = new FormData(element)

            this.buttonSubmit.disabled = true;
            this.alertMessage.style.display = 'none';

            axios
            .post('/api/registro', formData)
            .then(response => {
                //console.log(response.data);
                if (response.data.status == 200) {
                    this.buttonSubmit.disabled = false;
                    this.containerRegisterForm.style.display = 'none';
                    this.containerRegisterThanks.style.display = 'block';
                }
            })
            .catch(error => {
                if (typeof error.response !== 'undefined') {
                    //console.log(error.response);
                    this.buttonSubmit.disabled = false;
                    this.alertMessage.innerHTML = error.response.data.message;
                    this.alertMessage.style.display = 'block';
                }
            });
        }
    }

    validateFields()
    {
        let allInputs  = this.getAllElementsWithAttribute('required');
        let formIsValid = true;

        for (let key in allInputs) {
            //console.log(allInputs[key]);
            let inputElement = allInputs[key];
            let name = inputElement.name;
            let nameIdErrorMessage = name.replace(/\[/g, '');
            nameIdErrorMessage = nameIdErrorMessage.replace(/\]/g, '');
            let id = inputElement.id
            let type = inputElement.type;
            let value = inputElement.value;
            let message = inputElement.dataset.msg;

            //console.log(type);
            switch (type) {
                case 'text':
                case 'select-one':
                    //console.log('Es text o select-one');
                    //console.log('El name es: ', name);
                    if (!value) {
                        formIsValid = false;
                        inputElement.parentElement.classList.add('u-has-error');
                        var errorMessage = `
                        <div id="error-message-${ nameIdErrorMessage }" class="invalid-feedback" style="display: block;">
                            ${ message }
                        </div>`;

                        if (this.register.querySelector('#error-message-' + nameIdErrorMessage) == null) {
                            inputElement.insertAdjacentHTML('afterend', errorMessage);
                        }

                    } else {
                        inputElement.parentElement.classList.remove('u-has-error');
                        if (this.register.querySelector('#error-message-' + nameIdErrorMessage) != null) {
                            this.register.querySelector('#error-message-' + nameIdErrorMessage).remove();
                        }
                    }
                    break;
                case 'checkbox':
                case 'radio':
                    //console.log(type);
                    //console.log('El tipo es checkbox o radio y su nombre es: ', name);
                    const checkboxes = document.querySelectorAll('input[name="' + name + '"]:checked');
                    if (!checkboxes.length) {
                        //console.log('El elemento ' + name + ' no esta seleccionado');
                        formIsValid = false;
                        let checkboxElement = this.register.querySelector('input[name="' + name + '"]');
                        let closestParent = checkboxElement.closest(".js-form-message")
                        //console.log('el closestParent es: ', closestParent);

                        var errorMessage = `
                        <div id="error-message-${ nameIdErrorMessage }" class="invalid-feedback" style="display: block;">
                            ${ message }
                        </div>`;

                        if (this.register.querySelector('#error-message-' + nameIdErrorMessage) == null) {
                            closestParent.insertAdjacentHTML('beforeend', errorMessage);
                        }
                    } else {
                        if (this.register.querySelector('#error-message-' + nameIdErrorMessage) != null) {
                            this.register.querySelector('#error-message-' + nameIdErrorMessage).remove();
                        }
                    }
                    break;
                case 'file':
                    //console.log(type);
                    //console.log('El tipo es file y su nombre es: ', name);
                    if (!value) {
                        formIsValid = false;
                        var errorMessage = `
                        <div id="error-message-${ nameIdErrorMessage }" class="invalid-feedback" style="display: block; margin-top: 40px;">
                            ${ message }
                        </div>`;

                        if (this.register.querySelector('#error-message-' + nameIdErrorMessage) == null) {
                            inputElement.insertAdjacentHTML('afterend', errorMessage);
                        }
                    } else {
                        let size = inputElement.files[0].size;
                        //console.log('El tamaño del archivo es: ', size);

                        if (size > 2097152) {
                            //Si el tamaño del archivo es mayor a 2MB
                            formIsValid = false;
                            var errorMessage = `
                            <div id="error-message-${ nameIdErrorMessage }" class="invalid-feedback" style="display: block; margin-top: 40px;">
                               El archivo debe pesar menos de 2MB
                            </div>`;

                            if (this.register.querySelector('#error-message-' + nameIdErrorMessage) == null) {
                                inputElement.insertAdjacentHTML('afterend', errorMessage);
                            } else {
                                this.register.querySelector('#error-message-' + nameIdErrorMessage).innerHTML =  errorMessage;
                            }
                        } else {
                            if (this.register.querySelector('#error-message-' + nameIdErrorMessage) != null) {
                                this.register.querySelector('#error-message-' + nameIdErrorMessage).remove();
                            }
                        }
                    }
                    break;
            }
        }
        return formIsValid;
    }

    getAllElementsWithAttribute(attribute)
    {
      var matchingElements = [];
      var allElements = this.register.getElementsByTagName('*');

      for (var i = 0, n = allElements.length; i < n; i++) {
        if (allElements[i].getAttribute(attribute) !== null) {
          // Element exists with attribute. Add to array.
          matchingElements.push(allElements[i]);
        }
      }
      return matchingElements;
    }

    getAndSetStatesList()
    {
        axios
        .get('/api/departamentos')
        .then(response => {
            //console.log(response.data);
            let states = response.data;
            let departamentos = '<option value="">Seleccionar departamento</option>';

            for (let state of states) {
                departamentos += `<option value="${ state.id_departamento }">${ state.nombre }</option>`;
            }
            this.statesList = departamentos;
        }).catch(error => {
            console.log(error);
        });
    }

    getCity(e)
    {
        let element = e.target;
        let departamentoId = element.value;

        axios
        .get('/api/departamentos/' + departamentoId + '/ciudades')
        .then(response => {

            let ciudades = '<option value="">Seleccionar ciudad</option>';
            for (let ciudad of response.data) {
                ciudades += `<option value="${ ciudad.id_ciudad }">${ ciudad.nombre }</option>`;
            }

            //Asignar las ciudades al elemento que invoco la accion
            if (element.id == 'departamento-usuario') {
                this.ciudadUsuario.innerHTML = ciudades;
            }
            else {
                let id = element.dataset.id;
                let ciudad = this.register.querySelector('#ciudad-direccion-' + id);
                ciudad.innerHTML = ciudades;
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    addAddress()
    {
        this.addressCounter++;
        let template = this.getAddressTemplate();
        this.containerAddAddress.insertAdjacentHTML('beforebegin', template);
    }

    getAddressTemplate()
    {
        return  `

        <div class="col-12 ">
            <div class="flex-content-center height-100 ">
                <div class="w-100 height-1 bg-1"></div>
            </div>
        </div>

        <div class="col-md-6">
            <!-- Input -->
            <div class="js-form-message mb-4">
                <label class="form-label">
                    Dirección
                    <span class="text-danger">*</span>
                </label>
                <input type="text" class="form-control"  name="direcciones[${ this.addressCounter }][direccion]" placeholder=""
                    aria-label="" required="" data-msg="Por favor ingresa la Dirección."
                    data-error-class="u-has-error" data-success-class="u-has-success"
                    autocomplete="off">
            </div>
            <!-- End Input -->
        </div>
        <div class="col-md-6">
            <!-- Input -->
            <div class="js-form-message mb-4">
                <label class="form-label">
                    Telefono
                    <span class="text-danger">*</span>
                </label>
                <input type="text" class="form-control" name="direcciones[${ this.addressCounter }][telefono_quien_recibe]" placeholder=""
                    aria-label="" required="" data-msg="Por favor ingresa un Telefono."
                    data-error-class="u-has-error" data-success-class="u-has-success"
                    autocomplete="off">
            </div>
            <!-- End Input -->
        </div>
        <div class="col-md-3">
            <!-- Input -->
            <div class="js-form-message mb-4">
                <label class="form-label">
                    Departamento
                    <span class="text-danger">*</span>
                </label>
                <select type="text" class="form-control departamento-direccion"
                    id="departamento-direccion-${ this.addressCounter }"
                    name="direcciones[${ this.addressCounter }][departamento_id]"
                    data-id="${ this.addressCounter }" placeholder="" aria-label=""
                    required="" data-msg="Por favor selecciona tu Departamento."
                    data-error-class="u-has-error"
                    data-success-class="u-has-success" autocomplete="off">
                    ${ this.statesList }
                </select>
            </div>
            <!-- End Input -->
        </div>
        <div class="col-md-3">
            <!-- Input -->
            <div class="js-form-message mb-4">
                <label class="form-label">
                    Ciudad
                    <span class="text-danger">*</span>
                </label>
                <select type="text" class="form-control"
                    id="ciudad-direccion-${ this.addressCounter }"
                    name="direcciones[${ this.addressCounter }][ciudad_id]" placeholder="" aria-label=""
                    required="" data-msg="Por favor selecciona tu Ciudad."
                    data-error-class="u-has-error"
                    data-success-class="u-has-success" autocomplete="off">
                </select>
            </div>
            <!-- End Input -->
        </div>
        <div class="col-md-6">
            <!-- Input -->
            <div class="js-form-message mb-4">
                <label class="form-label">
                    Nombre de quien recibe
                    <span class="text-danger">*</span>
                </label>
                <input type="text" class="form-control"
                name="direcciones[${ this.addressCounter }][nombre_quien_recibe]"
                placeholder=""
                aria-label="" required="" data-msg="Por favor ingresa un nombre."
                data-error-class="u-has-error" data-success-class="u-has-success"
                autocomplete="off">
            </div>
            <!-- End Input -->
        </div>
        `;
    }
}

export default Register;
