import ProductService from "../utils/productService";
import CartService from "../utils/cartService";

class FavoritesList
{
    constructor()
    {
        this.containerFavorites = document.querySelector('#container-favorites');



        if (this.containerFavorites != null) {
            this.productService = new ProductService();
            this.cartService = new CartService();
            this.formFavoritesList = this.containerFavorites.querySelector('#form-favorites-list');
            this.containerFavoritesList = this.containerFavorites.querySelector('#container-favorites-list');
            this.containerFavoritesListTable = this.containerFavoritesList.querySelector('table');
            this.list = this.containerFavorites.querySelector('#favorite-list');
            this.title = this.containerFavorites.querySelector('#wishlist-title');
            this.classRemoveProduct = '.remove-product';
            this.classAddToCart = '.btn-add-to-cart';
            this.whatsappButton = document.querySelector(".whatsapp");
            this.whatsappButton.hidden = true;

            this.bindEvents();
        }
    }

    bindEvents()
    {
        this.getFavorites();
        document.addEventListener('click', this.clickEvents.bind(this));
    }

    clickEvents(e)
    {
        if (e.target.matches(this.classRemoveProduct)) {
            this.removeProduct(e);
        }

        if (e.target.matches(this.classAddToCart)) {
            this.addToCart(e);
        }
    }

    getFavorites()
    {
        let favoritesInLocalStorage = this.cartService.getFavoritesLocalStorage();

        if (typeof favoritesInLocalStorage != ''
            && favoritesInLocalStorage != null
            && favoritesInLocalStorage.length > 0) {
            let data = JSON.parse(favoritesInLocalStorage);
            this.getProducts(data);
        } else {
            this.showMessageFavoritesListEmpty();
        }
    }

    showMessageFavoritesListEmpty()
    {
        this.containerFavoritesList.innerHTML = '';
        this.formFavoritesList.innerHTML = '<h4 class="text-center">Aún no tienes favoritos</h4>';
        this.formFavoritesList.classList.remove('u-slick');
    }

    async getProducts(data)
    {
        let template = '';
        let totalPrice = 0;
        for (let [index, slug] of data.entries()) {
            let product = null;

            try {
                product = await this.productService.findProduct(slug);
            }
            catch (error) {
                if (typeof error.response !== 'undefined') {
                    console.log('catch del error: ', error.response.data.message);
                }
            }

            if (product) {
                let price = product.Descuento > 0 ? parseInt(product.precio - product.Descuento) : parseInt(product.precio);
                template += `
                <tr>
                    <td class="text-center">
                        <a href="#" class="text-gray-32 font-size-26 remove-product"
                        data-slug="${ product.slug }">×</a>
                    </td>
                    <td class="d-none d-md-table-cell">
                        <a href="#">
                            <img class="img-fluid max-width-100 p-1 border border-color-1"
                                src="${ product['Imagenes'][0].replace(/\s/g, '%20') }" alt="Image Description">
                        </a>
                    </td>

                    <td data-title="Product" class="product-name">
                        <a href="#" class="text-gray-90">
                            ${ product.Name }
                        </a>
                    </td>



                    <td data-title="Stock Status" id="product-status-row-${ index }">
                        ${
                            product.Quantity > 0 ? '<span>En stock</span>' : '<span class="unavailable">Sin disponibilidad</span>'
                        }
                    </td>

                    <td>
                        <button type="button"
                            class="btn btn-soft-secondary mb-3 mb-md-0 font-weight-normal px-5 px-md-4 px-lg-5 w-100 w-md-auto btn-add-to-cart"
                            data-slug="${ product.slug }"
                            data-row="${ index }"
                            ${ product.Quantity == 0 ? 'disabled':'' }>
                            Agregar a carrito
                        </button>
                    </td>
                    <td data-title="Unit Price" class="text-right">
                        <span class="">
                            $${ price.toLocaleString("de-DE") }
                        </span>
                    </td>
                </tr>`;
                totalPrice += price;
            }
        }

        if (template) {
            this.list.innerHTML = template + `<tfoot class="">
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="text-right">
                                Total: <span>$${ totalPrice.toLocaleString("de-DE") }</span>
                            </td>
                        </tr>
                    </tfoot>`;
            this.formFavoritesList.classList.remove('u-slick');
            this.containerFavoritesList.style.display = 'block';
        } else {
            this.showMessageFavoritesListEmpty();
        }
    }

    async addToCart(e)
    {
        e.preventDefault();
        let element = e.target;
        let slugSelected = element.dataset.slug;
        let row = element.dataset.row;
        let quantitySelected = 1;

        element.disabled = true;
        element.innerHTML = 'Agregando producto ...';

        try {
            let productMps = await this.productService.findProduct(slugSelected);
            let price = productMps.Descuento > 0 ? parseInt(productMps.precio - productMps.Descuento) : parseInt(productMps.precio);

            //Comprobar la cantidad disponible respecto a la cantidad solicitada
            if (productMps.Quantity >= quantitySelected ) {

                let productSelected = {
                    partnum: productMps.PartNum,
                    slug: slugSelected,
                    name: productMps.Name,
                    marks: productMps.Marks,
                    quantity: quantitySelected,
                    price: price
                };

                let response = await this.cartService.addProductToCart(productSelected);

                //element.disabled = false;
                element.innerHTML = 'Producto agregado';
                element.classList.add('btn-added-to-cart');
            } else {
                let productStatus = document.querySelector('#product-status-row-' + row);
                element.disabled = true;
                productStatus.innerHTML = '<span class="unavailable">Sin disponibilidad</span>';
            }

            this.enableDisableButtonAddToCart(element, 'enable');
        } catch (error) {
            if (typeof error.response !== 'undefined') {
                console.log('catch del error: ', error.response.data.message);
                element.disabled = false;
                element.innerHTML = 'Agregar a carrito';
            }
        }
    }

    removeProduct(e)
    {
        e.preventDefault();

        let slug = e.target.dataset.slug;
        let data = JSON.parse(this.cartService.getFavoritesLocalStorage());
        let index = data.indexOf(slug);

        if (index > -1) {
            // Eliminar el producto del array de productos
            data.splice(index, 1);

            // Actualizar el local storage
            if (data.length) {
                this.cartService.setFavoritesLocalStorage(JSON.stringify(data));
            } else {
                this.cartService.clearFavoritesLocalStorage();
                this.containerFavoritesList.innerHTML = '';
                this.formFavoritesList.innerHTML = '<h4 class="text-center">Aún no tienes favoritos</h4>';
                this.formFavoritesList.classList.remove('u-slick');
            }

            // Eliminar fila
            e.target.closest('tr').remove();
        }
    }
}

export default FavoritesList;
