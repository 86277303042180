import CartService from "../utils/cartService";
import CounterFavorites from "../common/counterFavorites";

class Favorites
{
    constructor()
    {
        this.cartService = new CartService();
        this.productDetail = document.querySelector('#product-detail');
        if (this.productDetail != null) {
            this.btnAddToFavorites = this.productDetail.querySelector('#btn-add-to-favorites');
            this.favoriteAdded = false;
            this.bindEvents();
        }
    }

    bindEvents()
    {
        if (this.btnAddToFavorites) {
            this.btnAddToFavorites.addEventListener('click', this.addToWhishlist.bind(this));
        }
    }

    addToWhishlist()
    {
        event.preventDefault();
        let element = event.currentTarget;
        let slug = element.dataset.slug;
        let favoritesInLocalStorage = this.cartService.getFavoritesLocalStorage();

        if (slug != "") {

            if (!this.favoriteAdded) {
                console.log('entra por true');
                if (typeof favoritesInLocalStorage != ''
                && favoritesInLocalStorage != null
                && favoritesInLocalStorage.length > 0) {
                    let data = JSON.parse(favoritesInLocalStorage);
                    if (!data.includes(slug)) {
                        data.unshift(slug);
                        // Actualizar los favoritos del localstorage
                        this.cartService.setFavoritesLocalStorage(JSON.stringify(data));
                    }
                } else {
                    this.cartService.setFavoritesLocalStorage(JSON.stringify([slug]));
                }

                this.favoriteAdded = true;
                element.innerHTML = `<i class="fas fa-heart fa-heart-active"></i> Agregado a favoritos`;
            } else {
                if (typeof favoritesInLocalStorage != ''
                && favoritesInLocalStorage != null
                && favoritesInLocalStorage.length > 0) {
                    let data = JSON.parse(favoritesInLocalStorage);
                    console.log('entra por false');
                    if (data.includes(slug)) {
                        let position = data.indexOf(slug);
                        data.splice(position, 1);

                        // Actualizar los favoritos del localstorage
                        this.cartService.setFavoritesLocalStorage(JSON.stringify(data));

                    }
                    this.favoriteAdded = false;

                    element.innerHTML = `<i class="ec ec-favorites mr-1 font-size-15"></i> Agregar a favoritos`;
                }
            }

            // Actualizar el contador de favoritos
            new CounterFavorites();
        }
    }
}

export default Favorites;
