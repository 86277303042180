class AccountAddress
{
    constructor()
    {
        this.container = document.querySelector('#account-address');

        if (this.container != null) {
            this.buttonAdd = '#btn-add-address, #btn-add-address i';
            this.buttonSave = '#button-save';
            this.buttonEdit = '.btn-edit';
            this.buttonSaveEdit = '#button-save-edit';
            this.buttonDelete = '.btn-delete';
            this.buttonYesDelete = '.btn-Yes-Delete';
            this.buttonReturn = '#button-return';

            this.buttonSubmit = this.container.querySelector('#button-save');
            this.buttonSubmitEdit = this.container.querySelector('#button-save-edit');
            this.buttonSubmitDelete = this.container.querySelector('.btn-Yes-Delete');
            this.buttonReturnForm = this.container.querySelector('#button-return');

            this.containerAdress = this.container.querySelector('#section-address');
            this.containerFormAdd = this.container.querySelector('#section-add-address');

            this.departamentoDireccion = '#departamento-direccion';
            this.ciudadDireccion = this.container.querySelector('#ciudad-direccion');
            this.departamento = this.container.querySelector('#departamento-direccion');
            this.nombre = this.container.querySelector('#nombre');
            this.telefono = this.container.querySelector('#telefono');
            this.direccion = this.container.querySelector('#direccion');
            this.checkboxPrincipal = '#principal';
            this.alertMessage = this.container.querySelector('#alert-message');
            this.alertMessageDelete = this.container.querySelector('#alert-message-delete');
            this.successMessage = this.container.querySelector('#success-message');
            this.successMessageDelete = this.container.querySelector('#success-message-delete');

            this.containerAddress = document.querySelector('#contenedorDirecciones');

            this.accion = 0;
            this.id;
            this.idDelete;
            this.principal = 0;

            this.bindEvents();
        }
    }

    bindEvents()
    {
        this.containerFormAdd.classList.add('d-none');
        this.buttonSubmitEdit.classList.add('d-none');
        document.addEventListener('click', this.submitEvents.bind(this));
        document.addEventListener('change', this.changeEvents.bind(this));
    }

    submitEvents(e)
    {

        if (e.target.matches(this.buttonAdd)){
            this.changeStylePage(e);
        }

        if (e.target.matches(this.buttonSave)){
            this.accion = "crear";
            this.submitForm(e);
        }

        if (e.target.matches(this.buttonSaveEdit)){
            this.accion = "editar";
            this.submitForm(e);
        }

        if (e.target.matches(this.buttonReturn)){
            this.buttonReturnForm.disabled = true;
            document.location.reload();
        }

        if (e.target.matches(this.buttonEdit)){
            let idButton = e.target.dataset.id;
            this.id = idButton;
            this.buttonSubmit.classList.add('d-none');
            this.buttonSubmitEdit.classList.add('d-inline');
            this.accion = "Editar";
            this.editAddress(idButton);
        }

        if (e.target.matches(this.buttonDelete)){
            let idButtonDelete = e.target.dataset.id;
            this.idDelete = idButtonDelete;
        }

        if (e.target.matches(this.buttonYesDelete)){
            this.deleteAddress(this.idDelete);
        }
    }

    changeEvents(e)
    {
        if (e.target.matches(this.departamentoDireccion)) {
            this.getCity(e);
        }

        if(e.target.matches(this.checkboxPrincipal)){
            if(this.principal == 0){
                this.principal = 1;
            }else if( this.principal ==1){
                this.principal = 0;
            }
        }

    }

    async changeStylePage(e)
    {
        e.preventDefault();
        try {

            this.containerAdress.classList.add('d-none');
            this.containerFormAdd.classList.add('d-block');

        } catch (error) {
            if (typeof error.response !== 'undefined') {
                console.log("error", error.response.data);
            }
        }

    }

    editAddress(idButton)
    {

        this.containerAdress.classList.add('d-none');
        this.containerFormAdd.classList.add('d-block');

        axios
        .get('/api/usuarios/direcciones/'+ idButton +'/edit')
        .then(response => {
            this.direccion.value = response.data.direccion;
            this.telefono.value = response.data.telefono_quien_recibe;
            this.nombre.value = response.data.nombre_quien_recibe;
            this.departamento.value = response.data.departamento_id;
            this.getCityEdit(response.data.ciudad_id, response.data.nombre_ciudad, response.data.departamento_id);

            if(response.data.principal == 1){
                this.checkbox.checked = true;
                this.principal = 1;
            }

        })
        .catch(error => {
            if (typeof error.response !== 'undefined') {
                console.log("error response",error.response);
            }
            //console.log(error);
        });
    }

    deleteAddress(idButton)
    {
        this.buttonSubmitDelete.disabled = true;

        axios
        .delete('/api/usuarios/direcciones/'+idButton)
        .then(response => {
            //console.log("data",response.data);
            if (response.data.status == 200) {
                this.buttonSubmitDelete.disabled = false;
                this.alertMessageDelete.style.display = 'none';
                this.successMessageDelete.style.display = 'block';
                document.location.reload();
                //this.getAllDirections();
            }
        })
        .catch(error => {
            //console.log("error",error);
            if (typeof error.response !== 'undefined') {
                console.log("error response",error.response);
                this.buttonSubmitDelete.disabled = false;
                this.successMessageDelete.style.display = 'none';
                this.alertMessageDelete.style.display = 'block';
            }
        });
    }

    submitForm(e)
    {
        e.preventDefault();
        if (this.validateFields()) {

            let direccionForm = this.direccion.value;
            let departamento = this.departamento.value;
            let ciudad = this.ciudadDireccion.value;
            let direccionPrincipal = this.principal;
            let telefono =  this.telefono.value;
            let nombre = this.nombre.value;

            let idAddress = this.id;
            let direccionCompleta = direccionForm;
            this.buttonSubmit.disabled = true;
            this.buttonSubmitEdit.disabled = true;
            this.alertMessage.style.display = 'none';
            this.successMessage.style.display = 'none';
            this.alertMessageDelete.style.display = 'none';
            this.successMessageDelete.style.display = 'none';

            let form = {
                direccion: direccionCompleta,
                departamento_id: departamento,
                ciudad_id: ciudad,
                principal:  direccionPrincipal,
                telefono_quien_recibe: telefono,
                nombre_quien_recibe: nombre
            };

            if(this.accion == "editar"){

                axios
                .put('/api/usuarios/direcciones/'+idAddress, form)
                .then(response => {
                    //console.log("data",response.data);
                    if (response.data.status == 200) {
                        document.location.reload();
                        //this.getAllDirections();
                    }
                })
                .catch(error => {
                    //console.log("error",error);
                    if (typeof error.response !== 'undefined') {
                        console.log("error response",error.response);
                        this.buttonSubmit.disabled = false;
                        this.buttonSubmitEdit.disabled = false;
                        this.alertMessage.style.display = 'block';
                    }
                });

            }else{

                axios
                .post('/api/usuarios/direcciones', form )
                .then(response => {
                    if (response.data.status == 200) {
                        this.buttonSubmit.disabled = true;
                        this.buttonSubmitEdit.disabled = true;
                        document.location.reload();
                        //this.getAllDirections();
                    }
                })
                .catch(error => {
                    //console.log("error",error);
                    if (typeof error.response !== 'undefined') {
                        console.log("error response",error.response);
                        this.buttonSubmit.disabled = false;
                        this.buttonSubmitEdit.disabled = false;
                        this.alertMessage.style.display = 'block';
                    }
                });

            }

        }
    }

    validateFields()
    {
        let allInputs  = this.getAllElementsWithAttribute('required');
        let formIsValid = true;

        for (let key in allInputs) {
            let inputElement = allInputs[key];
            let name = inputElement.name;
            let nameIdErrorMessage = name.replace(/\[/g, '');
            nameIdErrorMessage = nameIdErrorMessage.replace(/\]/g, '');
            let id = inputElement.id
            let type = inputElement.type;
            let value = inputElement.value;
            let message = inputElement.dataset.msg;

            switch (type) {
                case 'text':
                case 'select-one':
                    //console.log('Es text o select-one');
                    //console.log('El name es: ', name);
                    if (!value) {
                        formIsValid = false;
                        inputElement.parentElement.classList.add('u-has-error');
                        var errorMessage = `
                        <div id="error-message-${ nameIdErrorMessage }" class="invalid-feedback" style="display: block;">
                            ${ message }
                        </div>`;

                        if (this.container.querySelector('#error-message-' + nameIdErrorMessage) == null) {
                            inputElement.insertAdjacentHTML('afterend', errorMessage);
                        }

                    } else {
                        inputElement.parentElement.classList.remove('u-has-error');
                        if (this.container.querySelector('#error-message-' + nameIdErrorMessage) != null) {
                            this.container.querySelector('#error-message-' + nameIdErrorMessage).remove();
                        }
                    }
                    break;
                case 'checkbox':
                case 'radio':
                    //console.log(type);
                    //console.log('El tipo es checkbox o radio y su nombre es: ', name);
                    const checkboxes = document.querySelectorAll('input[name="' + name + '"]:checked');
                    if (!checkboxes.length) {
                        //console.log('El elemento ' + name + ' no esta seleccionado');
                        formIsValid = false;
                        let checkboxElement = this.container.querySelector('input[name="' + name + '"]');
                        let closestParent = checkboxElement.closest(".js-form-message")
                        //console.log('el closestParent es: ', closestParent);

                        var errorMessage = `
                        <div id="error-message-${ nameIdErrorMessage }" class="invalid-feedback" style="display: block;">
                            ${ message }
                        </div>`;

                        if (this.container.querySelector('#error-message-' + nameIdErrorMessage) == null) {
                            closestParent.insertAdjacentHTML('beforeend', errorMessage);
                        }
                    } else {
                        if (this.container.querySelector('#error-message-' + nameIdErrorMessage) != null) {
                            this.container.querySelector('#error-message-' + nameIdErrorMessage).remove();
                        }
                    }
                    break;
            }
        }
        return formIsValid;
    }

    getAllElementsWithAttribute(attribute)
    {
      var matchingElements = [];
      var allElements = this.container.getElementsByTagName('*');;
      for (var i = 0, n = allElements.length; i < n; i++) {
        if (allElements[i].getAttribute(attribute) !== null) {
          // Element exists with attribute. Add to array.
          matchingElements.push(allElements[i]);
        }
      }
      return matchingElements;
    }

    getCity(e)
    {
        let element = e.target;
        let departamentoId = element.value;

        axios
        .get('/api/departamentos/' + departamentoId + '/ciudades')
        .then(response => {
            let ciudades = '<option value="">Seleccionar ciudad</option>';
            for (let ciudad of response.data) {
                ciudades += `<option value="${ ciudad.id_ciudad }">${ ciudad.nombre }</option>`;
            }

            //Asignar las ciudades al elemento que invoco la accion
            if (element.id == 'departamento-direccion') {
                this.ciudadDireccion.innerHTML = ciudades;
            }
            else {
                let id = element.dataset.id;
                let ciudad = this.register.querySelector('#ciudad-direccion-' + id);
                ciudad.innerHTML = ciudades;
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    getCityEdit(id, name, departamentoId)
    {

        axios
        .get('/api/departamentos/' + departamentoId + '/ciudades')
        .then(response => {
            let ciudades = `<option value="${ id }">${ name }</option>`;
            for (let ciudad of response.data) {
                ciudades += `<option value="${ ciudad.id_ciudad }">${ ciudad.nombre }</option>`;
            }

            //Asignar las ciudades al elemento que invoco la accion
            this.ciudadDireccion.innerHTML = ciudades;
        })
        .catch(error => {
            console.log(error);
        });
    }
}

export default AccountAddress;
