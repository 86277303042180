class thanks
{
    constructor()
    {
        this.paymentsThanks = document.querySelector('#payments-thanks');
        if (this.paymentsThanks != null) {
            this.btnPrinter = '#printPayments';
            this.img = document.querySelector('#imgPurchase');
            this.title = document.querySelector('#titlePurchase');
            this.subtitle = document.querySelector('#subtitlePurchase');
            this.paragraph = document.querySelector('#textPurchase');
            this.whatsappButton = document.querySelector(".whatsapp");
            this.whatsappButton.hidden = true;

            this.getIdOrder();
            this.bindEvents();
        }
    }

    bindEvents()
    {
        document.addEventListener('click', this.clickEvents.bind(this));
    }

    clickEvents(e)
    {
        if (e.target.matches(this.btnPrinter)) {
            this.printBlade(e);
        }
    }

    getIdOrder()
    {
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        let idOrder = urlParams.get('orderId');

        this.getPaymentStatus(idOrder);
    }

    reloadPage()
    {
        window.location.reload();
    }

    getPaymentStatus(e)
    {
        let orderId = e;
        axios
        .get('/api/pagos/' + orderId + '/show')
        .then(response => {
            //console.log(response.data.data);

            if(response.data.data == null){
                //console.log("null order");
                this.img.src = "/mps/img/common/clock.svg";
                this.title.innerHTML = "Pago en Confirmación";
                this.subtitle.innerHTML = `El proceso de pago está siendo validado, este proceso puede tardar entre 1 a 5 minutos. Espera mientras lo confirmamos.`;

                setInterval(this.reloadPage,300000);

            } else {
                let orderNumber = response.data.data.order_number;
                let orderIdMps = response.data.data.order_id_mps;
                let status = response.data.data.order_status;
                this.printContent(status, orderNumber, orderIdMps);
            }

            this.paymentsThanks.style.display = "block";
        }).catch(error => {
            console.log(error);
        });
    }

    printContent(status, order, orderIdMps)
    {
        if(status == 2){
            this.img.src = "/mps/img/common/checked.svg";
            this.title.innerHTML = "Tu pedido esta confirmado";
            this.subtitle.innerHTML = `Tu compra con número de orden <strong>${ order }</strong> en credibanco y orden <strong>${ orderIdMps }</strong> en mps ha sido confirmada. Gracias por seguir confiando en MPS.`;
            this.paragraph.innerHTML = 'Un correo de confirmación llegará a tu bandeja de entrada con toda la información de tu pedido.';

        } else if(status == 0){
            this.img.src = "/mps/img/common/exclamation-mark.svg";
            this.title.innerHTML = "Pedido registrado pero no pagado";
            this.subtitle.innerHTML = `Tu compra con número de orden <strong>${ order }</strong> no ha podido completarse.`;
            this.paragraph.innerHTML = 'Nuestro sistema está validando el proceso de compra y esto puede tomar un par de horas. En cuanto tengamos la aprobación o respuesta del sistema, la pasarela de compras enviará un mensaje de confirmación con el estado del pedido. Para mayor información puedes escribirnos al correo:  atencioncliente@mps.com';

        } else if(status == 3 || status == 6){
            this.img.src = "/mps/img/common/error-mark.svg";
            this.title.innerHTML = "Autorización denegada";
            this.subtitle.innerHTML = `Tu compra con número de orden <strong>${ order }</strong> no ha podido completarse.`;
            this.paragraph.innerHTML = 'Nuestro sistema ha validado la información de tu compra y ha rechazado el pago. Revisa atentamente los datos de confirmación y tu pedido. Si crees que se debe a un error, puedes escribirnos al correo:  atencioncliente@mps.com';

        } else if(status == 4){
            this.img.src = "/mps/img/common/exclamation-mark.svg";
            this.title.innerHTML = "Se ha realizado la reverso de esta transacción";
            this.subtitle.innerHTML = `Tu compra con número de orden <strong>${ order }</strong> no ha podido completarse debido a que alguno de los productos solicitados no se encontraba disponible.`;
            this.paragraph.innerHTML = '';

        } else if(status == 5){
            this.img.src = "/mps/img/common/exclamation-mark.svg";
            this.title.innerHTML = "Autorización iniciada a través de ACS del banco emisor";
            this.subtitle.innerHTML = `Tu compra con número de orden <strong>${ order }</strong> no ha podido completarse.`;
            this.paragraph.innerHTML = 'Nuestro sistema está validando el proceso de compra y esto puede tomar un par de horas. En cuanto tengamos la aprobación o respuesta del sistema, la pasarela de compras enviará un mensaje de confirmación con el estado del pedido. Para mayor información puedes escribirnos al correo:  atencioncliente@mps.com';

        } else if(status == 7){
            this.img.src = "/mps/img/common/exclamation-mark.svg";
            this.title.innerHTML = "Pedido Pendiente";
            this.subtitle.innerHTML = `Tu compra con número de orden <strong>${ order }</strong> no ha podido completarse.`;
            this.paragraph.innerHTML = 'Nuestro sistema está validando el proceso de compra y esto puede tomar un par de horas. En cuanto tengamos la aprobación o respuesta del sistema, la pasarela de compras enviará un mensaje de confirmación con el estado del pedido. Para mayor información puedes escribirnos al correo:  atencioncliente@mps.com';
        }
    }

    printBlade()
    {
        return window.print();
    }
}

export default thanks;
