class Especifications
{
    constructor()
    {
        this.productDetail = document.querySelector('#product-detail');
        if (this.btnSeeMore != null) {
            this.btnSeeMore = this.productDetail.querySelector('#btn-SeeMore');
            if (this.btnSeeMore != null) {
                this.bindEvents();
            }
        }
    }

    bindEvents()
    {
        this.btnSeeMore.addEventListener('click', this.openSpecifications.bind(this));
    }

    openSpecifications(e)
    {
        let elemento = e.target;

        if(elemento.classList.contains('colapsado') == true){

            elemento.classList.replace("colapsado", "noColapsado");
        } else if(elemento.classList.contains('colapsado') == false){

            elemento.classList.replace("noColapsado", "colapsado");
        }
    }
}

export default Especifications;
