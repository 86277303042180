import ProductsFooterTemplate from './productsFooterTemplate';
import ProductService from "../../utils/productService";

class ProductsFooter
{
    constructor()
    {
        this.containerTabsProductsFooter = document.querySelector('#container-tabs-products-footer');

        if (this.containerTabsProductsFooter != null) {
            this.template = new ProductsFooterTemplate();
            this.productService = new ProductService();
            this.lastReleases = this.containerTabsProductsFooter.querySelector('#last-releases');
            this.ProductsDisscounts = this.containerTabsProductsFooter.querySelector('#disscounts');
            this.ProductsMostBought = this.containerTabsProductsFooter.querySelector('#mostBought');

            this.bindEvents();
        }
    }

    bindEvents()
    {
        this.getProducts();
    }

    async getProducts()
    {
        try {
            let sectionNewProducts = '';
            let sectionProductsDiscounts = '';
            let sectionProductsMostBoughts = '';

            //Obtener productos
            let releases = await this.getProductsPromise('releases');
            let disscounts = await this.getProductsPromise('disscounts');
            let mostBoughts = await this.getProductsPromise('mostBoughts');

            //Obtener el template de los productos
            sectionNewProducts += this.template.get(releases);
            sectionProductsDiscounts += this.template.get(disscounts);
            sectionProductsMostBoughts += this.template.get(mostBoughts);

            //Insertar templates
            this.lastReleases.innerHTML = sectionNewProducts;
            this.ProductsDisscounts.innerHTML = sectionProductsDiscounts;
            this.ProductsMostBought.innerHTML = sectionProductsMostBoughts;

        } catch (error) {
            if (typeof error.response !== 'undefined') {
                console.log(error.response.data);
            }
        }
    }

    getProductsPromise(type)
    {
        let products = '';

        switch (type) {
            case 'releases':
                products = this.productService.getNewProducts();
                break;
            case 'disscounts':
                products = this.productService.getDiscountProducts();
                break;
            case 'mostBoughts':
                products = this.productService.getmostBoughtsProducts();
                break;
        }

        return products;
    }
}

export default ProductsFooter;
