import CartService from "../utils/cartService";
import ProductService from "../utils/productService";

class CounterCart
{
    constructor()
    {
        this.counter = document.querySelector('#cart-items-counter');
        this.counterMobile = document.querySelector('#cart-items-counter-movil');
        this.globalPrice = document.querySelector('#cart-global-price');

        if (this.counter != null ||  this.counterMobile != null) {
            this.cartService = new CartService();
            this.productService = new ProductService();
            this.bindEvents();
        }
    }

    bindEvents()
    {
        this.getItems();
    }

    async getItems()
    {
        let cartProductsInLocalStorage = this.cartService.getCartLocalStorage();
        try {
            if (typeof cartProductsInLocalStorage != '' && cartProductsInLocalStorage != null && cartProductsInLocalStorage.length > 0) {
                let data = await this.cartService.decryptCart(cartProductsInLocalStorage);
                let totalPrice = 0;

                if (data && data.length) {
                    let length = data.length;
                    this.counter.innerHTML = length;
                    this.counterMobile.innerHTML = length;

                    // Obtener precio global
                    for (let product of data) {
                        let productMps = await this.productService.findProduct(product.slug);
                        totalPrice += (productMps.precio * product.quantity);
                    }

                    this.globalPrice.innerHTML = '$' + totalPrice.toLocaleString("de-DE");
                } else {
                    this.counter.innerHTML = 0;
                    this.counterMobile.innerHTML = 0;
                    this.globalPrice.innerHTML = '$' + totalPrice.toLocaleString("de-DE");
                }
            }
        } catch (error) {
            if (typeof error.response !== 'undefined') {
                console.log('Catch del error: ', error.response.data);
            }
        }
    }
}
export default CounterCart;
