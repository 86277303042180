class ProductsFooterTemplate
{
    constructor()
    {
       //
    }

    get(products)
    {
        let template = '';
        let counter = 0;

        for (let product of products) {
            if (counter < 3 && product) {
                template += `
                <li class="product-item product-item__list row no-gutters mb-6 remove-divider">
                    <div class="col-auto">
                        <a href="/productos/${ encodeURIComponent(product.slug) }"
                        class="d-block width-75 text-center">
                            <img class="img-fluid" src="${ product.Imagenes[0] }"
                            alt="Image Description">
                        </a>
                    </div>
                    <div class="col pl-4 d-flex flex-column">
                        <h5 class="product-item__title mb-0">
                            <a href="/productos/${ encodeURIComponent(product.slug)}"
                            class="text-blue font-weight-bold">
                                ${ product.Name.substring(0,34) }
                            </a>
                        </h5>`;

                        if (product.Descuento != undefined && product.Descuento > 0) {
                            template += `
                            <div class="prodcut-price mt-auto flex-horizontal-center">
                                <ins class="font-size-15 text-decoration-none">
                                    $ ${ (product.precio - product.Descuento).toLocaleString("de-DE") }
                                </ins>
                                <del class="font-size-12 text-gray-9 ml-2">
                                    $ ${ product.precio.toLocaleString("de-DE") }
                                </del>
                            </div>
                            <div>
                                <span class="text-secondary font-size-12">
                                    Precio antes de IVA
                                </span>
                                <div class="text-${ product.Quantity > 0 ? 'green' : 'red' } font-size-12">
                                    ${ product.Quantity > 0 ? 'Producto disponible' : 'No disponible' }
                                </div>
                            </div>
                            `;

                        } else {
                            template += `
                            <div class="prodcut-price mt-3">
                                <div class="font-size-15">
                                    $ ${ product.precio.toLocaleString("de-DE") } <br/>
                                    <span class="text-secondary font-size-12">
                                        Precio antes de IVA
                                    </span>
                                    <div class="text-${ product.Quantity > 0 ? 'green' : 'red' } font-size-12">
                                        ${ product.Quantity > 0 ? 'Producto disponible' : 'No disponible' }
                                    </div>
                                </div>
                            </div>
                            `;
                        }

                template +=  `

                    </div>
                </li>`
            }
            counter++;
        }
        return template;
    }
}

export default ProductsFooterTemplate;
