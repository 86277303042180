class ProductService
{
    constructor()
    {
        //
    }

    findProduct(slug)
    {
        return axios
        .get('/api/productos/' + slug + '/show')
        .then(function(response) {
           return response.data.data;
        });
    }

    getNewProducts()
    {
        return axios
        .get('/api/productos/nuevos')
        .then(response => {
            return response.data.data;
        });
    }

    getDiscountProducts()
    {
        return axios
        .get('/api/productos/descuento')
        .then(response => {
            return response.data.data;
        });
    }

    getmostBoughtsProducts()
    {
        return axios
        .get('/api/productos/mas-comprados')
        .then(response => {
            return response.data.data;
        });
    }
}

export default ProductService;
