class MicroshopRotator {
    constructor() {
        this.micrositeRotatorContainer = document.getElementById(
            "microsite-rotator"
        );
        if (this.micrositeRotatorContainer) {
            this.micrositeRotator = document.getElementById(
                "microsite-rotator-0"
            );
            this.bindEvents();
        }
    }

    bindEvents() {
        this.getProducts();
    }

    getProducts() {
        axios
            .post("/api/productos/filtro", {
                filtroMarcas: this.micrositeRotatorContainer.dataset.marca,
                pagina: 1
            })
            .then(response => {
                const {
                    data: { listaproductos: products }
                } = response;
                if (products.length) {
                    this.micrositeRotator.insertAdjacentHTML(
                        "beforeend",
                        this.getTemplateRotator(products)
                    );
                    $.HSCore.components.HSSlickCarousel.init(
                        ".js-slick-carousel-products-0"
                    );
                }
            });
    }

    getUpperCaseFirstChart(title) {
        return title.charAt(0).toUpperCase() + title.slice(1);
    }

    getTemplateRotator(products) {
        let template = `<div class="container">
            <!-- Deals of The Day -->
            <div class="mb-3">
                <div class="d-flex border-bottom border-color-1 flex-lg-nowrap flex-wrap border-md-down-top-0 border-sm-bottom-0 mb-2 mb-md-0">
                    <h3 class="section-title section-title__full mb-0 pb-2 font-size-22">Productos destacados</h3>
                </div>
                <div class="js-slick-carousel-products js-slick-carousel-products-0 u-slick overflow-hidden u-slick-overflow-visble pt-3 pb-6 px-1"
                    data-pagi-classes="text-center right-0 bottom-1 left-0 u-slick__pagination u-slick__pagination--long mb-0 z-index-n1 mt-4"
                    data-slides-show="5"
                    data-slides-scroll="1"
                    data-responsive='[{
                      "breakpoint": 1400,
                      "settings": {
                        "slidesToShow": 5
                      }
                    }, {
                        "breakpoint": 1200,
                        "settings": {
                          "slidesToShow": 4
                        }
                    }, {
                      "breakpoint": 992,
                      "settings": {
                        "slidesToShow": 4
                      }
                    }, {
                      "breakpoint": 768,
                      "settings": {
                        "slidesToShow": 3
                      }
                    }, {
                      "breakpoint": 554,
                      "settings": {
                        "slidesToShow": 2
                      }
                    }]'>
                    ${this.getTemplateProduct(products)}
                </div>
            </div>
            <!-- End Deals of The Day -->
        </div>
        <!-- End Rotador Categorias -->`;

        return template;
    }

    getTemplateProduct(products) {
        let template = "";

        for (let product of products) {
            template += `
            <div class="js-slide products-group">
                <div class="product-item">
                    <div class="product-item__outer h-100">
                        <div class="product-item__inner px-wd-4 p-2 p-md-3">
                            <div class="product-item__body pb-xl-2">
                                <div class="mb-2">
                                    <a href="/productos/${encodeURIComponent(
                                        product.slug
                                    )}" class="font-size-12 text-gray-5">
                                        ${product.Categoria}
                                    </a>
                                </div>
                                <h5 class="mb-1 product-item__title">
                                    <a href="/productos/${encodeURIComponent(
                                        product.slug
                                    )}" class="text-blue font-weight-bold">
                                        ${product.Name.substring(0, 34)}
                                    </a>
                                </h5>
                                <div class="mb-2">
                                    <a href="/productos/${encodeURIComponent(
                                        product.slug
                                    )}" class="d-block text-center">
                                        <img class="img-fluid" src="${
                                            product.Imagenes[0]
                                        }" alt="Image Description">
                                    </a>
                                </div>`;

            if (product.Descuento != undefined && product.Descuento > 0) {
                template += `
                                    <div class="flex-center-between flex-column align-items-stretch mb-1">
                                        <div class="prodcut-price d-flex align-items-center position-relative">
                                            <ins class="font-size-20 text-red text-decoration-none">
                                                $ ${(
                                                    product.precio -
                                                    product.Descuento
                                                ).toLocaleString("de-DE")}
                                            </ins>
                                            <del class="font-size-12 tex-gray-6 position-absolute bottom-100">
                                               $ ${product.precio.toLocaleString(
                                                   "de-DE"
                                               )}
                                            </del>
                                        </div>
                                        <span class="text-secondary font-size-12">
                                            Precio antes de IVA
                                        </span>
                                        <div class="text-${
                                            product.Quantity > 0
                                                ? "green"
                                                : "red"
                                        } font-size-12">
                                            ${
                                                product.Quantity > 0
                                                    ? "Producto disponible"
                                                    : "No disponible"
                                            }
                                        </div>
                                    </div>
                                    `;
            } else {
                template += `
                                    <div class="mb-1">
                                        <div class="prodcut-price">
                                            <div class="text-gray-100">
                                                $ ${product.precio.toLocaleString(
                                                    "de-DE"
                                                )}
                                            </div>
                                        </div>
                                        <span class="text-secondary font-size-12">
                                            Precio antes de IVA
                                        </span>
                                        <div class="text-${
                                            product.Quantity > 0
                                                ? "green"
                                                : "red"
                                        } font-size-12">
                                            ${
                                                product.Quantity > 0
                                                    ? "Producto disponible"
                                                    : "No disponible"
                                            }
                                        </div>
                                       <!-- <div class="d-none d-xl-block prodcut-add-cart">
                                            <a href="#" class="btn-add-cart btn-primary transition-3d-hover"><i class="ec ec-add-to-cart"></i></a>
                                        </div> -->
                                    </div>
                                    `;
            }

            template += `
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            `;
        }

        return template;
    }
}

export default MicroshopRotator;
