class UsersForm
{
    constructor()
    {
        this.accountUsersForm = document.querySelector('#account-users-form');
        if (this.accountUsersForm != null) {
            this.buttonSave = this.accountUsersForm.querySelector('#button-save');
            this.recordToDelete = null;
            this.bindEvents();
        }
    }

    bindEvents()
    {
        document.addEventListener('click', this.clickEvents.bind(this));
    }

    clickEvents(e)
    {
        if(e.target.matches('#' + this.buttonSave.id)){
            this.save(e);
        }
    }

    save(e)
    {
        e.preventDefault();
        let element = e.target;

        if (this.validateFields()) {
            element.disabled = true;
            this.accountUsersForm.submit();
        }

    }

    validateFields()
    {
        let allInputs  = this.getAllElementsWithAttribute('required');
        let formIsValid = true;

        for (let key in allInputs) {
            //console.log(allInputs[key]);
            let inputElement = allInputs[key];
            let name = inputElement.name;
            let nameIdErrorMessage = name.replace(/\[/g, '');
            nameIdErrorMessage = nameIdErrorMessage.replace(/\]/g, '');
            let type = inputElement.type;
            let value = inputElement.value;
            let message = inputElement.dataset.msg;

            switch (type) {
                case 'text':
                case 'select-one':
                    if (!value) {
                        formIsValid = false;
                        inputElement.parentElement.classList.add('u-has-error');
                        var errorMessage = `
                        <div id="error-message-${ nameIdErrorMessage }" class="invalid-feedback" style="display: block;">
                            ${ message }
                        </div>`;

                        if (this.accountUsersForm.querySelector('#error-message-' + nameIdErrorMessage) == null) {
                            inputElement.insertAdjacentHTML('afterend', errorMessage);
                        }

                    } else {
                        inputElement.parentElement.classList.remove('u-has-error');
                        if (this.accountUsersForm.querySelector('#error-message-' + nameIdErrorMessage) != null) {
                            this.accountUsersForm.querySelector('#error-message-' + nameIdErrorMessage).remove();
                        }
                    }
                    break;

            }
        }
        return formIsValid;
    }

    getAllElementsWithAttribute(attribute)
    {
      var matchingElements = [];
      var allElements = this.accountUsersForm.getElementsByTagName('*');

      for (var i = 0, n = allElements.length; i < n; i++) {
        if (allElements[i].getAttribute(attribute) !== null) {
          // Element exists with attribute. Add to array.
          matchingElements.push(allElements[i]);
        }
      }
      return matchingElements;
    }
}

export default UsersForm;
