import CartService from "../utils/cartService";

class CounterFavorites
{
    constructor()
    {
        this.counter = document.querySelector('#favorites-items-counter');
        //this.counterMobile = document.querySelector('#cart-items-counter-movil');

        if (this.counter != null) {
            this.cartService = new CartService();
            this.bindEvents();
        }
    }

    bindEvents()
    {
        this.getItems();
    }

    async getItems()
    {
        let favoritesProductsInLocalStorage = this.cartService.getFavoritesLocalStorage();

        try {
            if (typeof favoritesProductsInLocalStorage != '' && favoritesProductsInLocalStorage != null && favoritesProductsInLocalStorage.length > 0) {
                let data = JSON.parse(favoritesProductsInLocalStorage);
                let length = data.length;
                this.counter.innerHTML = length;
            } else {
                this.counter.innerHTML = 0;
            }
        } catch (error) {
            if (typeof error.response !== 'undefined') {
                console.log('Catch del error: ', error.response.data);
            }
        }
    }
}
export default CounterFavorites;
