/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

//require('../bootstrap');

window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

//Electro
import electroComponents from "./electro/components.js";
import electroMegaMenu from "./electro/mega-menu.js";

//Common
//import BurgerMenu from "./burgerMenu";
import MegaMenu from "./common/megaMenu";
import CounterCart from "./common/counterCart";
import CounterFavorites from "./common/counterFavorites";
import SearchProductForm from "./common/searchProductForm";
import ProductsFooter from "./common/productsFooter/productsFooter";
import Trm from "./common/Trm";
import Newsletter from "./common/newsletter";

//PreHome
//import BrandUnits from "./prehome/brandUnits";

//Home
//import ThreeTabs from "./home/threeTabs";
//import SeenTab from "./home/seenTab";
//import DestCategory from "./home/destCategory";
//import CategoryRotator from "./home/categoryRotator";
import ProductsRotator from "./home/ProductsRotator";

//Products
//import ProductsListOld from "./products/productsList";
import ProductsList from "./products/list/list";
import ProductsDetailAccesories from "./products/detail/accesories";
import ProductsDetailCart from "./products/detail/productsCart";
import ProductsDetailEspecifications from "./products/detail/especifications";
import Comments from "./products/comments";
import AddProductSeen from "./products/addProductSeen";
import Favorites from "./products/favorites";
//import ProductSeen from "./singleProduct/productSeen";

//Favorites
import FavoritesList from "./favorites/favoritesList";

//Cart
import Cart from "./cart/cart";

//Checkout
import Checkout from "./checkout/checkout";
import Address from "./checkout/address";

//Payment
import PaymentThanks from "./payments/thanks";

//Categories
//import Redirection from "./categories/redirection";
import GetProducts from "./categories/getproducts";
import Pagination from "./categories/pagination";

//Blog
import GetAllBlogs from "./blog/getAllBlogs";

//Account
import AccountAddress from "./account/AccountAddress";
import AccountUsers from "./account/Users";
import AccountUsersForm from "./account/UsersForm";
import PhoneOrders from "./account/PhoneOrders";
import PersonalInformation from "./account/PersonalInformation";

//Contacto
import Contacto from "./contacto/contacto";
import ContactoModal from "./contacto/contacto-modal";

//Register
import Register from "./register/register";
import ResetPassword from "./password/resetPassword";

//Microshop
import MicroshopRotator from "./micrositio/featured-products";
import MicroshopContactForm from "./micrositio/contacto.js";

$(document).on("ready", function() {
    //Electro
    new electroComponents();
    new electroMegaMenu();

    //Common
    new MegaMenu();
    new CounterCart();
    new CounterFavorites();
    new SearchProductForm();
    new ProductsFooter();
    new Trm();
    new Newsletter();

    //Home
    new ProductsRotator();

    //Products
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        )
    ) {
        console.log("mobile device");
        new ProductsList("mobile");
    } else {
        console.log("not mobile device");
        new ProductsList("web");
    }

    new ProductsDetailAccesories();
    new ProductsDetailCart();
    new ProductsDetailEspecifications();
    new Comments();
    new AddProductSeen();
    new Favorites();

    //Favorites List
    new FavoritesList();

    //Cart
    new Cart();

    //Checkout
    new Checkout();
    new Address();

    //Payment
    new PaymentThanks();

    //Categories
    new Pagination();

    //Blog
    new GetAllBlogs();

    //Account
    new AccountAddress();
    new AccountUsers();
    new AccountUsersForm();
    new PhoneOrders();
    new PersonalInformation();

    //Contacto
    new Contacto();
    new ContactoModal();

    //Register
    new Register();
    new ResetPassword();

    //microshop
    new MicroshopRotator();
    new MicroshopContactForm();

    //NO USADOS
    //Home
    //new BrandUnits();
    //new ThreeTabs()
    //let burgerMenu = new BurgerMenu();
    //new SeenTab();
    //new DestCategory();
    //new CategoryRotator();

    //Products
    //new ProductsListOld();
    //new ProductSeen();

    //Categories
    //new Redirection();
    //new GetProducts();
});

