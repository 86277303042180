import CounterCart from "../common/counterCart";

class CartService
{
    decryptCart(cartProductsInLocalStorage)
    {
       return axios
        .post('/api/carrito/get', {
            object: cartProductsInLocalStorage
        })
        .then(response => {
            return response.data;
        });
    }

    encryptCart(cartObject)
    {
        return axios
        .post('/api/carrito/store', {
            object: cartObject
        })
        .then(response => {
            return response.data;
        });
    }

    async addProductToCart(productSelected)
    {
        let cartProductsInLocalStorage = window.localStorage.getItem("cartItems");
        let cartObjectToSave = null;

        if (typeof cartProductsInLocalStorage != ''
            && cartProductsInLocalStorage != null
            && cartProductsInLocalStorage.length > 0) {
            //El carro contiene productos entonces seguir agregando productos
            let data = await this.decryptCart(cartProductsInLocalStorage);

            console.log('----- Inicio addProductToCart ------');
            console.log('El carro esta lleno');
            console.log('El carrito desencriptado es: ', data);
            console.log('----- Final addProductToCart ------');

            //Determinar si el producto existe
            let productFound = data.find(product => product.partnum == productSelected.partnum);
            if (productFound) {
                //console.log('Encontro el producto');
                //Si el producto existe entonces modificar la cantidad en el carrito
                let newQuantity = parseInt(productFound.quantity) + parseInt(productSelected.quantity);
                data.find(product => product.partnum == productSelected.partnum).quantity = newQuantity;
                //console.log('newQuantity ' , newQuantity);
            } else {
                //Si el producto no existe entonces agregarlo al carrito
                data.unshift(productSelected);
            }

            cartObjectToSave = JSON.stringify(data);
        } else {
            //El carro esta vacio entones agregar el primer producto
            console.log('El carro esta vacio');
            cartObjectToSave = JSON.stringify([productSelected]);
        }

        //Guardar y encriptar carrito
        let response = await this.saveAndEncryptCart(cartObjectToSave);
        //console.log('return es: ', response);

        return response;
    }

    async saveAndEncryptCart(cartObject)
    {
        /*console.log('----- Inicio saveAndEncryptCart ------');
        console.log('se va a encriptar y a guardar este objeto: ', cartObject);
        console.log('----- Inicio saveAndEncryptCart ------');*/
        let objectEncrypted = await this.encryptCart(cartObject);
        window.localStorage.setItem("cartItems", objectEncrypted);

        //Actualizar contador de productos en el carrito y mostrar mensaje
        let counterCart = new CounterCart();
        return 'se termino de guardar y encriptar el objeto';
    }

/*    saveAndEncryptCart(cartObject)
    {
        console.log('----- Inicio saveAndEncryptCart ------');
        console.log('se va a encriptar y a guardar este objeto: ', cartObject);
        console.log('----- Inicio saveAndEncryptCart ------');
        return axios
        .post('/api/carrito/store', {
            object: cartObject
        })
        .then(response => {
            let objectEncrypted = response.data;
            //console.log('El retorno encripatdo es: ', objectEncrypted);
            window.localStorage.setItem("cartItems", objectEncrypted);

            //Actualizar contador de productos en el carrito y mostrar mensaje
            let counterCart = new CounterCart();

            return objectEncrypted;
        })
        .catch(error => {
            console.log(error);
        });
    }*/

    getCartLocalStorage()
    {
        return window.localStorage.getItem("cartItems")
    }

    /*setCartLocalStorage()
    {
        window.localStorage.setItem("smartbuy-cart-items", '');
    }*/

    clearCart()
    {
        window.localStorage.setItem("cartItems", '');
    }

    getFavoritesLocalStorage()
    {
        return window.localStorage.getItem("favoriteProducts")
    }

    setFavoritesLocalStorage(data)
    {
        window.localStorage.setItem("favoriteProducts", data);
    }

    clearFavoritesLocalStorage()
    {
        window.localStorage.setItem("favoriteProducts", '');
    }
}
export default CartService;
