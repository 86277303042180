import ProductService from "../../utils/productService";
import CartService from "../../utils/cartService";

class ProductsCart
{
    constructor()
    {
        this.productDetail = document.querySelector('#product-detail');
        if (this.productDetail != null) {
            this.productService = new ProductService();
            this.cartService = new CartService();
            this.productPartNum = this.productDetail.querySelector('#product-partnum');
            this.productSlug = this.productDetail.querySelector('#product-slug');

            this.productQuantitySelector = this.productDetail.querySelector('#product-quantity-selector');
            this.buttonChangeQuantityProduct = this.productDetail.querySelectorAll('.js-minus, .js-plus');
            this.productQuantity = this.productDetail.querySelector('#product-quantity');
            this.btnAddToCart = this.productDetail.querySelector('#btn-add-to-cart');
            this.btnBuyNow = this.productDetail.querySelector('#btn-buy-now');
            this.alertAddedToCart = $('.alert-added-to-cart');
            this.alertMessage = this.productDetail.querySelector('.alertMessage');

            this.bindEvents();
        }
    }

    bindEvents()
    {
        this.buttonChangeQuantityProduct.forEach(element => {
            element.addEventListener('click', this.increaseOrDecreaseQuantity.bind(this));
        });

        if (this.productQuantity) {
            this.productQuantity.addEventListener('keyup', this.onChangeQuantity.bind(this));
        }
        if (this.btnAddToCart) {
            this.btnAddToCart.addEventListener('click', this.actionButtonAddToCart.bind(this));
        }

        if (this.btnBuyNow) {
            this.btnBuyNow.addEventListener('click', this.actionButtonBuyNow.bind(this));
        }
    }

    increaseOrDecreaseQuantity(e)
    {
        e.preventDefault();
        let element = e.currentTarget;

        //Recalcular precios
        if (element.classList.contains('js-plus')) {
            this.recalculatePrices('sum', element);
        } else {
            this.recalculatePrices('sustraction', element);
        }
    }

    recalculatePrices(operation, element)
    {
        let currentQuantity = parseInt(this.productQuantity.value);
        let maxQuantity = parseInt(this.productQuantity.dataset.max);
        let newQuantity = 0;

        if (operation == 'sum') {
            if (currentQuantity < maxQuantity) {
                newQuantity = currentQuantity + 1
            }
        } else {
            if (currentQuantity > 1) {
                newQuantity = currentQuantity - 1
            }
        }

        if (newQuantity > 0) {
            this.productQuantity.value = newQuantity;
        }
    }

    onChangeQuantity(e)
    {
        let element = e.target;
        //Si se ingresa un valor como letras poner un 1 en vez de un NaN
        element.value = parseInt(element.value) || 1;

        if (element.value < 1) {
            element.value = 1;
        }
    }

    async actionButtonAddToCart(e)
    {
        e.preventDefault();
        let element = e.currentTarget;
        let partnumSelected = element.dataset.partum;
        let slugSelected = element.dataset.slug;
        let quantitySelected = parseInt(this.productQuantity.value);

        this.hideAlertMessage();
        this.enableDisableButtonAddToCart(element, 'disable');
        try {
            let productMps = await this.productService.findProduct(slugSelected);

            //Comprobar la cantidad disponible respecto a la cantidad solicitada
            if (quantitySelected <= productMps.Quantity ) {
                let price = productMps.Descuento > 0 ? parseInt(productMps.precio - productMps.Descuento) : parseInt(productMps.precio);
                let productSelected = {
                    partnum: partnumSelected,
                    slug: slugSelected,
                    name: productMps.Name,
                    marks: productMps.Marks,
                    quantity: quantitySelected,
                    price: price
                };

                let response = await this.cartService.addProductToCart(productSelected);
                //console.log('respuesta en agregar carrito es: ', response);
                this.alertAddedToCart.show().delay(3000).fadeOut();

            } else {
                this.showAlertMessage('La cantidad seleccionada no esta disponible.');
            }

            this.enableDisableButtonAddToCart(element, 'enable');
        } catch (error) {
            if (typeof error.response !== 'undefined') {
                this.showAlertMessage(error.response.data.message);
                this.enableDisableButtonAddToCart(element, 'enable');
            }
        }
    }

    async actionButtonBuyNow(e)
    {
        e.preventDefault();

        let element = e.target;
        let partnumSelected = element.dataset.partum;
        let slugSelected = element.dataset.slug;
        let quantitySelected = parseInt(this.productQuantity.value);

        this.hideAlertMessage();
        this.enableDisableButtonBuyNow(element, 'disable');
        try {
            let productMps = await this.productService.findProduct(slugSelected);

            //Comprobar la cantidad disponible respecto a la cantidad solicitada
            if (quantitySelected <= productMps.Quantity ) {
                let price = productMps.Descuento > 0 ? parseInt(productMps.precio - productMps.Descuento) : parseInt(productMps.precio);
                let productSelected = {
                    partnum: partnumSelected,
                    slug: slugSelected,
                    name: productMps.Name,
                    marks: productMps.Marks,
                    quantity: quantitySelected,
                    price: price
                };

                let response = await this.cartService.addProductToCart(productSelected);
                //console.log('respuesta en comprar ahora es: ', response);
                document.location.href = '/checkout';
            } else {
                this.showAlertMessage('La cantidad seleccionada no esta disponible.');
                this.enableDisableButtonBuyNow(element, 'enable');
            }
        } catch (error) {
            if (typeof error.response !== 'undefined') {
                this.showAlertMessage(error.response.data.message);
                this.enableDisableButtonBuyNow(element, 'enable');
            }
        }
    }

    enableDisableButtonAddToCart(element, action)
    {
        if (action == 'enable') {
            element.disabled = false;
            let message = element.dataset.enablemessage;
            element.querySelector('span').innerHTML = message;
        } else {
            element.disabled = true;
            let message = element.dataset.disablemessage;
            element.querySelector('span').innerHTML = message;
        }
    }

    enableDisableButtonBuyNow(element, action)
    {
        if (action == 'enable') {
            element.disabled = false;
            let message = element.dataset.enablemessage;
            element.innerHTML = message;
        } else {
            element.disabled = true;
            let message = element.dataset.disablemessage;
            element.innerHTML = message;
        }
    }

    showAlertMessage(error)
    {
        console.log('Catch del error: ', error);
        this.alertMessage.innerHTML = error;
        this.alertMessage.style.display = 'block';
    }

    hideAlertMessage()
    {
        this.alertMessage.style.display = 'none';
    }
}

export default ProductsCart;
