class ContactModalForm
{
    constructor()
    {
        this.modal = $("#ModalContacto");

        if (this.modal.length) {
            this.form = this.modal.find("#contact-modal-form");
            this.thanksContainer = this.modal.find("#contact-modal-thanks");
            this.modalTitle = this.modal.find(".modal-title");
            console.log("this.thanksContainer " , this.thanksContainer);
            this.formInputs = this.form.find("input , textarea");
            this.bindEvents();
        }
    }

    bindEvents()
    {
        this.form.on("submit", this.validate.bind(this));
        this.formInputs.each((i, element) => {
            let input = $(element);

            input.on("input", this.hideAlerts.bind(this));
        });

        this.modal.on('hidden.bs.modal', this.onHiddenModal.bind(this))
    }

    validate()
    {
        let sendEmail = true;
        event.preventDefault();

        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.formInputs.each((i, element) => {
            let input = $(element);
            if (!input.val()) {
                sendEmail = false;
                this.showAlert(input);
            } else if (
                input.attr("name") === "correo" &&
                !reg.test(input.val())
            ) {
                sendEmail = false;
                this.showAlert(input);
            }
        });

        if (sendEmail) {
            let values = {};
            this.formInputs.each((i, input) => {
                values = {
                    ...values,
                    [input.name]: input.value
                };
            });

            this.form.find("button").prop('disabled', true);
            let $form = this.form;
            let $thanksContainer = this.thanksContainer;
            let $modalTitle = this.modalTitle;
            axios
            .post("/api/contacto", values)
            .then(function(response) {
                $form.find("button").prop('disabled', false);

                $form.addClass("d-none");
                $thanksContainer.removeClass("d-none");
                $modalTitle.addClass("d-none");
            })
            .catch(function(error) {
                alert(
                    "No podemos enviar su mensaje en este momento, intente de nuevo mas tarde."
                );
                $form.find("button").prop('disabled', false);
            });
        }
    }

    showAlert(input)
    {
        let container = input.parent();
        if (container.find("small").length) {
            return null;
        }
        input.addClass("input--alert");
        let name = input.attr("name");
        let alertText;
        switch (name) {
            case "nombre":
                alertText = "Por favor, complete su nombre.";
                break;
            case "apellido":
                alertText = "Por favor, complete su apellido.";
                break;
            case "telefono":
                alertText = "Por favor, complete su telefono.";
                break;
            case "correo":
                alertText =
                    "Por favor ingrese una dirección de correo electrónico válida.";
                break;
            case "comentarios":
                alertText = "Por favor, complete el mensaje.";
                break;

            default:
                alertText = "Por favor, debe llenar el campo.";
                break;
        }
        container.append(`<small class='small--alert'>${alertText}</small>`);
    }

    hideAlerts()
    {
        let input = $(event.target);

        if (input.val()) {
            let container = input.parent();
            input.removeClass("input--alert");
            let text = container.find("small");
            text.remove();
        } else {
            this.showAlert(input);
        }
    }

    radioValues()
    {
        let input = $(event.target);
        input.val("true");
        this.hideAlerts();
    }

    onHiddenModal() {
        this.form.removeClass("d-none");
        this.thanksContainer.addClass("d-none");
        this.modalTitle.removeClass("d-none");
    }
}
export default ContactModalForm;
