class ResetPassword
{
    constructor()
    {
        this.resetPassword = document.querySelector('#form-reset-password');

        if (this.resetPassword != null){
            this.password = this.resetPassword.querySelector('#password');
            this.passwordAgain = this.resetPassword.querySelector('#passwordConfirmation');
            this.buttonSubmit = this.resetPassword.querySelector('#button-save-password');
            this.button ='#button-save-password';
            this.bindEvents();
        }

    }

    bindEvents()
    {
        document.addEventListener('click', this.submitEvents.bind(this));
    }

    submitEvents(e)
    {
        if (e.target.matches(this.button)) {
            this.submitForm(e);
        }
    }

    submitForm(e)
    {
        e.preventDefault();

        if (this.validateFields()) {

            let pass = this.password.value;
            let passAgain = this.passwordAgain.value;
            //console.log("formulario", pass, passAgain );
            if( pass == passAgain){

                if(pass.length >= 8){

                    this.buttonSubmit.disabled = true;
                    let validacion = this.validarClave(pass);

                    //Ocultar textos
                    if(!document.getElementById('noSamePass').classList.contains('d-none')){
                        document.getElementById('noSamePass').classList.add('d-none');
                    }
                    if(!document.getElementById('incompletePass').classList.contains('d-none')){
                        document.getElementById('incompletePass').classList.add('d-none');
                    }
                    if(!document.getElementById('shortPass').classList.contains('d-none')){
                        document.getElementById('shortPass').classList.add('d-none');
                    }

                    //Validacion
                    if(validacion == true){
                        //this.buttonSubmit.disabled = false;
                        this.resetPassword.submit();

                    }else{
                        this.buttonSubmit.disabled = false;
                        document.getElementById('incompletePass').classList.remove('d-none');
                    }


                }else{
                    //Ocultar textos
                    if(!document.getElementById('noSamePass').classList.contains('d-none')){
                        document.getElementById('noSamePass').classList.add('d-none');
                    }

                    document.getElementById('shortPass').classList.remove('d-none');
                }

            }else{

                document.getElementById('noSamePass').classList.remove('d-none');
            }

        }
    }

    validarClave(pass)
    {

        var mayuscula = false;
        var minuscula = false;
        var numero = false;
        var caracter_raro = false;

        for(var i = 0;i<pass.length;i++)
        {
            if(pass.charCodeAt(i) >= 65 && pass.charCodeAt(i) <= 90)
            {
                mayuscula = true;
            }
            else if(pass.charCodeAt(i) >= 97 && pass.charCodeAt(i) <= 122)
            {
                minuscula = true;
            }
            else if(pass.charCodeAt(i) >= 48 && pass.charCodeAt(i) <= 57)
            {
                numero = true;
            }
            else
            {
                caracter_raro = true;
            }
        }
        if(mayuscula == true && minuscula == true && caracter_raro == true && numero == true)
        {
            return true;
        }

        return false;
    }

    validateFields()
    {
        let allInputs  = this.getAllElementsWithAttribute('required');
        let formIsValid = true;

        for (let key in allInputs) {
            //console.log(allInputs[key]);
            let inputElement = allInputs[key];
            let name = inputElement.name;
            let nameIdErrorMessage = name.replace(/\[/g, '');
            nameIdErrorMessage = nameIdErrorMessage.replace(/\]/g, '');
            let type = inputElement.type;
            let value = inputElement.value;
            let message = inputElement.dataset.msg;

            switch (type) {
                case 'text':
                case 'select-one':
                    if (!value) {
                        formIsValid = false;
                        inputElement.parentElement.classList.add('u-has-error');
                        var errorMessage = `
                        <div id="error-message-${ nameIdErrorMessage }" class="invalid-feedback" style="display: block;">
                            ${ message }
                        </div>`;

                        if (this.resetPassword.querySelector('#error-message-' + nameIdErrorMessage) == null) {
                            inputElement.insertAdjacentHTML('afterend', errorMessage);
                        }

                    } else {
                        inputElement.parentElement.classList.remove('u-has-error');
                        if (this.resetPassword.querySelector('#error-message-' + nameIdErrorMessage) != null) {
                            this.resetPassword.querySelector('#error-message-' + nameIdErrorMessage).remove();
                        }
                    }
                    break;

            }
        }
        return formIsValid;
    }

    getAllElementsWithAttribute(attribute)
    {
        var matchingElements = [];
        var allElements = this.resetPassword.getElementsByTagName('*');

        for (var i = 0, n = allElements.length; i < n; i++) {
            if (allElements[i].getAttribute(attribute) !== null) {
                // Element exists with attribute. Add to array.
                matchingElements.push(allElements[i]);
            }
        }
        return matchingElements;
    }
}

export default ResetPassword;
