class CouponService
{
    constructor()
    {
        //
    }

    calculateCouponDiscount(coupon, decryptedCartProducts, productsMps)
    {
        let subTotal = 0;

        // Recorrer los productos del carrito junto con los productos de Mps
        for (let cartProduct of decryptedCartProducts) {
            if (cartProduct.partnum != undefined) {
                productsMps.forEach(productMps => {
                    if (productMps.PartNum == cartProduct.partnum) {
                        // Calcular el subtotal de acuerdo al tipo de aplicación
                        // del cupón
                        subTotal += this.calculateSubtotalAccordingToApplication(productMps, cartProduct, coupon);
                    }
                });
            }
        }

        console.log('El subtotal calculado fue: ', subTotal.toLocaleString("de-DE"));
        let calculatedDiscount = this.calculateDiscountAccordingToCouponType(coupon, subTotal);
        //console.log('El calculatedDiscount fue: ', calculatedDiscount);

        if (calculatedDiscount.discount > 0) {
            return {
                'couponShouldBeApplied': true,
                'discount': Math.round(calculatedDiscount.discount),
                'code': coupon.codigo,
                'id': coupon.id
            };
        }

        return {
            'couponShouldBeApplied': false,
            'discount':Math.round(calculatedDiscount.discount),
            'alertMessage': calculatedDiscount.alertMessage
        };
    }

    calculateSubtotalAccordingToApplication(productMps, cartProduct, coupon)
    {
        let subTotal = 0;
        switch(coupon.cupon_aplicacion_id) {
            // Cupon aplicado a un producto
            case 1:
                // Si el partnum del producto es igual al
                // partnum del cupón entonces realizar calculo del subtotal
                if (productMps.PartNum == coupon.aplicado_a) {
                    subTotal = parseInt(cartProduct.quantity) * productMps.precio;
                }
                break;
            // Cupon aplicado a una marca
            case 2:
                // Si la marca homologada del producto es igual a la marca homologada
                // del cupón entonces realizar calculo del subtotal
                if (productMps.MarcaHomologada == coupon.aplicado_a) {
                    subTotal = parseInt(cartProduct.quantity) * productMps.precio;
                }
                break;
            // Cupon aplicado a una categoria
            case 3:
                // Si la categoria del producto es igual a la
                // categoria del cupón entonces realizar calculo del subtotal
                if (productMps.IdFamilia == coupon.aplicado_a) {
                    subTotal = parseInt(cartProduct.quantity) * productMps.precio;
                }
                break;
        }
        return subTotal;
    }

    calculateDiscountAccordingToCouponType(coupon, subTotal)
    {
        let discount = 0;
        let alertMessage = null;

        // Si el subTotal calculado mayor o igual al monto mínimo del cupón
        // entonces calcular el descuento
        if (subTotal >= coupon.monto_minimo) {
            let couponType = coupon.cupon_tipo_id;

            switch (couponType) {
                case 1:
                    // Porcentual
                    discount = (subTotal * coupon.valor) / 100;
                    subTotal -= discount;
                    break;
                case 2:
                    // Valor Fijo
                    discount = coupon.valor
                    subTotal -= discount;
                    break;
                case 3:
                    // Máximo de ventas
                    discount = coupon.valor
                    subTotal -= discount;
                    break;
            }
        } else {
            alertMessage = 'El mónto mínimo para aplicar este cúpón debe ser de $'
            + coupon.monto_minimo.toLocaleString("de-DE");
        }

        return {'discount': discount, 'alertMessage': alertMessage};
    }
}
export default CouponService;
