class components {
    constructor() {
        this.init();
    }

    init() {
        // initialization of header
        $.HSCore.components.HSHeader.init($("#header"));

        // initialization of animation
        $.HSCore.components.HSOnScrollAnimation.init("[data-animation]");

        // initialization of unfold component
        $.HSCore.components.HSUnfold.init($("[data-unfold-target]"), {
            afterOpen: function() {
                $(this)
                    .find('input[type="search"]')
                    .focus();
            }
        });

        // initialization of popups
        $.HSCore.components.HSFancyBox.init(".js-fancybox");

        // initialization of countdowns
        var countdowns = $.HSCore.components.HSCountdown.init(".js-countdown", {
            yearsElSelector: ".js-cd-years",
            monthsElSelector: ".js-cd-months",
            daysElSelector: ".js-cd-days",
            hoursElSelector: ".js-cd-hours",
            minutesElSelector: ".js-cd-minutes",
            secondsElSelector: ".js-cd-seconds"
        });

        // initialization of malihu scrollbar
        $.HSCore.components.HSMalihuScrollBar.init($(".js-scrollbar"));

        // initialization of forms
        $.HSCore.components.HSFocusState.init();

        // initialization of form validation
/*        $.HSCore.components.HSValidation.init(".js-validate", {
            rules: {
                confirmPassword: {
                    equalTo: "#signupPassword"
                }
            }
        });
*/


        // initialization of forms
        $.HSCore.components.HSRangeSlider.init(".js-range-slider");
        // initialization of show animations
        $.HSCore.components.HSShowAnimation.init(".js-animation-link");

        // initialization of fancybox
        $.HSCore.components.HSFancyBox.init(".js-fancybox");

        // initialization of slick carousel
        $.HSCore.components.HSSlickCarousel.init(".js-slick-carousel");

        // initialization of go to
        $.HSCore.components.HSGoTo.init(".js-go-to");

        // initialization of hamburgers
        $.HSCore.components.HSHamburgers.init("#hamburgerTrigger");

        // initialization of unfold component
        $.HSCore.components.HSUnfold.init($("[data-unfold-target]"), {
            beforeClose: function() {
                $("#hamburgerTrigger").removeClass("is-active");
            },
            afterClose: function() {
                $("#headerSidebarList .collapse.show").collapse("hide");
            }
        });

        $('#headerSidebarList [data-toggle="collapse"]').on("click", function(
            e
        ) {
            e.preventDefault();

            var target = $(this).data("target");

            if ($(this).attr("aria-expanded") === "true") {
                $(target).collapse("hide");
            } else {
                $(target).collapse("show");
            }
        });

        // initialization of unfold component
        $.HSCore.components.HSUnfold.init($("[data-unfold-target]"));

        // initialization of select picker
        $.HSCore.components.HSSelectPicker.init(".js-select");
    }
}

export default components;
