import ProductService from "../../utils/productService";
import CartService from "../../utils/cartService";
import CounterCart from "../../common/counterCart";

class Accesories
{
    constructor()
    {
        this.productDetail = document.querySelector('#product-detail');
        if (this.productDetail != null) {
            this.productService = new ProductService();
            this.cartService = new CartService();
            this.productPartNum = this.productDetail.querySelector('#product-partnum');
            this.productSlug = this.productDetail.querySelector('#product-slug');

            this.seccionAccesoriosTab = this.productDetail.querySelector('#seccion-accesorios-tab');
            this.seccionAccesorios = this.productDetail.querySelector('#seccion-accesorios');
            this.seccionAccesoriosUl = this.productDetail.querySelector('#seccion-accesorios ul');
            this.seccionAccesoriosContainerCheckboxes = this.productDetail.querySelector('#seccion-accesorios #container-checkboxes');
            this.checkboxAccesory = '#product-detail .form-check-input';
            this.arrayAccesories = [];
            this.containerPriceAndCounterAccesories = this.productDetail.querySelector('#container-price-and-counter-accesories');
            this.countItemAccesories = this.productDetail.querySelector('#count-items-accesories span');
            this.totalPriceAccesories = this.productDetail.querySelector('#total-price-accesories');
            this.btnAddAccesories = '#product-detail #btn-add-accesories';

            this.bindEvents();
        }
    }

    bindEvents()
    {
        this.getAccesories();
        document.addEventListener('click', this.clickEvents.bind(this));
    }

    clickEvents(e)
    {
        if (e.target.matches(this.checkboxAccesory)) {
           this.changeCheckboxAccesories(e);
        }

        if (e.target.matches(this.btnAddAccesories)) {
           this.addAccesories(e);
        }
    }

    getAccesories()
    {
        axios.post('/api/productos/accesorios', {
            partnum: this.productPartNum.value
        })
        .then(response => {
            //console.log(response.data);
            if (response.data.length) {
                let accesoriosUlHtml = '';
                let accesoriosCheckboxesHtml = '';

                for (let [key, accesorio] of  response.data.entries()) {
                    if (accesorio.Quantity > 0) {
                        let iconoPlus = key == 0 ? '' : 'add-accessories';
                        accesoriosUlHtml += this.getAccesoriosTemplateUl(accesorio, iconoPlus);
                        accesoriosCheckboxesHtml += this.getAccesoriosTemplateCheckboxes(accesorio);

                        //Agregar el partnum al array de accesorios
                        this.arrayAccesories.push(
                            {
                                partnum: accesorio.PartNum,
                                slug: accesorio.slug,
                                precio: accesorio.precio
                            }
                        );
                        this.calculateTotalPriceAndNumberOfAccesories();
                    }
                }
                //console.log('this.arrayAccesories ' , this.arrayAccesories);

                //Mostrar los accesorios solo si existen elementos en el array de accesorios
                if (this.arrayAccesories.length) {
                    //Obtener template
                    this.seccionAccesoriosUl.innerHTML = accesoriosUlHtml;
                    this.seccionAccesoriosContainerCheckboxes.innerHTML = accesoriosCheckboxesHtml;

                    //Mostrar elementos
                    /*this.seccionAccesoriosTab.classList.add('active');*/
                    this.seccionAccesoriosTab.style.display = 'block';
                    this.seccionAccesorios.classList.add('show');
                    /*this.seccionAccesorios.classList.add('active');*/
                }
            }
        })
        .catch(error => {
            if (typeof error.response !== 'undefined') {
                console.log(error.response.data);
            }
        });
    }

    getAccesoriosTemplateUl(accesorio, iconoPlus)
    {
        let template = `
        <li class="col-4 col-md-4 col-xl-2gdot5 product-item remove-divider-sm-down border-0">
            <div class="product-item__outer h-100">
                <div class="remove-prodcut-hover ${iconoPlus} product-item__inner px-xl-4 p-3">
                    <div class="product-item__body pb-xl-2">
                        <!-- <div class="mb-2 d-none d-md-block">
                            <a href="/productos/${encodeURIComponent(accesorio.PartNum)}" class="font-size-12 text-gray-5">
                                ${accesorio.Categoria}
                            </a>
                        </div> -->
                        <h5 class="mb-1 product-item__title d-none d-md-block">
                            <a href="/productos/${encodeURIComponent(accesorio.PartNum)}" class="text-blue font-weight-bold">Apple
                                ${accesorio.Name.substring(0,34)}
                            </a>
                        </h5>
                        <div class="mb-2">
                            <a href="/productos/${encodeURIComponent(accesorio.PartNum)}"
                                class="d-block text-center">
                                <!-- <img class="img-fluid"
                                    src="/mps/img/212X200/img1.jpg"
                                    alt="Image Description" /> -->
                                <div class="image" style="background: url(${accesorio.Imagenes[0]});
                                                        background-size: contain;
                                                        background-repeat: no-repeat;
                                                        margin: 0 auto;
                                                        width: 100px;
                                                        height: 100px;">
                                                    </div>
                            </a>
                        </div>`;

                        if (accesorio.Descuento != undefined && accesorio.Descuento > 0) {
                            template += `
                                <div class="flex-center-between flex-column align-items-stretch mb-1 d-none d-md-block">
                                    <div class="prodcut-price d-flex align-items-center position-relative">
                                        <ins class="font-size-20 text-red text-decoration-none">
                                            $ ${ (accesorio.precio - accesorio.Descuento).toLocaleString("de-DE") }
                                        </ins>
                                        <del class="font-size-12 tex-gray-6 position-absolute bottom-100">
                                           $ ${ accesorio.precio.toLocaleString("de-DE") }
                                        </del>
                                    </div>
                                </div>
                            `;
                        } else {
                            template += `
                            <div class="flex-center-between mb-1 d-none d-md-block">
                                <div class="prodcut-price">
                                    <div class="text-gray-100">
                                        $ ${ accesorio.precio.toLocaleString("de-DE") }
                                    </div>
                                </div>
                            </div>
                            `;
                        }

        template += `
                    </div>
                </div>
            </div>
        </li>
        `;

        return template;
    }

    getAccesoriosTemplateCheckboxes(accesorio)
    {
        return `
        <div class="form-check pl-4 pl-md-0 ml-md-4 mb-2 pb-2 pb-md-0 mb-md-0 border-bottom border-md-bottom-0">
            <input class="form-check-input" type="checkbox"
            data-partnum="${ accesorio.PartNum }"
            data-slug="${ accesorio.slug }"
            data-precio="${ accesorio.Descuento > 0 ? (accesorio.precio - accesorio.Descuento).toLocaleString("de-DE") : accesorio.precio.toLocaleString("de-DE") }"
            id="inlineCheckbox-${accesorio.PartNum}" checked />
            <label class="form-check-label mb-1" for="inlineCheckbox-${ accesorio.PartNum }">
                ${ accesorio.Name.substring(0,34) } -
                <span class="text-red font-size-16">
                    $ ${ accesorio.Descuento > 0 ? (accesorio.precio - accesorio.Descuento).toLocaleString("de-DE") : accesorio.precio.toLocaleString("de-DE") }
                </span>
            </label>
        </div>
        `;
    }

    changeCheckboxAccesories(e)
    {
        let element = e.target;
        let partnumSelected = element.dataset.partnum;
        let slugSelected = element.dataset.slug;
        let priceSelected = element.dataset.precio;

        if (element.checked) {
            //Agregar accesorio
            let partnumFound = this.arrayAccesories.find(producto => producto.partnum == partnumSelected);
            if (!partnumFound) {
                this.arrayAccesories.push(
                    {
                        partnum: partnumSelected,
                        slug: slugSelected,
                        precio: priceSelected
                    }
                );
            }
        } else {
            //Eliminar accesorio
            let index = this.arrayAccesories.findIndex(producto => producto.partnum == partnumSelected);
            if (index > -1) {
                this.arrayAccesories.splice(index, 1);
            }
        }

        this.calculateTotalPriceAndNumberOfAccesories();

        //console.log(this.arrayAccesories);
        if (this.arrayAccesories.length == 0) {
            //console.log('arrayAccesories esta vacio');
            this.containerPriceAndCounterAccesories.style.display = 'none';
        } else {
            this.containerPriceAndCounterAccesories.style.display = 'block';
        }
    }

    calculateTotalPriceAndNumberOfAccesories()
    {
        //Obtener el número de accesorios seleccionados
        this.countItemAccesories.innerHTML = this.arrayAccesories.length;

        //Sumar precios de los accesorios
        let totalPrice = 0;
        if (this.arrayAccesories.length) {
            for (let accesorio of this.arrayAccesories) {
                totalPrice += parseInt(accesorio.precio);
            }
            this.totalPriceAccesories.innerHTML = '$' + totalPrice.toLocaleString("de-DE");
        }
    }

    async addAccesories(e)
    {
        e.preventDefault();
        try {
            let element = e.target;
            element.innerHTML = 'Agregando ...';
            element.disabled = true;

            for (let accesorio of this.arrayAccesories) {
                let productMps = await this.productService.findProduct(accesorio.slug);

                let productSelected = {
                    partnum: accesorio.partnum,
                    slug: accesorio.slug,
                    name: productMps.Name,
                    marks: productMps.Marks,
                    quantity: 1,
                    price: parseInt(productMps.precio)
                };
                let response = await this.cartService.addProductToCart(productSelected);
            }
            element.innerHTML = 'Agregar al Carrito';
            element.disabled = false;
        } catch (error) {
            if (typeof error.response !== 'undefined') {
                this.showAlertMessage(error.response.data.message);
                element.innerHTML = 'Agregar al Carrito';
                element.disabled = false;
            }
        }
    }
}

export default Accesories;
