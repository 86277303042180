import Utils from "../../utils/utils";

class Template
{
    constructor()
    {
        this.utils = new Utils();
    }

    getTemplateMosaicView(producto)
    {
       let template =  `
        <li class="col-6 col-md-4 product-item">
            <div class="product-item__outer h-100 w-100">
                <div class="product-item__inner px-xl-4 p-3">
                    <div class="product-item__body pb-xl-2">
                        <div class="mb-2">
                            <a href="/productos/${ producto['slug'] }"
                                class="font-size-12 text-gray-5">
                                ${ producto['Categoria'] }
                            </a>
                        </div>
                        <h5 class="mb-1 product-item__title">
                            <a href="/productos/${producto['slug']}"
                                class="text-blue font-weight-bold">
                                ${ producto['Name'] }
                            </a>
                        </h5>
                        <div class="mb-2">
                            <a href="/productos/${producto['slug']}"
                                class="d-block text-center">

                                <div class="image"
                                    style="background: url(${producto['Imagenes'][0].replace(/\s/g, '%20')});
                                    background-size: contain;
                                    background-repeat: no-repeat;
                                    width: 150px;
                                    height: 150px;
                                    margin: 0 auto;">
                                </div>
                            </a>
                        </div>
                        <div class="flex-center-between mb-1">`;

                            if (producto['Descuento'] != undefined && producto['Descuento'] > 0) {
                                template += `<div class="flex-center-between flex-column align-items-stretch mb-1">
                                    <div class="prodcut-price d-flex align-items-center position-relative">
                                        <ins class="font-size-20 text-red text-decoration-none">
                                            $ ${ (producto['precio'] - Math.round(producto['Descuento'])).toLocaleString("de-DE") }
                                        </ins>
                                        <del class="font-size-12 tex-gray-6 position-absolute bottom-100">
                                            $ ${ (producto['precio']).toLocaleString("de-DE") }
                                        </del>
                                    </div>
                                    <span class="text-secondary font-size-12">
                                        Precio antes de IVA
                                    </span>
                                    <div class="text-${ producto['Quantity'] > 0 ? 'green' : 'red' } font-size-12">
                                        ${ producto['Quantity'] > 0 ? 'Producto disponible' : 'No disponible' }
                                    </div>
                                </div>
                                `;
                            } else {
                                template += `
                                <div class="prodcut-price">
                                    <div class="text-gray-100">
                                        $${ this.utils.formatNumber(producto['precio']) }
                                        <br/>
                                        <span class="text-secondary font-size-12">Precio antes de IVA
                                        </span>
                                        <div class="text-${ producto['Quantity'] > 0 ? 'green' : 'red' } font-size-12">
                                            ${ producto['Quantity'] > 0 ? 'Producto disponible' : 'No disponible' }
                                        </div>
                                    </div>
                                </div>
                                `;
                            }

        template += `

                            <!-- <div class="d-none d-xl-block prodcut-add-cart">
                                <a href="../shop/single-product-fullwidth.html"
                                class="btn-add-cart btn-primary transition-3d-hover"
                                onclick="addToCart(event,'${producto['slug']}')">
                                    <i class="ec ec-add-to-cart"></i>
                                </a>
                            </div> -->
                        </div>
                    </div>
                    <!-- <div class="product-item__footer">
                        <div class="border-top pt-2 flex-center-between flex-wrap">

                            <a href="../shop/wishlist.html" class="text-gray-6 font-size-13" onclick="wishlist(event, '{{$product['slug']}}')">
                                <i class="ec ec-favorites mr-1 font-size-15"></i>
                                Favoritos
                            </a>
                        </div>
                    </div> -->
                </div>
            </div>
        </li>
        `;

        return template;
    }

    getTemplateListView(producto)
    {
        let template = `
        <li class="product-item remove-divider">
            <div class="product-item__outer w-100">
                <div class="product-item__inner remove-prodcut-hover py-4 row">
                    <div class="product-item__header col-6 col-md-4">
                        <div class="mb-2">
                            <a href="/productos/${producto['slug']}"
                                class="d-block text-center">

                                <div class="image" style="background: url(${producto['Imagenes'][0].replace(/\s/g, '%20')});
                                        background-size: contain;
                                        background-repeat: no-repeat;
                                        width: 150px;
                                        height: 150px;
                                        margin: 0 auto;
                                       ">
                                </div>
                            </a>
                        </div>
                    </div>

                    <div class="product-item__body col-6 col-md-5">
                        <div class="pr-lg-10">
                            <div class="mb-2">
                                <a href="/productos/${producto['slug']}"
                                    class="font-size-12 text-gray-5">
                                    ${producto['Categoria']}
                                </a>
                            </div>
                            <h5 class="mb-2 product-item__title">
                                <a href="/productos/${producto['slug']}"
                                    class="text-blue font-weight-bold">
                                     ${producto['Name']}
                                </a>
                            </h5>

                            `;

                           if (producto['Descuento'] != undefined && producto['Descuento'] > 0) {
                                template += `
                                <div class="flex-center-between flex-column align-items-stretch mt-4 mb-1 d-md-none">
                                    <div class="prodcut-price d-flex align-items-center position-relative">
                                        <ins class="font-size-20 text-red text-decoration-none">
                                            $ ${ (producto['precio'] - Math.round(producto['Descuento'])).toLocaleString("de-DE") }
                                        </ins>
                                        <del class="font-size-12 tex-gray-6 position-absolute bottom-100">
                                           $ ${ producto['precio'].toLocaleString("de-DE") }
                                        </del>
                                    </div>
                                    <span class="text-secondary font-size-12">
                                        Precio antes de IVA
                                    </span>
                                    <div class="text-${ producto['Quantity'] > 0 ? 'green' : 'red' } font-size-12">
                                        ${ producto['Quantity'] > 0 ? 'Producto disponible' : 'No disponible' }
                                    </div>
                                </div>
                                `;
                            } else {
                                template += `
                                <div class="prodcut-price mb-2 d-md-none">
                                    <div class="text-gray-100">
                                        $${this.utils.formatNumber(producto['precio'])}
                                        <br/>
                                        <span class="text-secondary font-size-12">Precio antes de IVA
                                        </span>
                                        <div class="text-${ producto['Quantity'] > 0 ? 'green' : 'red' } font-size-12">
                                        ${ producto['Quantity'] > 0 ? 'Producto disponible' : 'No disponible' }
                                    </div>
                                    </div>
                                </div>
                                `;
                            }

        //template += producto['xmlAttributes'];


        if(producto['xmlAttributes']) {
            let xml = JSON.parse(producto['xmlAttributes']);
            //console.log('Partnum: ', producto['PartNum']);

            if (typeof xml['ListaAtributos']['Atributos']['attributecs'] !== 'undefined') {
                //console.log(xml['ListaAtributos']['Atributos']['attributecs']);

                template += '<ul class="font-size-12 p-0 text-gray-110 mb-4 d-none d-md-block ">';
                if (Array.isArray(xml['ListaAtributos']['Atributos']['attributecs'])) {
                    let count = 1;
                    //console.log('Es un array');
                    for (let attributecs of  xml['ListaAtributos']['Atributos']['attributecs']) {
                        if (count <= 3) {
                            if (typeof attributecs == 'object') {
                                template += `
                                <li class="line-clamp-1 mb-1 list-bullet">
                                    ${attributecs['AttributeName']}:  ${attributecs['AttributeValue']}
                                </li>
                                `;
                            } /*else {
                                console.log('no es object');
                                template += `
                                <li class="line-clamp-1 mb-1 list-bullet">
                                    ${attributecs}
                                </li>
                                `;
                            }*/
                        }
                        count +=1;
                    }
                 template += '</ul>';
                } else {
                    //console.log('No es un array');
                    let attributecs = xml['ListaAtributos']['Atributos']['attributecs'];

                    template += '<ul class="font-size-12 p-0 text-gray-110 mb-4 d-none d-md-block ">';
                    template += `
                                <li class="line-clamp-1 mb-1 list-bullet">
                                    ${attributecs['AttributeName']}:  ${attributecs['AttributeValue']}
                                </li>
                                `;

                    template += '</ul>';
                }
            }
        } else {

            template += `
                <ul class="font-size-12 p-0 text-gray-110 mb-4 d-none d-md-block">
                    <li class="line-clamp-1 mb-1 list-bullet ">
                        ${producto['Description']}
                    </li>
                </ul>
            `;

        }

        template +=`

                        </div>
                    </div>

                    <div class="product-item__footer col-md-3 d-md-block">
                        <div class="mb-3">`;


                            if (producto['Descuento'] != undefined && producto['Descuento'] > 0) {
                                template += `
                                <div class="flex-center-between flex-column align-items-stretch mt-4 mb-2">
                                    <div class="prodcut-price d-flex align-items-center position-relative">
                                        <ins class="font-size-20 text-red text-decoration-none">
                                            $ ${ (producto['precio'] - Math.round(producto['Descuento'])).toLocaleString("de-DE") }
                                        </ins>
                                        <del class="font-size-12 tex-gray-6 position-absolute bottom-100">
                                           $ ${producto['precio'].toLocaleString("de-DE")}
                                        </del>
                                    </div>
                                    <span class="text-secondary font-size-12">
                                        Precio antes de IVA
                                    </span>
                                    <div class="text-${ producto['Quantity'] > 0 ? 'green' : 'red' } font-size-12">
                                        ${ producto['Quantity'] > 0 ? 'Producto disponible' : 'No disponible' }
                                    </div>
                                </div>
                                `;
                            } else {
                                template += `
                                <div class="prodcut-price mb-2">
                                    <div class="text-gray-100">
                                        $${this.utils.formatNumber(producto['precio'])}
                                        <br/>
                                        <span class="text-secondary font-size-12">Precio antes de IVA
                                        </span>
                                        <div class="text-${ producto['Quantity'] > 0 ? 'green' : 'red' } font-size-12">
                                            ${ producto['Quantity'] > 0 ? 'Producto disponible' : 'No disponible' }
                                        </div>
                                    </div>
                                </div>
                                `;
                            }

    template += `

                            <div class="prodcut-add-cart">
                                <a href="/productos/${producto['slug']}" class="btn btn-sm btn-block btn-primary-dark btn-wide transition-3d-hover">
                                    Ver producto
                                </a>
                            </div>
                        </div>
                        <!-- <div class="flex-horizontal-center justify-content-between justify-content-wd-center flex-wrap">
                            <a href="" class="text-gray-6 font-size-13 mx-wd-3"
                                onclick="wishlist(event, '{{ $product['slug'] }}')">
                                <i class="ec ec-favorites mr-1 font-size-15"></i>
                                Favoritos
                            </a>
                        </div> -->
                    </div>
                </div>
            </div>
        </li>
        `;

        return template;
    }

    getTemplateSubcategories(subcategorias, selectedRangesOfsubCategories = [])
    {
        let template = '';
        let contador = 0;
        //console.log('subcategorias.length ' , subcategorias.length);
        //console.log('al mostrar las categorias selectedRangesOfsubCategories tiene: ' , selectedRangesOfsubCategories);

        // Mostrar los filtros si el array de rangos seleccionados esta vacio
        if (!selectedRangesOfsubCategories.length && subcategorias != null) {
            for(let i = 0; i < subcategorias.length; i++){
                // Mostrar los primeros tres colores
                if(contador < 3){
                    //if (!selectedRangesOfsubCategories.includes(subcategorias[i][1])) {
                        template +=  `
                            <div
                                class="subCategory-container form-group align-items-center justify-content-between mb-pb-1"
                                id="subCategoria-${subcategorias[i][0]}" style="margin-bottom: 0.7rem !important;">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="subCategory-checkbox custom-control-input"
                                            id="subCate-${subcategorias[i][0]}"
                                            data-subcategoria="${subcategorias[i][0]}"
                                            data-nombre="${subcategorias[i][1]}">
                                    <label class="custom-control-label"
                                            for="subCate-${subcategorias[i][0]}">${subcategorias[i][1]}
                                    </label>
                                </div>
                            </div>
                        `;
                    //}
                }

                // Mostrar los siguientes coloress colapsados
                if (contador == 3) {
                    template += `<div class="collapse" id="collapseSubCategories">`;
                }

                if (contador >= 3) {
                    //if (!selectedRangesOfsubCategories.includes(subcategorias[i][1])) {
                        template +=  `
                            <div
                                class="subCategory-container form-group align-items-center justify-content-between mb-pb-1"
                                id="subCategoria-${subcategorias[i][0]}" style="margin-bottom: 0.7rem !important;">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="subCategory-checkbox custom-control-input"
                                            id="subCate-${subcategorias[i][0]}"
                                            data-subcategoria="${subcategorias[i][0]}"
                                            data-nombre="${subcategorias[i][1]}">
                                    <label class="custom-control-label"
                                            for="subCate-${subcategorias[i][0]}">${subcategorias[i][1]}
                                    </label>
                                </div>
                            </div>
                        `;
                    //}
                }

                if (contador == subcategorias.length - 1) {
                    template += `</div>`;
                }

                contador++;
            }

            // Mostrar los botones de mas y menos
            if(subcategorias.length >= 3){
                template += `
                <a class="link link-collapse small font-size-13 text-gray-27 d-inline-flex mt-1 mb-3"
                    data-toggle="collapse" href="#collapseSubCategories" role="button" aria-expanded="false"
                    aria-controls="collapseSubCategories" id="collapseSubCategories">
                    <span class="link__icon text-gray-27 bg-white">
                        <span class="link__icon-inner">+</span>
                    </span>
                    <span class="link-collapse__default">Mostrar más</span>
                    <span class="link-collapse__active">Mostrar menos</span>
                </a>`;
            }
        }

        return template;
    }

    getTemplatePrice(precioMinimo, precioMaximo, selectedRangesOfPrices = [])
    {
        let minimumPrice = this.numberRangeFormat(precioMinimo, 'minimum');
        let maximumPrice = this.numberRangeFormat(precioMaximo, 'maximum');
        let template = '';
        let baseMin = 50000;
        let min =  0;
        let max = 50000;
        let rangeCounter = 0;
        let firstRangeShowed = false;
        let visibleRangesCounter = 1;
        let collapseTagIsOpen = false;

        // Mostrar los filtros si el array de rangos seleccionados esta vacio
        // y el precio minimo y maximo es mayor a 0
        if (selectedRangesOfPrices == null && precioMinimo > 0 && precioMaximo > 0) {
            //console.log('precioMinimo ' , precioMinimo);
            //console.log('precioMaximo ' , precioMaximo);

            //console.log('minimumPrice ' , minimumPrice);
            //console.log('maximumPrice ' , maximumPrice);

            //console.log('al mostrar los precios selectedRangesOfPrices tiene: ' , selectedRangesOfPrices);

            // Mientras el valor minimo de cada rango sea menor igual al precio máximo
            // entonces mostrar los rangos de precio
            while (min <= precioMaximo) {
                //console.log('rangeCounter (', rangeCounter , ') min ', min, ' max ' , max );

                // Mostrar el primer rango solo si precioMinimo esta dentro de ese rango
                if (precioMinimo >= min && precioMinimo <= max) {
                    //if (!selectedRangesOfPrices.includes(min + '-' + max)) {
                        template +=  this.getTemplatePriceRange(min, max);
                    //}

                    firstRangeShowed = true;
                    visibleRangesCounter++;
                } else if (firstRangeShowed) {
                    // Despues de haber mostrado el primer rango mostrar los demás

                    if (visibleRangesCounter <= 3) {
                        //if (!selectedRangesOfPrices.includes(min + '-' + max)) {
                            template +=  this.getTemplatePriceRange(min, max);
                        //}
                    } else {
                        if (!collapseTagIsOpen) {
                            template += `<div class="collapse" id="collapsePrice">`;
                            collapseTagIsOpen = true;
                        }

                        //if (!selectedRangesOfPrices.includes(min + '-' + max)) {
                            template +=  this.getTemplatePriceRange(min, max);
                        //}
                    }
                    visibleRangesCounter++;
                }

                rangeCounter++;


                // 1) 50 x 2 = 100 (o sea rango de 50 a 100)
                if (rangeCounter == 1) {
                    min = max;
                    max = baseMin * 2;
                }

                // 2) 50 x 3 = 150 (o sea rango de 100 a 150)
                if (rangeCounter == 2) {
                    min = max;
                    max = baseMin * 3;
                }

                // 3) 150 x 2 = 300 (o sea rango de 150 a 300)
                if (rangeCounter == 3) {
                    min = max;
                    max = max * 2;
                }

                // 4) 300 x 1.666 = 498 (o sea rango de 300 a 500)
                if (rangeCounter == 4) {
                    min = max;
                    max = max * 1.66;
                    let zerosMax =  Math.floor(Math.log10(max));
                    let factorMax = Math.pow(10, zerosMax);
                    let maxVal = Math.ceil(max / factorMax) *  factorMax;
                    //console.log('maxVal ' , maxVal);
                    max = maxVal;
                    baseMin = maxVal;
                    rangeCounter = 0;
                }
            }

            if (collapseTagIsOpen) {
                template += `</div>`;

                template += `
                    <a class="link link-collapse small font-size-13 text-gray-27 d-inline-flex mt-1 mb-3"
                        data-toggle="collapse" href="#collapsePrice" role="button" aria-expanded="false"
                        aria-controls="collapsePrice">
                        <span class="link__icon text-gray-27 bg-white">
                            <span class="link__icon-inner">+</span>
                        </span>
                        <span class="link-collapse__default">Mostrar más</span>
                        <span class="link-collapse__active">Mostrar menos</span>
                    </a>`;
            }

        }

        return template;
    }

    numberRangeFormat(number, type)
    {
        //console.log('number ' , number);

        // Obtener el número de ceros de los precios
        let zeros =  Math.floor(Math.log10(number));
        //console.log('zeros ' , zeros);

        // Obtener el numero multiplicador
        let factor = Math.pow(10, zeros);
        //console.log('factor ' , factor);

        // Obtener el precio minimo o máximo
        if (type == 'minimum') {
            return Math.floor(number / factor) *  factor;
        }
        return Math.ceil(number / factor) *  factor;
    }

    getTemplatePriceRange(min, max)
    {
        return  `
        <div class="form-group d-flex align-items-center justify-content-between mb-2 pb-1">
            <div class="custom-control custom-checkbox">
                <input type="checkbox" class="price-checkbox custom-control-input"
                    id="price-${min}-${max}"
                    data-min="${min}"
                    data-max="${max}">
                <label class="custom-control-label"
                    for="price-${min}-${max}">
                    <span id="" class="">
                        $ ${min.toLocaleString("de-DE")}</span>
                    <span class="mx-0dot5"> — </span>
                    <span id="" class="">
                        $ ${max.toLocaleString("de-DE")}</span>
                 </label>
            </div>
        </div> `;
    }

    getTemplateBrands(marcas, selectedBrands = [])
    {
        let template = '';
        let lista = [];
        let contador = 0;

        // Mostrar los filtros si el array de rangos seleccionados esta vacio
        if (!selectedBrands.length && marcas != null) {
            // Obtener las marcas únicas
            for(let i = 0; i < marcas.length; i++){
                if(!lista.includes(marcas[i][2])){
                    lista.push(marcas[i][2]);
                }
            }

            for (let marcas of lista) {
                // Mostrar las primeras tres marcas
                if (contador >= 0 && contador < 3) {
                    template +=  `
                        <div
                            class="brand-container form-group align-items-center justify-content-between mb-pb-1"
                            id="marca-${marcas}" style="margin-bottom: 0.7rem !important;">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="brand-checkbox custom-control-input"
                                    id="brand-${marcas}" data-brand="${marcas}">
                                <label class="custom-control-label"
                                    for="brand-${marcas}">${marcas}
                                </label>
                            </div>
                        </div>
                    `;
                }

                // Mostrar las siguientes marcas colapsadas
                if (contador == 3) {
                    template += `<div class="collapse" id="collapseBrand2">`;
                }

                if (contador >= 3) {
                    template +=  `
                        <div
                            class="brand-container form-group align-items-center justify-content-between mb-pb-1"
                            id="marca-${marcas}" style="margin-bottom: 0.7rem !important;">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="brand-checkbox custom-control-input"
                                    id="brand-${marcas}" data-brand="${marcas}">
                                <label class="custom-control-label"
                                    for="brand-${marcas}">${marcas}
                                </label>
                            </div>
                        </div>
                    `;
                }

                if (contador == lista.length - 1) {
                    template += `</div>`;
                }

                contador++;
            }

            // Mostrar los botones de mas y menos
            if (lista.length >= 3) {
                template += `
                <a class="link link-collapse small font-size-13 text-gray-27 d-inline-flex mt-1 mb-3"
                    data-toggle="collapse" href="#collapseBrand2" role="button" aria-expanded="false"
                    aria-controls="collapseBrand2" id="collapseBrand2">
                    <span class="link__icon text-gray-27 bg-white">
                        <span class="link__icon-inner">+</span>
                    </span>
                    <span class="link-collapse__default">Mostrar más</span>
                    <span class="link-collapse__active">Mostrar menos</span>
                </a>`
            }
        }

        return template;
    }

    getTemplateColors(colores, selectedRangesOfColors = [])
    {
        let template = '';
        let contador = 0;

        // Mostrar los filtros si el array de rangos seleccionados esta vacio
        if (!selectedRangesOfColors.length && colores != null) {
            // Mostrar los primeros tres colores
            for(let i = 0; i < colores.length; i++){
                if(contador < 3){
                    template +=  `
                        <div
                            class="color-container form-group align-items-center justify-content-between mb-pb-1"
                            id="color-${colores[i]}" style="margin-bottom: 0.7rem !important;">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="color-checkbox custom-control-input"
                                    id="${colores[i]}" data-color="${colores[i]}">
                                <label class="custom-control-label"
                                    for="${colores[i]}">${colores[i].toUpperCase()}
                                </label>
                            </div>
                        </div>
                    `;
                }

                // Mostrar los siguientes colores colapsados
                if (contador == 3) {
                    template += `<div class="collapse" id="collapseColors">`;
                }

                if (contador >= 3) {
                    template +=  `
                            <div
                                class="color-container form-group align-items-center justify-content-between mb-pb-1"
                                id="color-${colores[i]}" style="margin-bottom: 0.7rem !important;">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="color-checkbox custom-control-input"
                                        id="${colores[i]}" data-color="${colores[i]}">
                                    <label class="custom-control-label"
                                        for="${colores[i]}">${colores[i].toUpperCase()}
                                    </label>
                                </div>
                            </div>
                        `;
                }

                if (contador == colores.length - 1) {
                    template += `</div>`;
                }

                contador++;
            }

            // Mostrar los botones de mas y menos
            if(colores.length >= 3){
                template += `
                <a class="link link-collapse small font-size-13 text-gray-27 d-inline-flex mt-1 mb-3"
                    data-toggle="collapse" href="#collapseColors" role="button" aria-expanded="false"
                    aria-controls="collapseColors" id="collapseColors">
                    <span class="link__icon text-gray-27 bg-white">
                        <span class="link__icon-inner">+</span>
                    </span>
                    <span class="link-collapse__default">Mostrar más</span>
                    <span class="link-collapse__active">Mostrar menos</span>
                </a>`
            }
        }

        return template;
    }
}

export default Template;
