class Users
{
    constructor()
    {
        this.accountUsers = document.querySelector('#account-users');

        if (this.accountUsers != null) {
            this.btnDelete = this.accountUsers.querySelector('#btn-delete');
            this.btnConfirmDelete = this.accountUsers.querySelector('#btn-confirm-delete');
            this.recordToDelete = null;
            this.alertMessageDelete = this.accountUsers.querySelector('#alert-message-delete');
            //console.log(this.btnConfirmDelete);
            this.bindEvents();
        }
    }

    bindEvents()
    {
        document.addEventListener('click', this.clickEvents.bind(this));
    }

    clickEvents(e)
    {
        if (e.target.matches('#' + this.btnDelete.id)) {
            this.delete(e);
        }

        if (e.target.matches('#' + this.btnConfirmDelete.id)) {
            this.confirmDelete(e);
        }
    }

    delete(e)
    {
        this.recordToDelete = e.target.dataset.id;
        this.alertMessageDelete.style.display = 'none';
    }

    confirmDelete()
    {
        axios
        .delete('/cuenta/usuarios/'+ this.recordToDelete)
        .then(response => {
            //console.log("data",response.data);
            if (response.data.status == 200) {
                /*this.buttonSubmitDelete.disabled = false;
                this.alertMessageDelete.style.display = 'none';
                this.successMessageDelete.style.display = 'block';*/
                document.location.reload();
            }
        })
        .catch(error => {
            //console.log("error",error);
            if (typeof error.response !== 'undefined') {
                //console.log("error response",error.response.data);
                this.alertMessageDelete.innerHTML = error.response.data.message;
                this.alertMessageDelete.style.display = 'block';
            }
        });
    }
}

export default Users;
