class MegaMenu
{
    constructor()
    {
        this.init();
    }

    init()
    {
        $('.js-mega-menu').HSMegaMenu({
            event: 'hover',
            direction: 'horizontal',
            pageContainer: $('.container'),
            breakpoint: 767.98,
            hideTimeOut: 0
        });
    }
}

export default MegaMenu;
