class Newsletter
{
    constructor()
    {
        this.form = $("#newsletter-form");
        if (this.form.length) {
            this.formInput = this.form.find("input");
            this.formAlert = this.form.find('.small--alert');
            this.thanks = $('#newsletter-thanks');
            this.bindEvents();
        }
    }

    bindEvents()
    {
        this.form.on("submit", e => {
            this.handleSubmit(e);
        });
    }

    handleSubmit(e)
    {
        e.preventDefault();
        let value = this.formInput.val();
        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!reg.test(value)) {
            this.formAlert.text('Por favor ingrese una dirección de correo electrónico válida.');
            this.formAlert.show();
        } else {
            if (this.form.find('input:radio[name=terms]').is(':checked')) {
                this.formAlert.hide();
                let $this = this;

                axios.post('/api/prehome/suscriptores', this.form.serialize())
                .then(function(response) {
                    if (response.data.status == 200) {
                        $this.form.trigger("reset");
                        $this.thanks.show().delay(5000).fadeOut(3000);
                    }
                })
                .catch(function(error){
                    if (typeof error.response !== 'undefined') {
                        $this.formAlert.text(error.response.data.message);
                        $this.formAlert.show();
                    }
                });
            } else {
                this.formAlert.text('Debes autorizar el uso de tus datos personales.');
                this.formAlert.show();
            }
        }
    }
}

export default Newsletter;
